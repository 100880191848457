import React, {Component} from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import update from 'immutability-helper';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	Form,
	Input,
	FieldGroup,
	Checkbox,
	Select
} from '../../../../components/Form';
import {SearchKeyPlace} from '../../../../util/types';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class AddSearch extends Component {
	state = {
		success: false,
		invalid: false,
		name: '',
		internalName: '',
		source: '',
		searchKeys: []
	}

	componentDidMount() {
		setTitle(['Wyszukiwarki', 'Dodaj nową wyszukiwarkę']);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.added && this.props.added) {
			this.props.listSearches('id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push('/search');
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			addSearch,
			loading
		} = this.props;

		const {
			name,
			internalName,
			source,
			searchKeys
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		addSearch({
			name,
			internal_name: internalName,
			source,
			search_keys: searchKeys
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	addSearchKey = () => {
		const searchKey = {
			uuid: shortid.generate(),
			id: null,
			name: '',
			hide_name: false,
			search_key_place_name: ''
		};

		this.setState((prevProps) => update(prevProps, {
			searchKeys: {$push: [searchKey]}
		}));
	}

	removeSearchKey = (index) => {
		this.setState((prevProps) => update(prevProps, {
			searchKeys: {$splice: [[index, 1]]}
		}));
	}

	render() {
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			name,
			internalName,
			source,
			searchKeys
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Nowa wyszukiwarka</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
							<Input
								name="name"
								value={name}
								label="Nazwa wyszukiwarki"
								placeholder="Wpisz nazwę wyszukiwarki"
								handleChange={this.handleChange}
								required
							/>

							<Input
								name="internalName"
								value={internalName}
								label="Wewnętrzna nazwa wyszukiwarki (w odnośniku)"
								placeholder="Wpisz wewnętrzną nazwę wyszukiwarki"
								handleChange={this.handleChange}
								required
							/>

							<Input
								name="source"
								value={source}
								label="Źródło danych"
								placeholder="Wpisz źródło danych"
								handleChange={this.handleChange}
							/>

							<FieldGroup label="Własności wyszukiwarki">
								{
									searchKeys.map((searchKey, index) => (
										<Row
											key={searchKey.uuid}
											style={{
												border: '1px solid #d0d0d0',
												borderRadius: '5px',
												margin: '15px',
												padding: '1rem 0'
											}}
										>
											<Col lg={{span: 12}}>
												<Input
													name={`searchKeys[${index}].name`}
													value={searchKey.name}
													label="Nazwa właściwości"
													placeholder="Wpisz nazwę właściwości"
													handleChange={this.handleChange}
													required
												/>

												<Select
													name={`searchKeys[${index}].search_key_place_name`}
													value={searchKey.search_key_place_name}
													label="Położenie właściwości"
													options={[
														{
															label: 'Wybierz położenie',
															value: ''
														},
														{
															label: 'U góry',
															value: SearchKeyPlace.TOP
														},
														{
															label: 'Po lewej',
															value: SearchKeyPlace.LEFT
														},
														{
															label: 'Po prawej',
															value: SearchKeyPlace.RIGHT
														},
														{
															label: 'U dołu',
															value: SearchKeyPlace.BOTTOM
														}
													]}
													handleChange={this.handleChange}
													required
												/>

												<Checkbox
													name={`searchKeys[${index}].hide_name`}
													value={searchKey.hide_name}
													handleChange={this.handleChange}
													label="Ukrywanie nazwy"
													fieldLabel="Ukryj nazwę właściwości w wyszukiwarce"
												/>
											</Col>
											<Col lg={{span: 12}}>
												<Button size="sm" variant="outline-danger" onClick={() => this.removeSearchKey(index)}>
													Usuń tą właściwość
												</Button>
											</Col>
										</Row>
									))
								}

								<Button size="sm" variant="outline-success" onClick={this.addSearchKey}>
									Dodaj nową właściwość
								</Button>
							</FieldGroup>

							<Button variant="outline-primary" type="submit">
								Utwórz nową wyszukiwarkę
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

AddSearch.propTypes = {
	history: PropTypes.object.isRequired,
	listSearches: PropTypes.func.isRequired,
	added: PropTypes.bool.isRequired,
	addSearch: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

export default AddSearch;
