import axios from 'axios';

import {Rank} from '../util/actionTypes';

export const listRanks = (order, asc) => (dispatch) => {
	dispatch({
		type: Rank.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/rank/?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Rank.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: Rank.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Rank.LIST.FAILURE
			});
		});
};

export const getRank = (id) => (dispatch) => {
	dispatch({
		type: Rank.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/rank/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Rank.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: Rank.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Rank.GET.FAILURE
			});
		});
};

export const addRank = (data) => (dispatch) => {
	dispatch({
		type: Rank.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/rank/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Rank.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: Rank.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Rank.ADD.FAILURE
			});
		});
};

export const importRank = (data) => (dispatch) => {
	dispatch({
		type: Rank.IMPORT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/rank/import`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Rank.IMPORT.SUCCESS
				});
			} else {
				dispatch({
					type: Rank.IMPORT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Rank.IMPORT.FAILURE
			});
		});
};

export const editRank = (id, data) => (dispatch) => {
	dispatch({
		type: Rank.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/rank/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Rank.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: Rank.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Rank.EDIT.FAILURE
			});
		});
};

export const deleteRank = (id) => (dispatch) => {
	dispatch({
		type: Rank.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/rank/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Rank.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: Rank.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Rank.DELETE.FAILURE
			});
		});
};
