import {Graph} from '../util/actionTypes';
import {Status} from '../util/default';

const initialState = {
	status: Status.SUCCESS,
	list: null,
	item: null,
	added: false,
	imported: false,
	edited: false,
	deleted: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case Graph.LIST.REQUEST:
			return {
				...state,
				status: Status.REQUEST
			};
		case Graph.LIST.SUCCESS:
			return {
				...state,
				list: action.data,
				status: Status.SUCCESS
			};
		case Graph.LIST.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case Graph.GET.REQUEST:
			return {
				...state,
				item: null,
				status: Status.REQUEST
			};
		case Graph.GET.SUCCESS:
			return {
				...state,
				item: action.data,
				status: Status.SUCCESS
			};
		case Graph.GET.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case Graph.ADD.REQUEST:
			return {
				...state,
				added: false,
				status: Status.REQUEST
			};
		case Graph.ADD.SUCCESS:
			return {
				...state,
				added: true,
				status: Status.SUCCESS
			};
		case Graph.ADD.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case Graph.IMPORT.REQUEST:
			return {
				...state,
				imported: false,
				status: Status.REQUEST
			};
		case Graph.IMPORT.SUCCESS:
			return {
				...state,
				imported: true,
				status: Status.SUCCESS
			};
		case Graph.IMPORT.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case Graph.EDIT.REQUEST:
			return {
				...state,
				edited: false,
				status: Status.REQUEST
			};
		case Graph.EDIT.SUCCESS:
			return {
				...state,
				edited: true,
				status: Status.SUCCESS
			};
		case Graph.EDIT.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case Graph.DELETE.REQUEST:
			return {
				...state,
				deleted: false,
				status: Status.REQUEST
			};
		case Graph.DELETE.SUCCESS:
			return {
				...state,
				deleted: true,
				status: Status.SUCCESS
			};
		case Graph.DELETE.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		default:
			return state;
	}
};
