import {connect} from 'react-redux';

import {addGraphEntry, listGraphEntries} from '../../../../actions/graphEntry';
import {getGraph} from '../../../../actions/graph';
import Add from './Add';

const mapStateToProps = (state) => ({
	graph: state.graph.item,
	added: state.graphEntry.added,
	loading: state.graphEntry.status.loading || state.graph.status.loading,
	error: state.graphEntry.status.error || state.graph.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listGraphEntries: (graphGroupId, order, asc) => dispatch(listGraphEntries(graphGroupId, order, asc)),
	getGraph: (id) => dispatch(getGraph(id)),
	addGraphEntry: (data) => dispatch(addGraphEntry(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
