import React from 'react';
import PropTypes from 'prop-types';

import {Modal as BootstrapModal, Button} from 'react-bootstrap';

const Modal = (props) => {
	const {
		title,
		confirmText,
		confirmAction,
		cancelText,
		cancelAction,
		children
	} = props;

	return (
		<BootstrapModal show onHide={cancelAction}>
			<BootstrapModal.Header closeButton>
				<BootstrapModal.Title>{title}</BootstrapModal.Title>
			</BootstrapModal.Header>
			<BootstrapModal.Body>
				{children}
			</BootstrapModal.Body>
			<BootstrapModal.Footer>
				{
					confirmAction && (
						<Button variant="outline-success" onClick={confirmAction}>
							{confirmText}
						</Button>
					)
				}
				<Button variant={confirmAction ? 'outline-danger' : 'outline-secondary'} onClick={cancelAction}>
					{cancelText}
				</Button>
			</BootstrapModal.Footer>
		</BootstrapModal>
	);
};

Modal.propTypes = {
	title: PropTypes.string.isRequired,
	confirmText: PropTypes.string,
	confirmAction: PropTypes.func,
	cancelText: PropTypes.string,
	cancelAction: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired
};

Modal.defaultProps = {
	confirmText: 'Tak, chcę.',
	confirmAction: null,
	cancelText: 'Nie, nie chcę.'
};

export default Modal;
