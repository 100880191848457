import React from 'react';
import {Link} from 'react-router-dom';
import {
	Navbar,
	Nav,
	NavDropdown,
	Container
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {generateUserName} from '../../util/common';

const NavPrivate = (props) => {
	const {logout, me} = props;

	return (
		<Navbar bg="light" expand="lg">
			<Container>
				<Navbar.Brand as={Link} to="/">{process.env.REACT_APP_NAME}</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<Nav.Link as={Link} to="/voivodeship">Województwa</Nav.Link>
						<Nav.Link as={Link} to="/rank">Rankingi</Nav.Link>
						<Nav.Link as={Link} to="/search">Wyszukiwarki</Nav.Link>
						<Nav.Link as={Link} to="/map">Mapy</Nav.Link>
						<Nav.Link as={Link} to="/graph">Wykresy</Nav.Link>
						<Nav.Link as={Link} to="/calculator">Kalkulatory</Nav.Link>
						<Nav.Link as={Link} to="/timer">Liczniki</Nav.Link>
						<Nav.Link as={Link} to="/letter">Listy</Nav.Link>
						<Nav.Link as={Link} to="/item">Obiekty</Nav.Link>
					</Nav>
					<Nav>
						<NavDropdown title={generateUserName(me)} style={{cursor: 'pointer'}}>
							<NavDropdown.Item as={Link} to="/user/profile">Twoje konto</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/account">Konta użytkowników</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item href={`https://zacznijto.pl/support?app=smog-alert-v${process.env.REACT_APP_VERSION}&view=${encodeURIComponent(window.location.href)}&email=${encodeURIComponent(me.email)}&firstname=${encodeURIComponent(me.firstname)}&lastname=${encodeURIComponent(me.lastname)}`}>Pomoc techniczna</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item href="#" onClick={logout}>Wyloguj</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

NavPrivate.propTypes = {
	me: PropTypes.object,
	logout: PropTypes.func.isRequired
};

NavPrivate.defaultProps = {
	me: null
};

export default NavPrivate;

