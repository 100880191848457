import {connect} from 'react-redux';

import {editItem, getItem, listItems} from '../../../../actions/item';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	recipientGroups: state.recipientGroup.list,
	item: state.item.item,
	edited: state.item.edited,
	loading: state.item.status.loading,
	error: state.item.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listItems: (order, asc) => dispatch(listItems(order, asc)),
	getItem: (id) => dispatch(getItem(id)),
	editItem: (id, data) => dispatch(editItem(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
