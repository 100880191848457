import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
	Navbar,
	Nav,
	Container
} from 'react-bootstrap';

const NavLoading = (props) => {
	const {logout} = props;

	return (
		<Navbar bg="light" expand="lg">
			<Container>
				<Navbar.Brand as={Link} to="/">{process.env.REACT_APP_NAME}</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto" />
					<Nav>
						<Nav.Link href="#" onClick={logout}>Wyloguj</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

NavLoading.propTypes = {
	logout: PropTypes.func.isRequired
};

export default NavLoading;
