import {connect} from 'react-redux';

import {editLetter, getLetter, listLetters} from '../../../../actions/letter';
import Edit from './Edit';
import {listRecipientGroups} from '../../../../actions/recipientGroup';

const mapStateToProps = (state) => ({
	recipientGroups: state.recipientGroup.list,
	item: state.letter.item,
	edited: state.letter.edited,
	loading: state.letter.status.loading || state.recipientGroup.status.loading,
	error: state.letter.status.error || state.recipientGroup.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listLetters: (order, asc) => dispatch(listLetters(order, asc)),
	listRecipientGroups: (order, asc) => dispatch(listRecipientGroups(order, asc)),
	getLetter: (id) => dispatch(getLetter(id)),
	editLetter: (id, data) => dispatch(editLetter(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
