import {connect} from 'react-redux';

import {deleteRankGroup, listRankGroups} from '../../../../actions/rankGroup';
import {getRank} from '../../../../actions/rank';
import List from './List';

const mapStateToProps = (state, ownProps) => ({
	rank: state.rank.item,
	list: state.rankGroup.list[ownProps.match.params.rankId],
	deleted: state.rankGroup.deleted,
	loading: state.rank.status.loading || state.rankGroup.status.loading,
	error: state.rank.status.error || state.rankGroup.status.error
});

const mapDispatchToProps = (dispatch) => ({
	getRank: (rankId) => dispatch(getRank(rankId)),
	listRankGroups: (rankId, order, asc) => dispatch(listRankGroups(rankId, order, asc)),
	deleteRankGroup: (id) => dispatch(deleteRankGroup(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
