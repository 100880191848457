import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {Form, Input} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class AddRecipient extends Component {
	state = {
		success: false,
		invalid: false,
		name: '',
		email: ''
	}

	componentDidMount() {
		const {recipientGroupId} = this.props.match.params;

		setTitle(['Listy', 'Grupy odbiorców', `Grupa #${recipientGroupId}`, 'Odbiorcy', 'Dodaj nowego odbiorcę']);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.added && this.props.added) {
			this.props.listRecipients(this.props.match.params.recipientGroupId, 'id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/letter/recipient_group/${this.props.match.params.recipientGroupId}/recipient`);
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			addRecipient,
			loading
		} = this.props;

		const {
			name,
			email
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		const {recipientGroupId} = this.props.match.params;

		addRecipient({
			name,
			email,
			recipient_group_id: recipientGroupId
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {recipientGroupId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			name,
			email
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Nowy odbiorca w grupie #{recipientGroupId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
							<Input
								name="name"
								value={name}
								label="Nazwa odbiorcy"
								placeholder="Wpisz nazwę odbiorcy"
								handleChange={this.handleChange}
								required
							/>

							<Input
								name="email"
								value={email}
								label="Adres e-mail"
								placeholder="Wpisz adres e-mail"
								handleChange={this.handleChange}
								required
							/>

							<Button variant="outline-primary" type="submit">
								Utwórz nowego odbiorcę
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

AddRecipient.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	listRecipients: PropTypes.func.isRequired,
	added: PropTypes.bool.isRequired,
	addRecipient: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

export default AddRecipient;
