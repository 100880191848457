import {connect} from 'react-redux';

import {addSearchEntry, listSearchEntries} from '../../../../actions/searchEntry';
import {listAllBoroughs} from '../../../../actions/borough';
import {getSearch} from '../../../../actions/search';
import Add from './Add';

const mapStateToProps = (state) => ({
	search: state.search.item,
	boroughs: state.borough.allList,
	added: state.searchEntry.added,
	loading: state.searchEntry.status.loading || state.borough.status.loading || state.search.status.loading,
	error: state.searchEntry.status.error || state.borough.status.error || state.search.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listSearchEntries: (searchGroupId, order, asc) => dispatch(listSearchEntries(searchGroupId, order, asc)),
	getSearch: (id) => dispatch(getSearch(id)),
	listAllBoroughs: (order, asc) => dispatch(listAllBoroughs(order, asc)),
	addSearchEntry: (data) => dispatch(addSearchEntry(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
