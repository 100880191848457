import axios from 'axios';

import {Voivodeship} from '../util/actionTypes';

export const listVoivodeships = (order, asc) => (dispatch) => {
	dispatch({
		type: Voivodeship.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/voivodeship/?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Voivodeship.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: Voivodeship.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Voivodeship.LIST.FAILURE
			});
		});
};

export const getVoivodeship = (id) => (dispatch) => {
	dispatch({
		type: Voivodeship.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/voivodeship/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Voivodeship.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: Voivodeship.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Voivodeship.GET.FAILURE
			});
		});
};

export const addVoivodeship = (data) => (dispatch) => {
	dispatch({
		type: Voivodeship.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/voivodeship/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Voivodeship.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: Voivodeship.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Voivodeship.ADD.FAILURE
			});
		});
};

export const editVoivodeship = (id, data) => (dispatch) => {
	dispatch({
		type: Voivodeship.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/voivodeship/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Voivodeship.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: Voivodeship.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Voivodeship.EDIT.FAILURE
			});
		});
};

export const deleteVoivodeship = (id) => (dispatch) => {
	dispatch({
		type: Voivodeship.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/voivodeship/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Voivodeship.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: Voivodeship.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Voivodeship.DELETE.FAILURE
			});
		});
};
