import {connect} from 'react-redux';

import {deleteCheckpoint, listCheckpoints} from '../../../../actions/checkpoint';
import List from './List';

const mapStateToProps = (state, ownProps) => ({
	list: state.checkpoint.list[ownProps.match.params.mapId],
	deleted: state.checkpoint.deleted,
	loading: state.checkpoint.status.loading,
	error: state.checkpoint.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listCheckpoints: (mapId, order, asc) => dispatch(listCheckpoints(mapId, order, asc)),
	deleteCheckpoint: (id) => dispatch(deleteCheckpoint(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
