import {connect} from 'react-redux';

import {addVoivodeship, listVoivodeships} from '../../../../actions/voivodeship';
import Add from './Add';

const mapStateToProps = (state) => ({
	added: state.voivodeship.added,
	loading: state.voivodeship.status.loading,
	error: state.voivodeship.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listVoivodeships: (order, asc) => dispatch(listVoivodeships(order, asc)),
	addVoivodeship: (data) => dispatch(addVoivodeship(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
