import {connect} from 'react-redux';

import {addItem, listItems} from '../../../../actions/item';
import {listItemTypes} from '../../../../actions/itemType';
import Add from './Add';

const mapStateToProps = (state) => ({
	itemTypes: state.itemType.list,
	added: state.item.added,
	loading: state.item.status.loading || state.itemType.status.loading,
	error: state.item.status.error || state.itemType.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listItems: (order, asc) => dispatch(listItems(order, asc)),
	listItemTypes: (order, asc) => dispatch(listItemTypes(order, asc)),
	addItem: (data) => dispatch(addItem(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
