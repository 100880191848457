import {connect} from 'react-redux';

import {deleteTimer, listTimers} from '../../../../actions/timer';
import List from './List';

const mapStateToProps = (state) => ({
	list: state.timer.list,
	deleted: state.timer.deleted,
	loading: state.timer.status.loading,
	error: state.timer.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listTimers: (order, asc) => dispatch(listTimers(order, asc)),
	deleteTimer: (id) => dispatch(deleteTimer(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
