import React from 'react';
import {Container} from 'react-bootstrap';

const Footer = () => (
	<Container
		style={{
			marginTop: '3rem',
			marginBottom: '3rem',
			textAlign: 'center'
		}}
	>
		<h6>{process.env.REACT_APP_NAME} v{process.env.REACT_APP_VERSION}</h6>
		<p>System dumnie rozwijany w Krakowie</p>
	</Container>
);

export default Footer;
