import axios from 'axios';

import {Graph} from '../util/actionTypes';

export const listGraphs = (order, asc) => (dispatch) => {
	dispatch({
		type: Graph.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/graph/?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Graph.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: Graph.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Graph.LIST.FAILURE
			});
		});
};

export const getGraph = (id) => (dispatch) => {
	dispatch({
		type: Graph.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/graph/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Graph.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: Graph.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Graph.GET.FAILURE
			});
		});
};

export const addGraph = (data) => (dispatch) => {
	dispatch({
		type: Graph.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/graph/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Graph.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: Graph.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Graph.ADD.FAILURE
			});
		});
};

export const importGraph = (data) => (dispatch) => {
	dispatch({
		type: Graph.IMPORT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/graph/import`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Graph.IMPORT.SUCCESS
				});
			} else {
				dispatch({
					type: Graph.IMPORT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Graph.IMPORT.FAILURE
			});
		});
};

export const editGraph = (id, data) => (dispatch) => {
	dispatch({
		type: Graph.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/graph/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Graph.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: Graph.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Graph.EDIT.FAILURE
			});
		});
};

export const deleteGraph = (id) => (dispatch) => {
	dispatch({
		type: Graph.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/graph/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Graph.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: Graph.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Graph.DELETE.FAILURE
			});
		});
};
