import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {Checkbox, Form, Input} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class AddRankEntry extends Component {
	state = {
		success: false,
		invalid: false,
		name: '',
		hidden: false
	}

	componentDidMount() {
		const {rankId, rankGroupId} = this.props.match.params;

		setTitle(['Rankingi', `Ranking #${rankId}`, 'Grupy', `Grupa #${rankGroupId}`, 'Pozycje', 'Dodaj nową pozycję']);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.added && this.props.added) {
			this.props.listRankEntries(this.props.match.params.rankGroupId, 'id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/rank/${this.props.match.params.rankId}/group/${this.props.match.params.rankGroupId}/entry`);
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			addRankEntry,
			loading
		} = this.props;

		const {name, hidden} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		const {rankGroupId} = this.props.match.params;

		addRankEntry({
			name,
			hidden,
			rank_group_id: rankGroupId
		});

		return true;
	};

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {rankId, rankGroupId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			name,
			hidden
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Nowa pozycja w grupie #{rankGroupId} z rankingu #{rankId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
							<Input
								name="name"
								value={name}
								label="Nazwa pozycji rankingu"
								placeholder="Wpisz nazwę pozycji rankingu"
								handleChange={this.handleChange}
								required
							/>

							<Checkbox
								name="hidden"
								value={hidden}
								handleChange={this.handleChange}
								label="Ukryta pozycja"
								fieldLabel="Ukryj tę pozycję, ale uwzględniaj jej wartości w podsumowaniu"
							/>

							<Button variant="outline-primary" type="submit">
								Utwórz nową pozycję
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

AddRankEntry.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	listRankEntries: PropTypes.func.isRequired,
	added: PropTypes.bool.isRequired,
	addRankEntry: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

export default AddRankEntry;
