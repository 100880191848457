import {connect} from 'react-redux';

import {editRecipient, getRecipient, listRecipients} from '../../../../actions/recipient';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.recipient.item,
	edited: state.recipient.edited,
	loading: state.recipient.status.loading,
	error: state.recipient.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRecipients: (recipientGroupId, order, asc) => dispatch(listRecipients(recipientGroupId, order, asc)),
	getRecipient: (id) => dispatch(getRecipient(id)),
	editRecipient: (id, data) => dispatch(editRecipient(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
