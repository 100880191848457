import {connect} from 'react-redux';

import {addGraph, listGraphs} from '../../../../actions/graph';
import Add from './Add';

const mapStateToProps = (state) => ({
	added: state.graph.added,
	loading: state.graph.status.loading,
	error: state.graph.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listGraphs: (order, asc) => dispatch(listGraphs(order, asc)),
	addGraph: (data) => dispatch(addGraph(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
