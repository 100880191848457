import {connect} from 'react-redux';

import {addCheckpoint, listCheckpoints} from '../../../../actions/checkpoint';
import {getMap} from '../../../../actions/map';
import Add from './Add';

const mapStateToProps = (state) => ({
	map: state.map.item,
	added: state.checkpoint.added,
	loading: state.checkpoint.status.loading || state.map.status.loading,
	error: state.checkpoint.status.error || state.map.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listCheckpoints: (mapId, order, asc) => dispatch(listCheckpoints(mapId, order, asc)),
	getMap: (id) => dispatch(getMap(id)),
	addCheckpoint: (data) => dispatch(addCheckpoint(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
