import {connect} from 'react-redux';

import {addTimer, listTimers} from '../../../../actions/timer';
import Add from './Add';

const mapStateToProps = (state) => ({
	added: state.timer.added,
	loading: state.timer.status.loading,
	error: state.timer.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listTimers: (order, asc) => dispatch(listTimers(order, asc)),
	addTimer: (data) => dispatch(addTimer(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
