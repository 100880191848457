import axios from 'axios';

import {RankValue} from '../util/actionTypes';

export const listRankGroupValues = (rankGroupId, order, asc) => (dispatch) => {
	dispatch({
		type: RankValue.LIST_GROUP.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/rank_value/rank_group/${rankGroupId}?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					id: rankGroupId,
					type: RankValue.LIST_GROUP.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: RankValue.LIST_GROUP.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankValue.LIST_GROUP.FAILURE
			});
		});
};

export const listRankEntryValues = (rankEntryId, order, asc) => (dispatch) => {
	dispatch({
		type: RankValue.LIST_ENTRY.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/rank_value/rank_entry/${rankEntryId}?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					id: rankEntryId,
					type: RankValue.LIST_ENTRY.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: RankValue.LIST_ENTRY.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankValue.LIST_ENTRY.FAILURE
			});
		});
};

export const getRankValue = (id) => (dispatch) => {
	dispatch({
		type: RankValue.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/rank_value/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RankValue.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: RankValue.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankValue.GET.FAILURE
			});
		});
};

export const addRankValue = (data) => (dispatch) => {
	dispatch({
		type: RankValue.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/rank_value/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RankValue.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: RankValue.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankValue.ADD.FAILURE
			});
		});
};

export const importRankValue = (data) => (dispatch) => {
	dispatch({
		type: RankValue.IMPORT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/rank_value/import`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RankValue.IMPORT.SUCCESS
				});
			} else {
				dispatch({
					type: RankValue.IMPORT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankValue.IMPORT.FAILURE
			});
		});
};

export const editRankValue = (id, data) => (dispatch) => {
	dispatch({
		type: RankValue.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/rank_value/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RankValue.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: RankValue.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankValue.EDIT.FAILURE
			});
		});
};

export const deleteRankValue = (id) => (dispatch) => {
	dispatch({
		type: RankValue.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/rank_value/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RankValue.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: RankValue.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankValue.DELETE.FAILURE
			});
		});
};
