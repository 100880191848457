import {Calculator} from '../util/actionTypes';
import {Status} from '../util/default';

const initialState = {
	status: Status.SUCCESS,
	list: null,
	item: null,
	added: false,
	edited: false,
	deleted: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case Calculator.LIST.REQUEST:
			return {
				...state,
				status: Status.REQUEST
			};
		case Calculator.LIST.SUCCESS:
			return {
				...state,
				list: action.data,
				status: Status.SUCCESS
			};
		case Calculator.LIST.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case Calculator.GET.REQUEST:
			return {
				...state,
				item: null,
				status: Status.REQUEST
			};
		case Calculator.GET.SUCCESS:
			return {
				...state,
				item: action.data,
				status: Status.SUCCESS
			};
		case Calculator.GET.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case Calculator.ADD.REQUEST:
			return {
				...state,
				added: false,
				status: Status.REQUEST
			};
		case Calculator.ADD.SUCCESS:
			return {
				...state,
				added: true,
				status: Status.SUCCESS
			};
		case Calculator.ADD.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case Calculator.EDIT.REQUEST:
			return {
				...state,
				edited: false,
				status: Status.REQUEST
			};
		case Calculator.EDIT.SUCCESS:
			return {
				...state,
				edited: true,
				status: Status.SUCCESS
			};
		case Calculator.EDIT.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case Calculator.DELETE.REQUEST:
			return {
				...state,
				deleted: false,
				status: Status.REQUEST
			};
		case Calculator.DELETE.SUCCESS:
			return {
				...state,
				deleted: true,
				status: Status.SUCCESS
			};
		case Calculator.DELETE.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		default:
			return state;
	}
};
