import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {Form, Input} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class EditDistrict extends Component {
	state = {
		success: false,
		invalid: false,
		initialized: false,
		name: ''
	}

	componentDidMount() {
		const {voivodeshipId, districtId} = this.props.match.params;
		const {getDistrict} = this.props;

		setTitle(['Województwa', `Województwo #${voivodeshipId}`, 'Powiaty', `Powiat #${districtId}`, 'Edytuj']);

		getDistrict(districtId);

		return true;
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.item && this.props.item && this.props.item.id === parseInt(this.props.match.params.districtId, 10)) {
			this.setState({
				initialized: true,
				name: this.props.item.name
			});
		}

		if (!prevProps.edited && this.props.edited) {
			this.props.listDistricts(this.props.match.params.voivodeshipId, 'id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/voivodeship/${this.props.match.params.voivodeshipId}/district`);
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {districtId} = this.props.match.params;
		const {editDistrict, loading} = this.props;
		const {name} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		editDistrict(districtId, {name});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {districtId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			initialized,
			success,
			invalid,
			name
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>
							Edycja powiatu #{districtId}
						</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							initialized && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<Input
										name="name"
										value={name}
										label="Nazwa powiatu"
										placeholder="Wpisz nazwę powiatu"
										handleChange={this.handleChange}
										required
									/>

									<Button variant="outline-primary" type="submit">
										Zapisz zmiany
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

EditDistrict.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	item: PropTypes.object,
	getDistrict: PropTypes.func.isRequired,
	edited: PropTypes.bool.isRequired,
	editDistrict: PropTypes.func.isRequired,
	listDistricts: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

EditDistrict.defaultProps = {
	item: null
};

export default EditDistrict;

