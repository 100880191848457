import {connect} from 'react-redux';

import Add from './Add';
import {addUser, listUsers} from '../../../../actions/user';

const mapStateToProps = (state) => ({
	added: state.user.added,
	loading: state.user.status.loading,
	error: state.user.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listUsers: (order, asc) => dispatch(listUsers(order, asc)),
	addUser: (data) => dispatch(addUser(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
