import {connect} from 'react-redux';

import {editCalculator, getCalculator, listCalculators} from '../../../../actions/calculator';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.calculator.item,
	edited: state.calculator.edited,
	loading: state.calculator.status.loading,
	error: state.calculator.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listCalculators: (order, asc) => dispatch(listCalculators(order, asc)),
	getCalculator: (id) => dispatch(getCalculator(id)),
	editCalculator: (id, data) => dispatch(editCalculator(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
