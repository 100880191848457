import {connect} from 'react-redux';

import {getUserMe, saveUserMe} from '../../../../actions/user';
import Profile from './Profile';

const mapStateToProps = (state) => ({
	me: state.user.me,
	savedMe: state.user.savedMe,
	loading: state.user.status.loading,
	error: state.user.status.error
});

const mapDispatchToProps = (dispatch) => ({
	getUserMe: () => dispatch(getUserMe()),
	saveUserMe: (data) => dispatch(saveUserMe(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Profile);
