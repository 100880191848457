import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	Row,
	Col,
	Alert
} from 'react-bootstrap';
import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	Select,
	Form,
	File,
	Input
} from '../../../../components/Form';
import {GraphType, ImportFileType} from '../../../../util/types';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class ImportGraph extends Component {
	state = {
		success: false,
		invalid: false,
		name: '',
		type: '',
		file: null,
		graphType: null
	}

	componentDidMount() {
		const {graphId} = this.props.match.params;
		const {getGraph} = this.props;

		setTitle(['Wykresy', `Wykres #${graphId}`, 'Zaimportuj nową definicję']);

		getGraph(graphId);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.graph && this.props.graph && this.props.graph.id === parseInt(this.props.match.params.graphId, 10)) {
			const {graph} = this.props;

			this.setState({
				graphType: graph.graph_type_name
			});
		}

		if (!prevProps.imported && this.props.imported) {
			this.props.listGraphGroups(this.props.match.params.graphId, 'id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/graph/${this.props.match.params.graphId}/group`);
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			importGraph,
			loading
		} = this.props;

		const {
			name,
			type,
			file
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		const {graphId} = this.props.match.params;

		importGraph({
			name,
			graph_id: graphId,
			type,
			file
		});

		return true;
	};

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {graphId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			name,
			type,
			file,
			graphType
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Wczytaj nową definicję dla wykresu #{graphId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
							<Input
								name="name"
								value={name}
								label="Nazwa definicji"
								placeholder="Wpisz nazwę definicji"
								handleChange={this.handleChange}
								required
							/>

							{
								[GraphType.BAR, GraphType.LINE, GraphType.MULTILINE].includes(graphType) && (
									<>
										<Alert variant="danger">
											<strong>Najpierw przeczytaj</strong>, a potem przejdź dalej ;)<br />
											Wykonujesz import do wykresu, który zawiera zgrupowane pozycje. Jeżeli
											nazwa Twojej definicji to np. <em>grudzień 2024</em> pozyskamy z rok i
											użyjemy go jako grupy (w razie potrzeby zostanie nawet ona stworzona).
											W przeciwnym wypadku import zostanie wykonany do grupy <em>Pozycje</em>.
										</Alert>
									</>
								)
							}

							<Select
								name="type"
								value={type}
								label="Rodzaj pliku grupy"
								options={[
									{
										label: 'Wybierz rodzaj pliku',
										value: ''
									},
									{
										label: 'Czyste powietrze XLS (liniowy lub słupkowy)',
										value: ImportFileType.CP_LINE_XLS
									},
									{
										label: 'Czyste powietrze XLS (liniowy skumulowany)',
										value: ImportFileType.CP_MULTILINE_XLS
									},
									{
										label: 'Czyste powietrze XLS (kołowy)',
										value: ImportFileType.CP_CIRCLE_XLS
									}
								]}
								handleChange={this.handleChange}
								required
							/>

							{
								type === ImportFileType.CP_LINE_XLS && (
									<Alert variant="warning">
										<strong>Zatrzymaj się</strong> na chwilę :)<br />
										Jeżeli wykonujesz import to musisz upewnić się, że Twój wykres wspiera ten rodzaj
										pliku pomiaru.<br />
										<br />
										Aby Twój wykres wspierał musi zawierać zdefiniowane grupy z tego pliku, czyli:
										<ol>
											<li>Liczba wszystkich złożonych wniosków</li>
											<li>Liczba złożonych wniosków na wymianę kotła</li>
											<li>Liczba złożonych wniosków</li>
											<li>Liczba podpisanych umów</li>
											<li>Liczba wypłaconych dotacji</li>
										</ol>
										...oraz następujące pozycje:
										<ol>
											<li>Cała Polska</li>
											<li>Dolnośląskie</li>
											<li>Kujawsko-pomorskie</li>
											<li>Lubuskie</li>
											<li>Lubelskie</li>
											<li>Łódzkie</li>
											<li>Małopolskie</li>
											<li>Mazowieckie</li>
											<li>Opolskie</li>
											<li>Podkarpackie</li>
											<li>Podlaskie</li>
											<li>Pomorskie</li>
											<li>Śląskie</li>
											<li>Świętokrzyskie</li>
											<li>Warmińsko-mazurskie</li>
											<li>Wielkopolskie</li>
											<li>Zachodno-pomorskie</li>
										</ol>
										Jeżeli są grupy lub pozycje na tym wykresie, które wykraczają poza tę listę musisz
										wprowadzić zmiany w wykresie. W przypadku, gdy nie są zdefiniowane wszystkie pola to
										te, które nie istnieją zostaną pominięte.
									</Alert>
								)
							}

							{
								type === ImportFileType.CP_MULTILINE_XLS && (
									<Alert variant="warning">
										<strong>Zatrzymaj się</strong> na chwilę :)<br />
										Jeżeli wykonujesz import to musisz upewnić się, że Twój wykres wspiera ten rodzaj
										pliku pomiaru.<br />
										<br />
										Aby Twój wykres wspierał musi zawierać zdefiniowane grupy z tego pliku, czyli:
										<ol>
											<li>Cała Polska</li>
										</ol>
										...oraz następujące pozycje:
										<ol>
											<li>Kotły na biomasę</li>
											<li>Kotły gazowe kondensacyjne</li>
											<li>Pompy ciepła</li>
											<li>Kotły węglowe</li>
											<li>Pozostałe</li>
										</ol>
										Jeżeli są grupy lub pozycje na tym wykresie, które wykraczają poza tę listę musisz
										wprowadzić zmiany w wykresie. W przypadku, gdy nie są zdefiniowane wszystkie pola to
										te, które nie istnieją zostaną pominięte.
									</Alert>
								)
							}

							{
								type === ImportFileType.CP_CIRCLE_XLS && (
									<Alert variant="warning">
										<strong>Zatrzymaj się</strong> na chwilę :)<br />
										Jeżeli wykonujesz import to musisz upewnić się, że Twój wykres wspiera ten rodzaj
										pliku pomiaru.<br />
										<br />
										Aby Twój wykres wspierał musi zawierać zdefiniowane własności z tego pliku, czyli:
										<ol>
											<li>Kotły na biomasę</li>
											<li>Kotły węglowe</li>
											<li>Węzły cieplne</li>
											<li>Systemy ogrzewania elektrycznego</li>
											<li>Kotły olejowe</li>
											<li>Kotły gazowe</li>
											<li>Pompy ciepła powietrzne</li>
											<li>Pompy ciepła odbierające ciepło z gruntu lub wody</li>
										</ol>
										Jeżeli są pola na tym wykresie, które wykraczają poza tę listę musisz wprowadzić
										zmiany w edycji wykresu. W przypadku, gdy nie są zdefiniowane wszystkie pola to te,
										które nie istnieją zostaną pominięte.
									</Alert>
								)
							}

							{
								type && (
									<File
										name="file"
										value={file}
										label="Plik definicji"
										handleChange={this.handleChange}
										required
									/>
								)
							}

							<Button variant="outline-primary" type="submit">
								Zaimportuj nową definicję
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

ImportGraph.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	graph: PropTypes.object,
	getGraph: PropTypes.func.isRequired,
	listGraphGroups: PropTypes.func.isRequired,
	imported: PropTypes.bool.isRequired,
	importGraph: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

ImportGraph.defaultProps = {
	graph: null
};

export default ImportGraph;
