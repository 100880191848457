import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	Row,
	Col,
	Alert
} from 'react-bootstrap';
import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	Select,
	Form,
	File, Input
} from '../../../../components/Form';
import {ImportFileType} from '../../../../util/types';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class ImportRankGroupValue extends Component {
	state = {
		success: false,
		invalid: false,
		name: '',
		type: '',
		file: null
	}

	componentDidMount() {
		const {rankId} = this.props.match.params;

		setTitle(['Rankingi', `Ranking #${rankId}`, 'Zaimportuj nową definicję']);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.imported && this.props.imported) {
			this.props.listRankGroups(this.props.match.params.rankId, 'id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/rank/${this.props.match.params.rankId}/group`);
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			importRank,
			loading
		} = this.props;

		const {
			name,
			type,
			file
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		const {rankId} = this.props.match.params;

		importRank({
			name,
			rank_id: rankId,
			type,
			file
		});

		return true;
	};

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {rankId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			name,
			type,
			file
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Wczytaj nową definicję dla rankingu #{rankId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
							<Input
								name="name"
								value={name}
								label="Nazwa definicji"
								placeholder="Wpisz nazwę definicji"
								handleChange={this.handleChange}
								required
							/>

							<Select
								name="type"
								value={type}
								label="Rodzaj pliku definicji"
								options={[
									{
										label: 'Wybierz rodzaj pliku',
										value: ''
									},
									{
										label: 'Ranking województw XLS',
										value: ImportFileType.RV_XLS
									}
								]}
								handleChange={this.handleChange}
								required
							/>

							{
								type === ImportFileType.RV_XLS && (
									<Alert variant="warning">
										<strong>Zatrzymaj się</strong> na chwilę :)<br />
										Jeżeli wykonujesz import to wszystkie nowe województwa zostaną dodane do bazy systemu. Może się to przełożyć na duplikaty w przypadku, gdy plik zawiera literówki.<br />
										<br />
										Ponadto aby Twój ranking wspierał musi zawierać zdefiniowane grupy z tego pliku, czyli:
										<ol>
											<li>Liczba wszystkich złożonych wniosków</li>
											<li>Liczba złożonych wniosków na wymianę kotła</li>
											<li>Liczba złożonych wniosków</li>
											<li>Liczba podpisanych umów</li>
											<li>Liczba wypłaconych dotacji</li>
										</ol>
										W przypadku, gdy wartość nakłada się już z istniejącą to zostanie nadpisana.
									</Alert>
								)
							}

							{
								type && (
									<File
										name="file"
										value={file}
										label="Plik definicji"
										handleChange={this.handleChange}
										required
									/>
								)
							}

							<Button variant="outline-primary" type="submit">
								Zaimportuj nowe wartości
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

ImportRankGroupValue.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	listRankGroups: PropTypes.func.isRequired,
	imported: PropTypes.bool.isRequired,
	importRank: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

export default ImportRankGroupValue;
