import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Row,
	Col,
	Container
} from 'react-bootstrap';

import LayoutPublic from '../../../components/Layout/Public';
import {handleChange, setTitle} from '../../../util/common';
import {Form, Input} from '../../../components/Form';
import {MainPrivateView, SuccessMessageTimeout} from '../../../util/globals';
import {Status} from '../../../components/Other';

class ForgotPassword extends Component {
	state = {
		initialized: false,
		success: false,
		invalid: false,
		email: ''
	}

	componentDidMount() {
		setTitle(['Przywracanie hasła']);

		if (window.localStorage.getItem('token')) {
			this.props.history.push(MainPrivateView);
		} else {
			this.setState({
				initialized: true
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.reset && this.props.reset) {
			this.setState({
				success: true,
				invalid: false
			}, () => {
				setTimeout(() => {
					this.props.history.push('/');
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {resetPassword, loading} = this.props;
		const {email} = this.state;

		if (loading) return false;

		if (email === '') {
			this.setState({
				invalid: true,
				success: false
			});

			return false;
		}

		this.setState({
			invalid: false,
			success: false
		});

		resetPassword(email);
		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {loading, error} = this.props;
		const {
			initialized,
			success,
			invalid,
			email
		} = this.state;

		if (!initialized) {
			return (
				<LayoutPublic>
					<Container style={{marginTop: '3rem', marginBottom: '3rem'}}>
						<h2 style={{marginBottom: '2rem'}}>Chwileczkę...</h2>
						<p>Aplikacja aktualnie wczytuje wszystkie potrzebne informacje do autoryzacji...</p>
					</Container>
				</LayoutPublic>
			);
		}

		return (
			<LayoutPublic>
				<Row>
					<Col lg={{span: 6, offset: 3}}>
						<h2 style={{marginBottom: '2rem'}}>Przywracanie hasła</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
							<Input
								name="email"
								value={email}
								type="email"
								label="E-mail"
								placeholder="Wpisz e-mail"
								handleChange={this.handleChange}
								required
							/>

							<Button variant="outline-primary" type="submit">
								Przypomnij hasło
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPublic>
		);
	}
}

ForgotPassword.propTypes = {
	history: PropTypes.object.isRequired,
	reset: PropTypes.bool.isRequired,
	resetPassword: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

export default ForgotPassword;
