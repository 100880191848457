import React, {Component} from 'react';
import {Form} from 'react-bootstrap';
import PropTypes from 'prop-types';

class Input extends Component {
	handleChange = (e) => {
		const {handleChange, name} = this.props;

		handleChange(name, e.target.value);
	}

	render() {
		const {
			name,
			value,
			type,
			label,
			placeholder,
			required,
			step,
			disabled
		} = this.props;

		return (
			<Form.Group className="mb-3" controlId={name}>
				{
					label && (
						<Form.Label>{label}</Form.Label>
					)
				}
				<Form.Control
					name={name}
					type={type}
					defaultValue={value}
					placeholder={placeholder}
					onChange={this.handleChange}
					required={required}
					step={step}
					disabled={disabled}
				/>
			</Form.Group>
		);
	}
}

Input.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'date']),
	label: PropTypes.string,
	placeholder: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	step: PropTypes.string,
	disabled: PropTypes.bool
};

Input.defaultProps = {
	type: 'text',
	label: null,
	required: false,
	step: '1',
	disabled: false
};

export default Input;
