import {connect} from 'react-redux';

import {importRecipient, listRecipients} from '../../../../actions/recipient';
import Import from './Import';

const mapStateToProps = (state) => ({
	imported: state.recipient.imported,
	loading: state.recipient.status.loading,
	error: state.recipient.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRecipients: (recipientGroupId, order, asc) => dispatch(listRecipients(recipientGroupId, order, asc)),
	importRecipient: (data) => dispatch(importRecipient(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Import);
