import axios from 'axios';

import {RecipientGroup} from '../util/actionTypes';

export const listRecipientGroups = (order, asc) => (dispatch) => {
	dispatch({
		type: RecipientGroup.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/recipient_group/?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RecipientGroup.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: RecipientGroup.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RecipientGroup.LIST.FAILURE
			});
		});
};

export const getRecipientGroup = (id) => (dispatch) => {
	dispatch({
		type: RecipientGroup.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/recipient_group/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RecipientGroup.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: RecipientGroup.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RecipientGroup.GET.FAILURE
			});
		});
};

export const addRecipientGroup = (data) => (dispatch) => {
	dispatch({
		type: RecipientGroup.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/recipient_group/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RecipientGroup.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: RecipientGroup.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RecipientGroup.ADD.FAILURE
			});
		});
};

export const editRecipientGroup = (id, data) => (dispatch) => {
	dispatch({
		type: RecipientGroup.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/recipient_group/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RecipientGroup.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: RecipientGroup.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RecipientGroup.EDIT.FAILURE
			});
		});
};

export const deleteRecipientGroup = (id) => (dispatch) => {
	dispatch({
		type: RecipientGroup.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/recipient_group/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RecipientGroup.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: RecipientGroup.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RecipientGroup.DELETE.FAILURE
			});
		});
};
