import axios from 'axios';

import {User} from '../util/actionTypes';

export const getUserMe = () => (dispatch) => {
	dispatch({
		type: User.GET_ME.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/user/me`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: User.GET_ME.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: User.GET_ME.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: User.GET_ME.FAILURE
			});
		});
};

export const saveUserMe = (data) => (dispatch) => {
	dispatch({
		type: User.SAVE_ME.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/user/update`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: User.SAVE_ME.SUCCESS
				});
			} else {
				dispatch({
					type: User.SAVE_ME.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: User.SAVE_ME.FAILURE
			});
		});
};

export const listUsers = (order, asc) => (dispatch) => {
	dispatch({
		type: User.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/user/?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: User.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: User.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: User.LIST.FAILURE
			});
		});
};

export const addUser = (data) => (dispatch) => {
	dispatch({
		type: User.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/user/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: User.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: User.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: User.ADD.FAILURE
			});
		});
};

export const deleteUser = (id) => (dispatch) => {
	dispatch({
		type: User.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/user/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: User.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: User.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: User.DELETE.FAILURE
			});
		});
};

