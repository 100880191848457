import {connect} from 'react-redux';

import {importRankValue, listRankGroupValues} from '../../../../actions/rankValue';
import Import from './Import';

const mapStateToProps = (state) => ({
	imported: state.rankValue.imported,
	loading: state.rankValue.status.loading,
	error: state.rankValue.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRankValues: (rankGroupId, order, asc) => dispatch(listRankGroupValues(rankGroupId, order, asc)),
	importRankValue: (data) => dispatch(importRankValue(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Import);
