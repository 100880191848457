import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Row, Col, Alert} from 'react-bootstrap';

import {Modal, Status, Table} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';
import LayoutPrivate from '../../../../components/Layout/Private';
import {
	formatTimestamp,
	handleChange,
	setTitle
} from '../../../../util/common';

class ListRecipientGroup extends Component {
	state = {
		success: false,
		deleteId: null,
		order: 'id',
		asc: false
	}

	componentDidMount() {
		setTitle(['Listy', 'Grupy odbiorców']);

		this.loadData(true);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.deleted && this.props.deleted) {
			this.props.listRecipientGroups(this.state.order, this.state.asc);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});
				}, SuccessMessageTimeout);
			});
		}
	}

	sortable = (type) => {
		this.setState((prevState) => ({
			order: type,
			asc: prevState.order === type ? !prevState.asc : prevState.asc
		}), () => {
			this.loadData();
		});
	}

	loadData = (init = false) => {
		const {list, listRecipientGroups} = this.props;
		const {order, asc} = this.state;

		if (list) {
			if (list.order !== order || list.asc !== asc) {
				if (init) {
					this.setState({
						order: list.order,
						asc: list.asc
					});
				} else {
					listRecipientGroups(order, asc);
				}
			}
		} else {
			listRecipientGroups(order, asc);
		}
	}

	handleChange = (name, value) => handleChange(this, name, value);

	handleDelete = (deleteId) => {
		this.setState({
			deleteId
		});
	}

	handleDeleteConfirmed = () => {
		const {deleteRecipientGroup, loading} = this.props;
		const {deleteId} = this.state;

		if (loading) {
			return false;
		}

		this.setState({
			deleteId: null,
			success: false
		});

		deleteRecipientGroup(deleteId);

		return true;
	}

	handleDeleteClose = () => {
		this.setState({
			deleteId: null
		});
	}

	render() {
		const {
			list,
			history,
			loading,
			error
		} = this.props;

		const {
			success,
			deleteId,
			order,
			asc
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col>
						<h2 style={{marginBottom: '2rem'}}>Utworzone grupy odbiorców</h2>
						<Status
							success={success}
							error={error}
							loading={loading}
						/>
						<Table
							sortable={this.sortable}
							cols={[
								{
									sortable: true,
									name: 'id',
									label: '#'
								},
								{
									sortable: true,
									name: 'name',
									label: 'Nazwa',
									render: (item) => (
										<Link className="link-dark" to={`/letter/recipient_group/${item.id}/recipient`}>
											{item.name}
										</Link>
									)
								},
								{
									sortable: true,
									name: 'updated',
									label: 'Zmieniono',
									render: (item) => (item.updated === item.created ? formatTimestamp(item.updated) : <em>{formatTimestamp(item.updated)}</em>)
								}
							]}
							order={order}
							asc={asc}
							operations={[
								{
									style: 'outline-primary',
									link: '/letter/recipient_group/add',
									label: 'Dodaj nową grupę'
								},
								{
									style: 'outline-info',
									link: '/letter',
									label: 'Wróć do listów'
								}
							]}
							list={list ? list.list : null}
							actions={[
								{
									style: 'outline-success',
									action: (item) => history.push(`/letter/recipient_group/${item.id}`),
									label: 'Edytuj'
								},
								{
									style: 'outline-danger',
									action: (item) => this.handleDelete(item.id),
									label: 'Usuń'
								}
							]}
						/>
					</Col>
				</Row>
				{
					deleteId && (
						<Modal
							title="Potwierdź akcję"
							confirmAction={this.handleDeleteConfirmed}
							cancelAction={this.handleDeleteClose}
						>
							<p>
								Naprawdę chcesz usunąć grupę #{deleteId}? To spowoduje, że zniknie on ze wszystkich miejsc, na których zostało umieszczony. Stanie się tak jakby nigdy nie istniał.
							</p>
							<Alert variant="warning">
								<strong>Zatrzymaj się na chwilę!</strong> Ta operacja jest nieodwracalna z poziomu panelu administratora. Może ją przywrócić tylko administrator systemu.
							</Alert>
						</Modal>
					)
				}
			</LayoutPrivate>
		);
	}
}

ListRecipientGroup.propTypes = {
	history: PropTypes.object.isRequired,
	list: PropTypes.object,
	listRecipientGroups: PropTypes.func.isRequired,
	deleted: PropTypes.bool.isRequired,
	deleteRecipientGroup: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

ListRecipientGroup.defaultProps = {
	list: null
};

export default ListRecipientGroup;
