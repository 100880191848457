import {connect} from 'react-redux';

import {deleteRecipientGroup, listRecipientGroups} from '../../../../actions/recipientGroup';
import List from './List';

const mapStateToProps = (state) => ({
	list: state.recipientGroup.list,
	deleted: state.recipientGroup.deleted,
	loading: state.recipientGroup.status.loading,
	error: state.recipientGroup.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRecipientGroups: (order, asc) => dispatch(listRecipientGroups(order, asc)),
	deleteRecipientGroup: (id) => dispatch(deleteRecipientGroup(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
