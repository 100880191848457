import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {Form, Input} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class ProfileAccount extends Component {
	state = {
		initialized: false,
		success: false,
		invalid: false,
		email: '',
		password: '',
		firstname: '',
		lastname: ''
	}

	componentDidMount() {
		const {me} = this.props;

		setTitle(['Twoje konto']);

		if (me) {
			this.setUserMe();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.me !== this.props.me && this.props.me) {
			this.setUserMe();
		}

		if (!prevProps.savedMe && this.props.savedMe) {
			this.props.getUserMe();

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});
				}, SuccessMessageTimeout);
			});
		}
	}

	setUserMe = () => {
		const {me} = this.props;

		this.setState({
			initialized: true,
			email: me.email,
			firstname: me.firstname,
			lastname: me.lastname
		});
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			saveUserMe,
			loading
		} = this.props;

		const {
			email,
			password,
			firstname,
			lastname
		} = this.state;

		if (loading) return false;

		if (email === '') {
			this.setState({
				invalid: true,
				success: false
			});

			return false;
		}

		let data = null;

		if (password === '') {
			data = {
				email,
				firstname,
				lastname
			};
		} else {
			data = {
				email,
				password,
				firstname,
				lastname
			};
		}

		this.setState({
			invalid: false,
			success: false
		});

		saveUserMe(data);

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {
			loading,
			error
		} = this.props;

		const {
			initialized,
			firstname,
			lastname,
			email,
			password,
			success,
			invalid
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 6, offset: 3}}>
						<h2 style={{marginBottom: '2rem'}}>Zaktualizuj swoje konto użytkownika</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							initialized && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<Row>
										<Col lg={{span: 6}}>
											<Input
												name="firstname"
												value={firstname}
												label="Imię"
												placeholder="Wpisz imię"
												handleChange={this.handleChange}
											/>
										</Col>
										<Col lg={{span: 6}}>
											<Input
												name="lastname"
												value={lastname}
												label="Nazwisko"
												placeholder="Wpisz nazwisko"
												handleChange={this.handleChange}
											/>
										</Col>
									</Row>

									<Input
										name="email"
										value={email}
										type="email"
										label="E-mail"
										placeholder="Wpisz e-mail"
										handleChange={this.handleChange}
										required
									/>

									<Input
										name="password"
										value={password}
										type="password"
										label="Hasło"
										placeholder="Wpisz hasło tylko, gdy zmieniasz"
										handleChange={this.handleChange}
										required
									/>

									<Button variant="outline-primary" type="submit">
										Zapisz zmiany
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

ProfileAccount.propTypes = {
	me: PropTypes.object,
	getUserMe: PropTypes.func.isRequired,
	savedMe: PropTypes.bool.isRequired,
	saveUserMe: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

ProfileAccount.defaultProps = {
	me: null
};

export default ProfileAccount;
