import React, {Component} from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import shortid from 'shortid';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	Select,
	FieldGroup,
	Form,
	Input,
	Checkbox
} from '../../../../components/Form';
import {PropertyType} from '../../../../util/types';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class AddMap extends Component {
	state = {
		success: false,
		invalid: false,
		name: '',
		internalName: '',
		source: '',
		details: '',
		showSummary: true,
		summaryTitle: '',
		propertyKeys: []
	}

	componentDidMount() {
		setTitle(['Mapy', 'Dodaj nową mapę']);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.added && this.props.added) {
			this.props.listMaps('id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push('/map');
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			addMap,
			loading
		} = this.props;

		const {
			name,
			internalName,
			source,
			details,
			propertyKeys
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		addMap({
			name,
			internal_name: internalName,
			source,
			details,
			property_keys: propertyKeys
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	addPropertyKey = () => {
		const propertyKey = {
			uuid: shortid.generate(),
			id: null,
			name: '',
			display_name: '',
			property_type_name: ''
		};

		this.setState((prevProps) => update(prevProps, {
			propertyKeys: {$push: [propertyKey]}
		}));
	}

	removePropertyKey = (index) => {
		this.setState((prevProps) => update(prevProps, {
			propertyKeys: {$splice: [[index, 1]]}
		}));
	}

	render() {
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			name,
			internalName,
			source,
			details,
			showSummary,
			summaryTitle,
			propertyKeys
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Nowa mapa</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
							<Input
								name="name"
								value={name}
								label="Nazwa mapy"
								placeholder="Wpisz nazwę mapy"
								handleChange={this.handleChange}
								required
							/>

							<Input
								name="internalName"
								value={internalName}
								label="Wewnętrzna nazwa mapy (w odnośniku)"
								placeholder="Wpisz wewnętrzną nazwę mapy"
								handleChange={this.handleChange}
								required
							/>

							<Input
								name="source"
								value={source}
								label="Domyślne źródło danych mapy"
								placeholder="Wpisz domyślne źródło danych mapy"
								handleChange={this.handleChange}
								required
							/>

							<Input
								name="details"
								value={details}
								label="Szczegółowe informacje o mapie"
								placeholder="Wpisz szczegółowe informacje o mapie"
								handleChange={this.handleChange}
							/>

							<Checkbox
								name="showSummary"
								value={showSummary}
								handleChange={this.handleChange}
								label="Podsumowanie wyników"
								fieldLabel="Wyświetl podsumowanie wyników zsumowych ze wszystkich pomiarów"
							/>

							{
								showSummary && (
									<>
										<Input
											name="summaryTitle"
											value={summaryTitle}
											label="Nazwa specjalnego pomiaru wyświetlającego zsumowane wyniki ze wszystkich pomiarów"
											placeholder="Wpisz nazwę specjalnego pomiaru wyświetlającego zsumowane wyniki ze wszystkich pomiarów"
											handleChange={this.handleChange}
										/>
										<p>
											<small>Domyślna nazwa tego specjalnego pomiaru to &quot;Wszystkie pomiary&quot;, ale może być nadpisana zgodnie z Twoimi preferencjami. Dopuszczalne są wyrażenia <em>%FIRST_CHECKPOINT%</em> oraz <em>%LAST_CHECKPOINT%</em>, które zostaną zamienione z nazwą pierwszego i ostatniego pomiaru.</small>
										</p>
									</>
								)
							}

							<FieldGroup label="Własności mapy">
								{
									propertyKeys.map((propertyKey, index) => (
										<Row
											key={propertyKey.uuid}
											style={{
												border: '1px solid #d0d0d0',
												borderRadius: '5px',
												margin: '15px',
												padding: '1rem 0'
											}}
										>
											<Col lg={{span: 4}}>
												<Select
													name={`propertyKeys[${index}].property_type_name`}
													value={propertyKey.property_type_name}
													label="Rodzaj właściwości"
													options={[
														{
															label: 'Wybierz rodzaj',
															value: ''
														},
														{
															label: 'Informacyjna (nieistotna dla mapy)',
															value: PropertyType.INFORMATIVE
														},
														{
															label: 'Statystyczna (istotna dla mapy)',
															value: PropertyType.STATISTIC
														},
														{
															label: 'Doprecyzowująca (definiująca submapę)',
															value: PropertyType.PRECISE
														}
													]}
													handleChange={this.handleChange}
													required
												/>
											</Col>
											{
												propertyKey.property_type_name === PropertyType.PRECISE ? (
													<>
														<Col lg={{span: 4}}>
															<Input
																name={`propertyKeys[${index}].name`}
																value={propertyKey.name}
																label="Nazwa submapy"
																placeholder="Wpisz nazwę submapy"
																handleChange={this.handleChange}
																required
															/>
														</Col>
														<Col lg={{span: 4}}>
															<Input
																name={`propertyKeys[${index}].display_name`}
																value={propertyKey.display_name}
																label="Nazwa właściwości"
																placeholder="Wpisz nazwę właściwości (jeżeli inna niż submapy)"
																handleChange={this.handleChange}
															/>
														</Col>
													</>
												) : (
													<Col lg={{span: 8}}>
														<Input
															name={`propertyKeys[${index}].name`}
															value={propertyKey.name}
															label="Nazwa właściwości"
															placeholder="Wpisz nazwę właściwości"
															handleChange={this.handleChange}
															required
														/>
													</Col>
												)
											}
											<Col lg={{span: 12}}>
												<Button size="sm" variant="outline-danger" onClick={() => this.removePropertyKey(index)}>
													Usuń tą właściwość
												</Button>
											</Col>
										</Row>
									))
								}

								<Button size="sm" variant="outline-success" onClick={this.addPropertyKey}>
									Dodaj nową właściwość
								</Button>
							</FieldGroup>

							<Button variant="outline-primary" type="submit">
								Utwórz nową mapę
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

AddMap.propTypes = {
	history: PropTypes.object.isRequired,
	listMaps: PropTypes.func.isRequired,
	added: PropTypes.bool.isRequired,
	addMap: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

export default AddMap;
