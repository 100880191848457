import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	Row,
	Col,
	Alert
} from 'react-bootstrap';
import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	Select,
	Form,
	Input,
	File
} from '../../../../components/Form';
import {ImportFileType, PropertyType} from '../../../../util/types';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class ImportCheckpoint extends Component {
	state = {
		success: false,
		invalid: false,
		name: '',
		source: '',
		captured: '',
		type: '',
		file: null
	}

	componentDidMount() {
		const {mapId} = this.props.match.params;

		setTitle(['Mapy', `Mapa #${mapId}`, 'Pomiary', 'Zaimportuj nowy pomiar']);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.imported && this.props.imported) {
			this.props.listCheckpoints(this.props.match.params.mapId, 'captured', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/map/${this.props.match.params.mapId}/checkpoint`);
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			importCheckpoint,
			loading
		} = this.props;

		const {
			name,
			source,
			captured,
			type,
			file
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		const {mapId} = this.props.match.params;

		importCheckpoint({
			name,
			source,
			captured,
			map_id: mapId,
			type,
			file
		});

		return true;
	};

	handleChange = (name, value) => handleChange(this, name, value);

	printType = (type) => {
		switch (type) {
			case PropertyType.INFORMATIVE:
				return 'informacyjna';
			case PropertyType.STATISTIC:
				return 'statystyczna';
			case PropertyType.PRECISE:
				return 'precyzująca';
			default:
				return '';
		}
	}

	render() {
		const {mapId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			name,
			source,
			captured,
			type,
			file
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Wczytaj nowy pomiar dla mapy #{mapId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
							<Input
								name="name"
								value={name}
								label="Nazwa pomiaru"
								placeholder="Wpisz nazwę pomiaru"
								handleChange={this.handleChange}
								required
							/>

							<Input
								name="source"
								value={source}
								label="Źródło danych dla pomiaru (jeżeli inne niż mapy)"
								placeholder="Wpisz źródło danych dla pomiaru"
								handleChange={this.handleChange}
							/>

							<Input
								name="captured"
								value={captured}
								type="date"
								label="Data wykonania pomiaru (jeżeli inna niż dzisiaj)"
								placeholder="Wpisz datę wykonania pomiaru"
								handleChange={this.handleChange}
							/>

							<Select
								name="type"
								value={type}
								label="Rodzaj pliku pomiaru"
								options={[
									{
										label: 'Wybierz rodzaj pliku',
										value: ''
									},
									{
										label: 'Czyste powietrze XLS',
										value: ImportFileType.CP_XLS
									}
								]}
								handleChange={this.handleChange}
								required
							/>

							{
								type === ImportFileType.CP_XLS && (
									<Alert variant="warning">
										<strong>Zatrzymaj się</strong> na chwilę :)<br />
										Jeżeli wykonujesz import to musisz upewnić się, że Twoja mapa wspiera ten rodzaj pliku pomiaru.<br />
										<br />
										Aby Twoja mapa wspierała musi zawierać zdefiniowane właściwości z tego pliku, czyli:
										<ol>
											<li>Liczba złożonych wniosków</li>
											<li>Liczba podpisanych umów</li>
											<li>Liczba wypłaconych dotacji</li>
											<li>Kotły na biomasę</li>
											<li>Kotły węglowe</li>
											<li>Węzły cieplne</li>
											<li>Systemy ogrzewania elektrycznego</li>
											<li>Kotły olejowe</li>
											<li>Kotły gazowe</li>
											<li>Pompy ciepła powietrzne</li>
											<li>Pompy ciepła odbierające ciepło z gruntu lub wody</li>
										</ol>
										Jeżeli są pola na tej mapie, które wykraczają poza tę listę musisz wprowadzić zmiany w edycji pomiaru. W przypadku, gdy nie są zdefiniowane wszystkie pola to te, które nie istnieją zostaną pominięte.
									</Alert>
								)
							}

							{
								type && (
									<File
										name="file"
										value={file}
										label="Plik pomiaru"
										handleChange={this.handleChange}
										required
									/>
								)
							}

							<Button variant="outline-primary" type="submit">
								Zaimportuj nowy pomiar
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

ImportCheckpoint.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	listCheckpoints: PropTypes.func.isRequired,
	imported: PropTypes.bool.isRequired,
	importCheckpoint: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

export default ImportCheckpoint;
