import axios from 'axios';

import {Calculator} from '../util/actionTypes';

export const listCalculators = (order, asc) => (dispatch) => {
	dispatch({
		type: Calculator.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/calculator/?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Calculator.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: Calculator.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Calculator.LIST.FAILURE
			});
		});
};

export const getCalculator = (id) => (dispatch) => {
	dispatch({
		type: Calculator.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/calculator/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Calculator.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: Calculator.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Calculator.GET.FAILURE
			});
		});
};

export const addCalculator = (data) => (dispatch) => {
	dispatch({
		type: Calculator.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/calculator/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Calculator.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: Calculator.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Calculator.ADD.FAILURE
			});
		});
};

export const editCalculator = (id, data) => (dispatch) => {
	dispatch({
		type: Calculator.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/calculator/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Calculator.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: Calculator.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Calculator.EDIT.FAILURE
			});
		});
};

export const deleteCalculator = (id) => (dispatch) => {
	dispatch({
		type: Calculator.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/calculator/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Calculator.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: Calculator.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Calculator.DELETE.FAILURE
			});
		});
};
