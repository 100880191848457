import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	Checkbox,
	Form,
	Input,
	Select,
	Textarea
} from '../../../../components/Form';
import {CalculatorType} from '../../../../util/types';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class AddCalculator extends Component {
	state = {
		success: false,
		invalid: false,
		name: '',
		internalName: '',
		type: '',
		showRefund: false,
		refundCheckbox: '',
		legend: ''
	}

	componentDidMount() {
		setTitle(['Kalkulatory', 'Dodaj nowy kalkulator']);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.added && this.props.added) {
			this.props.listCalculators('id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push('/calculator');
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			addCalculator,
			loading
		} = this.props;

		const {
			name,
			internalName,
			type,
			showRefund,
			refundCheckbox,
			legend
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		addCalculator({
			name,
			internal_name: internalName,
			calculator_type_name: type,
			show_refund: showRefund,
			refund_checkbox: refundCheckbox,
			legend
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			name,
			internalName,
			type,
			showRefund,
			refundCheckbox,
			legend
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Nowy kalkulator</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
							<Input
								name="name"
								value={name}
								label="Nazwa kalkulatora"
								placeholder="Wpisz nazwę kalkulatora"
								handleChange={this.handleChange}
								required
							/>

							<Input
								name="internalName"
								value={internalName}
								label="Wewnętrzna nazwa kalkulatora (w odnośniku)"
								placeholder="Wpisz wewnętrzną nazwę kalkulatora"
								handleChange={this.handleChange}
								required
							/>

							<Select
								name="type"
								value={type}
								label="Rodzaj kalkulatora"
								options={[
									{
										label: 'Wybierz rodzaj',
										value: ''
									},
									{
										label: 'Kalkulator kosztów ogrzewania',
										value: CalculatorType.HEATING
									}
								]}
								handleChange={this.handleChange}
								required
							/>

							<Checkbox
								name="showRefund"
								value={showRefund}
								handleChange={this.handleChange}
								label="Uwzględnij aktualne dopłaty"
								fieldLabel="Dodaj możliwość uwzględniania aktualnych dopłat w kalkulatorze"
							/>

							{
								showRefund && (
									<Input
										name="refundCheckbox"
										value={refundCheckbox}
										label="Niestandardowy tekst dla opcji uwzględnienia dopłat (opcjonalnie)"
										placeholder="Wpisz niestandardowy tekst dla opcji uwzględnienia dopłat (opcjonalnie)"
										handleChange={this.handleChange}
									/>
								)
							}

							<Textarea
								name="legend"
								value={legend}
								label="Legenda dla obliczeń kalkulatora (opcjonalnie)"
								placeholder="Wpisz legendę dla obliczeń kalkulatora (opcjonalnie)"
								handleChange={this.handleChange}
							/>

							<Button variant="outline-primary" type="submit">
								Utwórz nowy kalkulator
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

AddCalculator.propTypes = {
	history: PropTypes.object.isRequired,
	listCalculators: PropTypes.func.isRequired,
	added: PropTypes.bool.isRequired,
	addCalculator: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

export default AddCalculator;
