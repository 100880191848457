import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Alert} from 'react-bootstrap';
import shortid from 'shortid';

import {
	Code,
	Modal,
	Status,
	Table
} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';
import LayoutPrivate from '../../../../components/Layout/Private';
import {
	formatTimestamp,
	generateIframe,
	handleChange,
	setTitle
} from '../../../../util/common';

class ListItem extends Component {
	state = {
		success: false,
		deleteId: null,
		order: 'id',
		asc: false,
		embed: null
	}

	componentDidMount() {
		setTitle(['Obiekty']);

		this.loadData(true);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.deleted && this.props.deleted) {
			this.props.listItems(this.state.order, this.state.asc);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});
				}, SuccessMessageTimeout);
			});
		}
	}

	sortable = (type) => {
		this.setState((prevState) => ({
			order: type,
			asc: prevState.order === type ? !prevState.asc : prevState.asc
		}), () => {
			this.loadData();
		});
	}

	loadData = (init = false) => {
		const {list, listItems} = this.props;
		const {order, asc} = this.state;

		if (list) {
			if (list.order !== order || list.asc !== asc) {
				if (init) {
					this.setState({
						order: list.order,
						asc: list.asc
					});
				} else {
					listItems(order, asc);
				}
			}
		} else {
			listItems(order, asc);
		}
	}

	handleChange = (name, value) => handleChange(this, name, value);

	handleEmbed = (name) => {
		this.setState({
			embed: {
				name,
				uuid: shortid.generate()
			}
		});
	}

	handleEmbedClose = () => {
		this.setState({
			embed: null
		});
	}

	handleDelete = (deleteId) => {
		this.setState({
			deleteId
		});
	}

	handleDeleteConfirmed = () => {
		const {deleteItem, loading} = this.props;
		const {deleteId} = this.state;

		if (loading) {
			return false;
		}

		this.setState({
			deleteId: null,
			success: false
		});

		deleteItem(deleteId);

		return true;
	}

	handleDeleteClose = () => {
		this.setState({
			deleteId: null
		});
	}

	render() {
		const {
			list,
			history,
			loading,
			error
		} = this.props;

		const {
			success,
			deleteId,
			order,
			asc,
			embed
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col>
						<h2 style={{marginBottom: '2rem'}}>Utworzone obiekty</h2>
						<Status
							success={success}
							error={error}
							loading={loading}
						/>
						<Table
							sortable={this.sortable}
							cols={[
								{
									sortable: true,
									name: 'id',
									label: '#'
								},
								{
									sortable: true,
									name: 'name',
									label: 'Nazwa'
								},
								{
									sortable: true,
									name: 'internal_name',
									label: 'Odnośnik',
									render: (item) => (
										<a
											href={`${process.env.REACT_APP_API_URL}/view/item/${item.internal_name}`}
											className="link-secondary"
											target="_blank"
											rel="noreferrer"
										>
											{item.internal_name}
										</a>
									)
								},
								{
									sortable: true,
									name: 'item_type_name',
									label: 'Typ'
								},
								{
									sortable: true,
									name: 'updated',
									label: 'Zmieniono',
									render: (item) => (item.updated === item.created ? formatTimestamp(item.updated) : <em>{formatTimestamp(item.updated)}</em>)
								}
							]}
							order={order}
							asc={asc}
							operations={[
								{
									style: 'outline-primary',
									link: '/item/add',
									label: 'Dodaj nowy obiekt'
								}
							]}
							list={list ? list.list : null}
							actions={[
								{
									style: 'outline-secondary',
									action: (item) => this.handleEmbed(item.internal_name),
									label: 'Osadź'
								},
								{
									style: 'outline-success',
									action: (item) => history.push(`/item/${item.id}`),
									label: 'Edytuj'
								},
								{
									style: 'outline-danger',
									action: (item) => this.handleDelete(item.id),
									label: 'Usuń'
								}
							]}
						/>
					</Col>
				</Row>
				{
					deleteId && (
						<Modal
							title="Potwierdź akcję"
							confirmAction={this.handleDeleteConfirmed}
							cancelAction={this.handleDeleteClose}
						>
							<p>
								Naprawdę chcesz usunąć list #{deleteId}? To spowoduje, że zniknie on ze wszystkich miejsc, na których zostało umieszczony. Stanie się tak jakby nigdy nie istniał.
							</p>
							<Alert variant="warning">
								<strong>Zatrzymaj się na chwilę!</strong> Ta operacja jest nieodwracalna z poziomu panelu administratora. Może ją przywrócić tylko administrator systemu.
							</Alert>
						</Modal>
					)
				}
				{
					embed && (
						<Modal
							title="Kod osadzenia obiektu"
							cancelText="Ok, to wszystko"
							cancelAction={this.handleEmbedClose}
						>
							<Alert variant="info">
								Skopiuj poniższy kod i wklej go na dowolnie wybranej przez siebie stronie w dowolnym miejscu.
							</Alert>
							<Alert variant="warning">
								Kod musi być skopiowany w całości, aby działał poprawnie!
							</Alert>
							<hr />
							<Code code={generateIframe('item', embed)} />
						</Modal>
					)
				}
			</LayoutPrivate>
		);
	}
}

ListItem.propTypes = {
	history: PropTypes.object.isRequired,
	list: PropTypes.object,
	listItems: PropTypes.func.isRequired,
	deleted: PropTypes.bool.isRequired,
	deleteItem: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

ListItem.defaultProps = {
	list: null
};

export default ListItem;
