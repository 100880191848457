import {connect} from 'react-redux';

import {deleteRankValue, listRankEntryValues} from '../../../../actions/rankValue';
import List from './List';

const mapStateToProps = (state, ownProps) => ({
	list: state.rankValue.listEntry[ownProps.match.params.rankEntryId],
	deleted: state.rankValue.deleted,
	loading: state.rankValue.status.loading,
	error: state.rankValue.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRankValues: (rankEntryId, order, asc) => dispatch(listRankEntryValues(rankEntryId, order, asc)),
	deleteRankValue: (id) => dispatch(deleteRankValue(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
