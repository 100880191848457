import axios from 'axios';

import {Letter} from '../util/actionTypes';

export const listLetters = (order, asc) => (dispatch) => {
	dispatch({
		type: Letter.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/letter/?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Letter.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: Letter.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Letter.LIST.FAILURE
			});
		});
};

export const getLetter = (id) => (dispatch) => {
	dispatch({
		type: Letter.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/letter/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Letter.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: Letter.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Letter.GET.FAILURE
			});
		});
};

export const addLetter = (data) => (dispatch) => {
	dispatch({
		type: Letter.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/letter/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Letter.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: Letter.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Letter.ADD.FAILURE
			});
		});
};

export const editLetter = (id, data) => (dispatch) => {
	dispatch({
		type: Letter.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/letter/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Letter.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: Letter.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Letter.EDIT.FAILURE
			});
		});
};

export const deleteLetter = (id) => (dispatch) => {
	dispatch({
		type: Letter.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/letter/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Letter.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: Letter.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Letter.DELETE.FAILURE
			});
		});
};
