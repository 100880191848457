import {connect} from 'react-redux';

import {setCalculatorDefinition, getCalculatorDefinition} from '../../../../actions/calculatorDefinition';
import Set from './Set';

const mapStateToProps = (state) => ({
	list: state.calculatorDefinition.list,
	set: state.calculatorDefinition.set,
	loading: state.calculatorDefinition.status.loading,
	error: state.calculatorDefinition.status.error
});

const mapDispatchToProps = (dispatch) => ({
	getCalculatorDefinition: () => dispatch(getCalculatorDefinition()),
	setCalculatorDefinition: (data) => dispatch(setCalculatorDefinition(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Set);
