import axios from 'axios';

import {Auth} from '../util/actionTypes';
import {setToken} from '../util/common';

export const login = (email, password) => (dispatch) => {
	dispatch({
		type: Auth.LOGIN.REQUEST
	});

	const data = {
		email,
		password
	};

	axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Auth.LOGIN.SUCCESS
				});

				setToken(res.message);
			} else {
				dispatch({
					type: Auth.LOGIN.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Auth.LOGIN.FAILURE
			});
		});
};

export const resetPassword = (email) => (dispatch) => {
	dispatch({
		type: Auth.RESET_PASSWORD.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/auth/restore/${email}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Auth.RESET_PASSWORD.SUCCESS
				});
			} else {
				dispatch({
					type: Auth.RESET_PASSWORD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Auth.RESET_PASSWORD.FAILURE
			});
		});
};
