import React, {Component} from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';

import {Button, Row, Col} from 'react-bootstrap';
import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	Checkbox,
	FieldGroup,
	Form,
	Input
} from '../../../../components/Form';
import {PropertyType} from '../../../../util/types';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class EditCheckpoint extends Component {
	state = {
		initialized: false,
		success: false,
		invalid: false,
		name: '',
		source: '',
		hidden: false,
		properties: []
	}

	componentDidMount() {
		const {mapId, checkpointId} = this.props.match.params;
		const {getCheckpoint} = this.props;

		setTitle(['Mapy', `Mapa #${mapId}`, 'Pomiary', `Pomiar #${checkpointId}`, 'Edytuj']);

		getCheckpoint(checkpointId);

		return true;
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.item && this.props.item && this.props.item.id === parseInt(this.props.match.params.checkpointId, 10)) {
			this.setState({
				initialized: true,
				name: this.props.item.name,
				source: this.props.item.source,
				hidden: this.props.item.hidden === 1,
				properties: this.props.item.properties.map((property) => ({
					uuid: shortid.generate(),
					key: {
						id: property.key.id,
						name: property.key.name,
						property_type_name: property.key.property_type_name
					},
					value: property.value ? {
						id: property.value.id,
						ds: property.value.ds || '',
						kp: property.value.kp || '',
						lb: property.value.lb || '',
						ls: property.value.ls || '',
						ld: property.value.ld || '',
						mp: property.value.mp || '',
						mz: property.value.mz || '',
						op: property.value.op || '',
						pk: property.value.pk || '',
						pl: property.value.pl || '',
						pm: property.value.pm || '',
						sl: property.value.sl || '',
						sk: property.value.sk || '',
						wm: property.value.wm || '',
						wp: property.value.wp || '',
						zp: property.value.zp || ''
					} : {
						id: null,
						ds: '',
						kp: '',
						lb: '',
						ls: '',
						ld: '',
						mp: '',
						mz: '',
						op: '',
						pk: '',
						pl: '',
						pm: '',
						sl: '',
						sk: '',
						wm: '',
						wp: '',
						zp: ''
					}
				}))
			});
		}

		if (!prevProps.edited && this.props.edited) {
			this.props.listCheckpoints(this.props.match.params.mapId, 'captured', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/map/${this.props.match.params.mapId}/checkpoint`);
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {mapId, checkpointId} = this.props.match.params;
		const {editCheckpoint, loading} = this.props;

		const {
			name,
			source,
			hidden,
			properties
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		editCheckpoint(checkpointId, {
			name,
			source,
			hidden,
			map_id: mapId,
			properties: properties.filter((property) => (
				property.value.ds
				|| property.value.kp
				|| property.value.lb
				|| property.value.ls
				|| property.value.ld
				|| property.value.mp
				|| property.value.mz
				|| property.value.op
				|| property.value.pk
				|| property.value.pl
				|| property.value.pm
				|| property.value.sl
				|| property.value.sk
				|| property.value.wm
				|| property.value.wp
				|| property.value.zp
			))
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	printType = (type) => {
		switch (type) {
			case PropertyType.INFORMATIVE:
				return 'informacyjna';
			case PropertyType.STATISTIC:
				return 'statystyczna';
			case PropertyType.PRECISE:
				return 'precyzująca';
			default:
				return '';
		}
	}

	render() {
		const {mapId, checkpointId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			initialized,
			success,
			invalid,
			name,
			source,
			properties,
			hidden
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Edycja pomiaru #{checkpointId} dla mapy #{mapId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							initialized && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<Input
										name="name"
										value={name}
										label="Nazwa pomiaru"
										placeholder="Wpisz nazwę pomiaru"
										handleChange={this.handleChange}
										required
									/>

									<Input
										name="source"
										value={source}
										label="Źródło danych dla pomiaru (jeżeli inne niż mapy)"
										placeholder="Wpisz źródło danych dla pomiaru"
										handleChange={this.handleChange}
									/>

									<Checkbox
										name="hidden"
										value={hidden}
										handleChange={this.handleChange}
										label="Ukryty pomiar"
										fieldLabel="Ukryj ten pomiar, ale uwzględniaj jego wyniki w podsumowaniu"
									/>

									<FieldGroup label="Własności mapy dla pomiaru">
										{
											properties.map((property, index) => (
												<Row
													key={property.uuid}
													style={{
														border: '1px solid #d0d0d0',
														borderRadius: '5px',
														margin: '15px',
														padding: '1rem 0'
													}}
												>
													<Col lg={{span: 12}}>
														Własność {this.printType(property.key.property_type_name)}: <strong>{property.key.name}</strong>
														<hr />
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.ds`}
															type="number"
															value={property.value.ds}
															placeholder="dolnośląskie"
															handleChange={this.handleChange}
														/>
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.kp`}
															type="number"
															value={property.value.kp}
															placeholder="kujawsko-pomorskie"
															handleChange={this.handleChange}
														/>
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.lb`}
															type="number"
															value={property.value.lb}
															placeholder="lubelskie"
															handleChange={this.handleChange}
														/>
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.ls`}
															type="number"
															value={property.value.ls}
															placeholder="lubuskie"
															handleChange={this.handleChange}
														/>
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.ld`}
															type="number"
															value={property.value.ld}
															placeholder="łódzkie"
															handleChange={this.handleChange}
														/>
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.mp`}
															type="number"
															value={property.value.mp}
															placeholder="małopolskie"
															handleChange={this.handleChange}
														/>
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.mz`}
															type="number"
															value={property.value.mz}
															placeholder="mazowieckie"
															handleChange={this.handleChange}
														/>
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.op`}
															type="number"
															value={property.value.op}
															placeholder="opolskie"
															handleChange={this.handleChange}
														/>
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.pk`}
															type="number"
															value={property.value.pk}
															placeholder="podkarpackie"
															handleChange={this.handleChange}
														/>
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.pl`}
															type="number"
															value={property.value.pl}
															placeholder="podlaskie"
															handleChange={this.handleChange}
														/>
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.pm`}
															type="number"
															value={property.value.pm}
															placeholder="pomorskie"
															handleChange={this.handleChange}
														/>
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.sl`}
															type="number"
															value={property.value.sl}
															placeholder="śląskie"
															handleChange={this.handleChange}
														/>
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.sk`}
															type="number"
															value={property.value.sk}
															placeholder="świętokrzyskie"
															handleChange={this.handleChange}
														/>
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.wm`}
															type="number"
															value={property.value.wm}
															placeholder="warmińsko-mazurskie"
															handleChange={this.handleChange}
														/>
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.wp`}
															type="number"
															value={property.value.wp}
															placeholder="wielkopolskie"
															handleChange={this.handleChange}
														/>
													</Col>
													<Col lg={{span: 3}}>
														<Input
															name={`properties[${index}].value.zp`}
															type="number"
															value={property.value.zp}
															placeholder="zachodniopomorskie"
															handleChange={this.handleChange}
														/>
													</Col>
												</Row>
											))
										}
									</FieldGroup>

									<Button variant="outline-primary" type="submit">
										Zapisz zmiany
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

EditCheckpoint.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	item: PropTypes.object,
	getCheckpoint: PropTypes.func.isRequired,
	edited: PropTypes.bool.isRequired,
	editCheckpoint: PropTypes.func.isRequired,
	listCheckpoints: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

EditCheckpoint.defaultProps = {
	item: null
};

export default EditCheckpoint;
