import {connect} from 'react-redux';

import {editGraphGroup, getGraphGroup, listGraphGroups} from '../../../../actions/graphGroup';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.graphGroup.item,
	edited: state.graphGroup.edited,
	loading: state.graphGroup.status.loading,
	error: state.graphGroup.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listGraphGroups: (graphId, order, asc) => dispatch(listGraphGroups(graphId, order, asc)),
	getGraphGroup: (id) => dispatch(getGraphGroup(id)),
	editGraphGroup: (id, data) => dispatch(editGraphGroup(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
