import React, {Component} from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	Checkbox,
	FieldGroup,
	Form,
	Input
} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';
import {GraphType} from '../../../../util/types';

class EditGraphEntry extends Component {
	state = {
		initialized: false,
		success: false,
		invalid: false,
		name: '',
		color: '',
		withGoal: false,
		goal: '',
		goalProgressInfo: '',
		goalValueInfo: '',
		goalValueSubinfo: '',
		values: null,
		graphType: null
	}

	componentDidMount() {
		const {graphId, graphGroupId, graphEntryId} = this.props.match.params;
		const {getGraphEntry, getGraph} = this.props;

		setTitle(['Wykresy', `Wykres #${graphId}`, 'Grupy', `Grupa #${graphGroupId}`, 'Pozycje', `Pozycja #${graphEntryId}`, 'Edytuj']);

		getGraphEntry(graphEntryId);
		getGraph(graphId);
	}

	componentDidUpdate(prevProps) {
		if (this.props.graph && this.props.item && (
			(!prevProps.graph && this.props.graph && this.props.graph.id === parseInt(this.props.match.params.graphId, 10))
			|| (!prevProps.item && this.props.item && this.props.item.id === parseInt(this.props.match.params.graphEntryId, 10))
		)) {
			this.setState({
				initialized: true,
				graphType: this.props.graph.graph_type_name,
				name: this.props.item.name,
				color: this.props.item.color,
				withGoal: this.props.item.with_goal === 1,
				goal: this.props.item.goal,
				goalProgressInfo: this.props.item.goal_progress_info,
				goalValueInfo: this.props.item.goal_value_info,
				goalValueSubinfo: this.props.item.goal_value_subinfo,
				values: [GraphType.BAR, GraphType.LINE].includes(this.props.graph.graph_type_name) ? (this.props.item.values.map((value) => ({
					uuid: shortid.generate(),
					key: {
						id: value.key.id,
						name: value.key.name,
						short_name: value.key.short_name,
						value_type_name: value.key.value_type_name
					},
					group: {
						id: value.group.id,
						name: value.group.name,
						color: value.group.color
					},
					value: value.value ? {
						id: value.value.id,
						value: value.value.value
					} : {
						id: null,
						value: ''
					}
				}))) : (
					this.props.item.values.map((value) => ({
						uuid: shortid.generate(),
						key: {
							id: value.key.id,
							name: value.key.name,
							short_name: value.key.short_name,
							value_type_name: value.key.value_type_name
						},
						value: value.value ? {
							id: value.value.id,
							value: value.value.value
						} : {
							id: null,
							value: ''
						}
					}))
				)
			});
		}

		if (!prevProps.edited && this.props.edited) {
			this.props.listGraphEntries(this.props.match.params.graphGroupId, 'id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/graph/${this.props.match.params.graphId}/group/${this.props.match.params.graphGroupId}/entry`);
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {graphEntryId} = this.props.match.params;
		const {editGraphEntry, loading} = this.props;
		const {
			name,
			color,
			withGoal,
			goal,
			goalProgressInfo,
			goalValueInfo,
			goalValueSubinfo,
			values
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		editGraphEntry(graphEntryId, {
			name,
			color,
			with_goal: withGoal,
			goal,
			goal_progress_info: goalProgressInfo,
			goal_value_info: goalValueInfo,
			goal_value_subinfo: goalValueSubinfo,
			values
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {graphEntryId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			initialized,
			success,
			invalid,
			name,
			color,
			withGoal,
			goal,
			goalProgressInfo,
			goalValueInfo,
			goalValueSubinfo,
			values,
			graphType
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Edycja pozycji wykresie #{graphEntryId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							initialized && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<Input
										name="name"
										value={name}
										label="Nazwa"
										placeholder="Wpisz nazwę pozycji"
										handleChange={this.handleChange}
										required
									/>

									{
										graphType === GraphType.MULTILINE && (
											<>
												<Input
													name="color"
													value={color}
													label="Kolor pozycji"
													placeholder="Wpisz kolor pozycji"
													handleChange={this.handleChange}
													required
												/>
												<p>
													<small>Dopuszczalne są kolory w języku angielskim oraz <em>rgb</em>, <em>rgba</em>, <em>hex</em>.</small>
												</p>
											</>
										)
									}

									{
										graphType === GraphType.BAR && (
											<>
												<Checkbox
													name="withGoal"
													value={withGoal}
													handleChange={this.handleChange}
													label="Cel pozycji"
													fieldLabel="Ustaw cel, do którego dążą wartości w tej pozycji"
												/>

												{
													withGoal && (
														<>
															<Input
																name="goal"
																value={goal}
																label="Wartość celu"
																type="number"
																placeholder="Wpisz wartość celu"
																handleChange={this.handleChange}
																required
																min="1"
															/>
															<Input
																name="goalProgressInfo"
																value={goalProgressInfo}
																label="Podpis dla postępu celu"
																type="text"
																placeholder="Wpisz podpis dla postępu celu"
																handleChange={this.handleChange}
																required
															/>
															<Input
																name="goalValueInfo"
																value={goalValueInfo}
																label="Podpis dla wartości celu"
																type="text"
																placeholder="Wpisz podpis dla wartości celu"
																handleChange={this.handleChange}
																required
															/>
															<Input
																name="goalValueSubinfo"
																value={goalValueSubinfo}
																label="Dodatkowy opis dla wartości celu"
																type="text"
																placeholder="Wpisz dodatkowy opis dla wartości celu"
																handleChange={this.handleChange}
																required
															/>
														</>
													)
												}
											</>
										)
									}

									<FieldGroup label="Własności wykresu dla pozycji">
										{
											values.map((value, index) => (
												<Row
													key={value.uuid}
													style={{
														border: '1px solid #d0d0d0',
														borderRadius: '5px',
														margin: '15px',
														padding: '1rem 0'
													}}
												>
													<Col lg={{span: 12}}>
														Własność{value.group && <> z grupy <em>{value.group.name}</em></>}: <strong>{value.key.name}</strong> (<em>{value.key.short_name}</em>)
														<hr />
													</Col>
													<Col lg={{span: 12}}>
														<Input
															name={`values[${index}].value.value`}
															type="number"
															min={0}
															value={value.value.value}
															placeholder={value.key.name}
															handleChange={this.handleChange}
															step="any"
														/>
													</Col>
												</Row>
											))
										}
									</FieldGroup>

									<Button variant="outline-primary" type="submit">
										Zapisz zmiany
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

EditGraphEntry.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	graph: PropTypes.object,
	getGraph: PropTypes.func.isRequired,
	listGraphEntries: PropTypes.func.isRequired,
	item: PropTypes.object,
	getGraphEntry: PropTypes.func.isRequired,
	edited: PropTypes.bool.isRequired,
	editGraphEntry: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

EditGraphEntry.defaultProps = {
	item: null,
	graph: null
};

export default EditGraphEntry;
