import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import {configureStore} from '@reduxjs/toolkit';
import {Provider} from 'react-redux';

import reducers from './reducers';
import App from './App';

if (window.localStorage.getItem('token')) {
	axios.defaults.headers.common.Authorization = `Bearer ${window.localStorage.getItem('token')}`;
}

axios.interceptors.response.use((response) => {
	if (response.data.success === false && response.data.message === 'UNAUTHORISED' && window.location.pathname !== '/') {
		window.localStorage.removeItem('token');
		window.location.replace('/');
	}

	return response.data;
}, () => {
	window.localStorage.removeItem('token');
	window.location.replace('/');
});

const store = configureStore({
	reducer: reducers
});

ReactDOM.render((
	<Provider store={store}>
		<App />
	</Provider>
), document.getElementById('root'));
