import {connect} from 'react-redux';

import {deleteGraphGroup, listGraphGroups} from '../../../../actions/graphGroup';
import List from './List';

const mapStateToProps = (state, ownProps) => ({
	list: state.graphGroup.list[ownProps.match.params.graphId],
	deleted: state.graphGroup.deleted,
	loading: state.graphGroup.status.loading,
	error: state.graphGroup.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listGraphGroups: (graphId, order, asc) => dispatch(listGraphGroups(graphId, order, asc)),
	deleteGraphGroup: (id) => dispatch(deleteGraphGroup(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
