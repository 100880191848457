import {connect} from 'react-redux';

import {editRank, getRank, listRanks} from '../../../../actions/rank';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.rank.item,
	edited: state.rank.edited,
	loading: state.rank.status.loading,
	error: state.rank.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRanks: (order, asc) => dispatch(listRanks(order, asc)),
	getRank: (id) => dispatch(getRank(id)),
	editRank: (id, data) => dispatch(editRank(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
