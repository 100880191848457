import React, {Component} from 'react';
import {Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {Editor} from '@tinymce/tinymce-react'; // eslint-disable-line import/no-extraneous-dependencies

class Html extends Component {
	handleChange = (value) => {
		const {handleChange, name} = this.props;

		handleChange(name, value);
	}

	render() {
		const {
			name,
			value,
			label
		} = this.props;

		return (
			<Form.Group className="mb-3" controlId={name}>
				{
					label && (
						<Form.Label>{label}</Form.Label>
					)
				}
				<Editor
					init={{
						language: 'pl',
						plugins: ['advlist autolink autosave lists link image charmap print preview hr anchor pagebreak',
							'searchreplace wordcount visualblocks visualchars code fullscreen',
							'insertdatetime media nonbreaking save table contextmenu directionality',
							'emoticons template paste textcolor colorpicker textpattern imagetools codesample toc help'],
						toolbar1: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
						toolbar2: 'print preview media | forecolor backcolor emoticons | codesample help',
						menubar: false,
						image_advtab: true,
						branding: false,
						height: 400
					}}
					apiKey="dzvcovd8edsd49trppvulpgvdhlujjbv48dzrb8t22j34qk2"
					name={name}
					value={value}
					onEditorChange={this.handleChange}
				/>
			</Form.Group>
		);
	}
}

Html.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	label: PropTypes.string,
	handleChange: PropTypes.func.isRequired
};

Html.defaultProps = {
	label: null
};

export default Html;
