import axios from 'axios';

import {Item} from '../util/actionTypes';

export const listItems = (order, asc) => (dispatch) => {
	dispatch({
		type: Item.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/item/?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Item.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: Item.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Item.LIST.FAILURE
			});
		});
};

export const getItem = (id) => (dispatch) => {
	dispatch({
		type: Item.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/item/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Item.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: Item.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Item.GET.FAILURE
			});
		});
};

export const addItem = (data) => (dispatch) => {
	dispatch({
		type: Item.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/item/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Item.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: Item.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Item.ADD.FAILURE
			});
		});
};

export const editItem = (id, data) => (dispatch) => {
	dispatch({
		type: Item.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/item/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Item.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: Item.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Item.EDIT.FAILURE
			});
		});
};

export const deleteItem = (id) => (dispatch) => {
	dispatch({
		type: Item.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/item/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Item.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: Item.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Item.DELETE.FAILURE
			});
		});
};
