import React, {Fragment} from 'react';
import {Container} from 'react-bootstrap';
import PropTypes from 'prop-types';

import NavPublic from '../Nav/Public';
import {Footer} from '../Other';

const LayoutPublic = (props) => {
	const {children} = props;

	return (
		<>
			<NavPublic />
			<Container style={{marginTop: '3rem', marginBottom: '3rem'}}>
				{children}
			</Container>
			<Footer />
		</>
	);
};

LayoutPublic.propTypes = {
	children: PropTypes.node.isRequired
};

export default LayoutPublic;
