import {connect} from 'react-redux';

import {deleteBorough, listBoroughs} from '../../../../actions/borough';
import List from './List';

const mapStateToProps = (state, ownProps) => ({
	list: state.borough.list[ownProps.match.params.districtId],
	deleted: state.borough.deleted,
	loading: state.borough.status.loading,
	error: state.borough.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listBoroughs: (districtId, order, asc) => dispatch(listBoroughs(districtId, order, asc)),
	deleteBorough: (id) => dispatch(deleteBorough(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
