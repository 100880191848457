import {connect} from 'react-redux';

import {editVoivodeship, getVoivodeship, listVoivodeships} from '../../../../actions/voivodeship';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.voivodeship.item,
	edited: state.voivodeship.edited,
	loading: state.voivodeship.status.loading,
	error: state.voivodeship.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listVoivodeships: (order, asc) => dispatch(listVoivodeships(order, asc)),
	getVoivodeship: (id) => dispatch(getVoivodeship(id)),
	editVoivodeship: (id, data) => dispatch(editVoivodeship(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
