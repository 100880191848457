import axios from 'axios';

import {Checkpoint} from '../util/actionTypes';

export const listCheckpoints = (mapId, order, asc) => (dispatch) => {
	dispatch({
		type: Checkpoint.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/checkpoint/map/${mapId}?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					id: mapId,
					type: Checkpoint.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: Checkpoint.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Checkpoint.LIST.FAILURE
			});
		});
};

export const getCheckpoint = (id) => (dispatch) => {
	dispatch({
		type: Checkpoint.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/checkpoint/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Checkpoint.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: Checkpoint.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Checkpoint.GET.FAILURE
			});
		});
};

export const addCheckpoint = (data) => (dispatch) => {
	dispatch({
		type: Checkpoint.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/checkpoint/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Checkpoint.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: Checkpoint.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Checkpoint.ADD.FAILURE
			});
		});
};

export const importCheckpoint = (data) => (dispatch) => {
	dispatch({
		type: Checkpoint.IMPORT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/checkpoint/import`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Checkpoint.IMPORT.SUCCESS
				});
			} else {
				dispatch({
					type: Checkpoint.IMPORT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Checkpoint.IMPORT.FAILURE
			});
		});
};

export const editCheckpoint = (id, data) => (dispatch) => {
	dispatch({
		type: Checkpoint.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/checkpoint/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Checkpoint.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: Checkpoint.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Checkpoint.EDIT.FAILURE
			});
		});
};

export const deleteCheckpoint = (id) => (dispatch) => {
	dispatch({
		type: Checkpoint.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/checkpoint/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Checkpoint.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: Checkpoint.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Checkpoint.DELETE.FAILURE
			});
		});
};
