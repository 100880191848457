import {connect} from 'react-redux';

import {editCheckpoint, getCheckpoint, listCheckpoints} from '../../../../actions/checkpoint';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.checkpoint.item,
	edited: state.checkpoint.edited,
	loading: state.checkpoint.status.loading,
	error: state.checkpoint.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listCheckpoints: (mapId, order, asc) => dispatch(listCheckpoints(mapId, order, asc)),
	getCheckpoint: (id) => dispatch(getCheckpoint(id)),
	editCheckpoint: (id, data) => dispatch(editCheckpoint(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
