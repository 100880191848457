import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {Form, File, Input} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class ImportRecipient extends Component {
	state = {
		success: false,
		invalid: false,
		file: null,
		name: '',
		email: ''
	}

	componentDidMount() {
		const {recipientGroupId} = this.props.match.params;

		setTitle(['Listy', 'Grupy odbiorców', `Grupa #${recipientGroupId}`, 'Odbiorcy', 'Zaimportuj nowych odbiorców']);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.imported && this.props.imported) {
			this.props.listRecipients(this.props.match.params.recipientGroupId, 'id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/letter/recipient_group/${this.props.match.params.recipientGroupId}/recipient`);
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {recipientGroupId} = this.props.match.params;

		const {
			importRecipient,
			loading
		} = this.props;

		const {
			file,
			name,
			email
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		importRecipient({
			recipient_group_id: recipientGroupId,
			file,
			name,
			email
		});

		return true;
	};

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {recipientGroupId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			file,
			name,
			email
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Wczytaj nowych odbiorców dla grupy #{recipientGroupId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
							<File
								name="file"
								value={file}
								label="Plik XLS z kontaktami"
								handleChange={this.handleChange}
								required
							/>

							<Row>
								<Col>
									<Input
										name="name"
										value={name}
										label="Kolumna zawierająca nazwy"
										placeholder="Wpisz kolumnę zawierającą nazwy"
										handleChange={this.handleChange}
										required
									/>
								</Col>
								<Col>
									<Input
										name="email"
										value={email}
										label="Kolumna zawierająca adresy e-mail"
										placeholder="Wpisz kolumnę zawierającą adresy e-mail"
										handleChange={this.handleChange}
										required
									/>
								</Col>
							</Row>

							<Button variant="outline-primary" type="submit">
								Zaimportuj nowe kontakty
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

ImportRecipient.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	listRecipients: PropTypes.func.isRequired,
	imported: PropTypes.bool.isRequired,
	importRecipient: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

export default ImportRecipient;
