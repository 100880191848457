import {connect} from 'react-redux';

import {editTimer, getTimer, listTimers} from '../../../../actions/timer';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.timer.item,
	edited: state.timer.edited,
	loading: state.timer.status.loading,
	error: state.timer.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listTimers: (order, asc) => dispatch(listTimers(order, asc)),
	getTimer: (id) => dispatch(getTimer(id)),
	editTimer: (id, data) => dispatch(editTimer(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
