import {connect} from 'react-redux';

import {addCalculator, listCalculators} from '../../../../actions/calculator';
import Add from './Add';

const mapStateToProps = (state) => ({
	added: state.calculator.added,
	loading: state.calculator.status.loading,
	error: state.calculator.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listCalculators: (order, asc) => dispatch(listCalculators(order, asc)),
	addCalculator: (data) => dispatch(addCalculator(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
