import {connect} from 'react-redux';

import {importRank} from '../../../../actions/rank';
import Import from './Import';
import {listRankGroups} from '../../../../actions/rankGroup';

const mapStateToProps = (state) => ({
	imported: state.rank.imported,
	loading: state.rank.status.loading || state.rankGroup.status.loading,
	error: state.rank.status.error || state.rankGroup.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRankGroups: (rankGroupId, order, asc) => dispatch(listRankGroups(rankGroupId, order, asc)),
	importRank: (data) => dispatch(importRank(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Import);
