import {connect} from 'react-redux';

import {addDistrict, listDistricts} from '../../../../actions/district';
import Add from './Add';

const mapStateToProps = (state) => ({
	added: state.district.added,
	loading: state.district.status.loading,
	error: state.district.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listDistricts: (voivodeshipId, order, asc) => dispatch(listDistricts(voivodeshipId, order, asc)),
	addDistrict: (data) => dispatch(addDistrict(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
