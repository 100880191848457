import {connect} from 'react-redux';

import {addMap, listMaps} from '../../../../actions/map';
import Add from './Add';

const mapStateToProps = (state) => ({
	added: state.map.added,
	loading: state.map.status.loading,
	error: state.map.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listMaps: (order, asc) => dispatch(listMaps(order, asc)),
	addMap: (data) => dispatch(addMap(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
