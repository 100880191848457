import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container} from 'react-bootstrap';

import NavLoading from '../../Nav/Loading';
import NavPrivate from '../../Nav/Private';
import {Footer} from '../../Other';
import {extendToken} from '../../../util/common';

class LayoutPrivate extends Component {
	state = {
		initialized: false
	};

	constructor(props) {
		super(props);

		this.timeout = null;
	}

	componentDidMount() {
		const {me, getUserMe} = this.props;

		if (!me) {
			getUserMe();
		} else {
			this.setState({
				initialized: true
			});

			this.extendToken();
		}
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.me && this.props.me) {
			this.setState({
				initialized: true
			});

			this.extendToken();
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	extendToken = () => {
		this.timeout = setTimeout(() => {
			extendToken();
			this.extendToken();
		}, 60000); // 1m
	}

	logout = () => {
		window.localStorage.removeItem('token');
		window.location.replace('/');
	}

	render() {
		const {children, me} = this.props;
		const {initialized} = this.state;

		if (!initialized) {
			return (
				<>
					<NavLoading logout={this.logout} />
					<Container style={{marginTop: '3rem', marginBottom: '3rem'}}>
						<h2 style={{marginBottom: '2rem'}}>Chwileczkę...</h2>
						<p>Aby przyspieszyć działanie aplikacji potrzebujemy odrobiny czasu. Ładujemy wszystkie potrzebne składniki...</p>
					</Container>
				</>
			);
		}

		return (
			<>
				<NavPrivate me={me} logout={this.logout} />
				<Container style={{marginTop: '3rem', marginBottom: '3rem'}}>
					{children}
				</Container>
				<Footer />
			</>
		);
	}
}

LayoutPrivate.propTypes = {
	me: PropTypes.object,
	getUserMe: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired
};

LayoutPrivate.defaultProps = {
	me: null
};

export default LayoutPrivate;
