import React, {Component} from 'react';
import {Form} from 'react-bootstrap';
import PropTypes from 'prop-types';

class Select extends Component {
	handleChange = (e) => {
		const {handleChange, name} = this.props;

		handleChange(name, e.target.value);
	}

	render() {
		const {
			name,
			value,
			label,
			options,
			multiple,
			required
		} = this.props;

		return (
			<Form.Group className="mb-3" controlId={name}>
				{
					label && (
						<Form.Label>{label}</Form.Label>
					)
				}

				<Form.Control
					name={name}
					as="select"
					defaultValue={value}
					onChange={this.handleChange}
					required={required}
					multiple={multiple}
				>
					{
						options.map((o) => (
							<option value={o.value} key={o.value}>{o.label}</option>
						))
					}
				</Form.Control>
			</Form.Group>
		);
	}
}

Select.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	label: PropTypes.string,
	options: PropTypes.array.isRequired,
	handleChange: PropTypes.func.isRequired,
	multiple: PropTypes.bool,
	required: PropTypes.bool
};

Select.defaultProps = {
	label: null,
	multiple: false,
	required: false
};

export default Select;
