import {ItemType} from '../util/actionTypes';
import {Status} from '../util/default';

const initialState = {
	status: Status.SUCCESS,
	list: null,
	item: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ItemType.LIST.REQUEST:
			return {
				...state,
				status: Status.REQUEST
			};
		case ItemType.LIST.SUCCESS:
			return {
				...state,
				list: action.data,
				status: Status.SUCCESS
			};
		case ItemType.LIST.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case ItemType.GET.REQUEST:
			return {
				...state,
				item: null,
				status: Status.REQUEST
			};
		case ItemType.GET.SUCCESS:
			return {
				...state,
				item: action.data,
				status: Status.SUCCESS
			};
		case ItemType.GET.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		default:
			return state;
	}
};
