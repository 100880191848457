export const Status = {
	REQUEST: {
		loading: true,
		error: false
	},
	SUCCESS: {
		loading: false,
		error: false
	},
	FAILURE: {
		loading: false,
		error: true
	}
};
