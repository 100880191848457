import axios from 'axios';

import {District} from '../util/actionTypes';

export const listDistricts = (voivodeshipId, order, asc) => (dispatch) => {
	dispatch({
		type: District.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/district/voivodeship/${voivodeshipId}?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					id: voivodeshipId,
					type: District.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: District.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: District.LIST.FAILURE
			});
		});
};

export const getDistrict = (id) => (dispatch) => {
	dispatch({
		type: District.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/district/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: District.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: District.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: District.GET.FAILURE
			});
		});
};

export const addDistrict = (data) => (dispatch) => {
	dispatch({
		type: District.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/district/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: District.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: District.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: District.ADD.FAILURE
			});
		});
};

export const editDistrict = (id, data) => (dispatch) => {
	dispatch({
		type: District.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/district/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: District.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: District.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: District.EDIT.FAILURE
			});
		});
};

export const deleteDistrict = (id) => (dispatch) => {
	dispatch({
		type: District.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/district/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: District.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: District.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: District.DELETE.FAILURE
			});
		});
};
