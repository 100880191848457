import axios from 'axios';

import {Search} from '../util/actionTypes';

export const listSearches = (order, asc) => (dispatch) => {
	dispatch({
		type: Search.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/search/?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Search.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: Search.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Search.LIST.FAILURE
			});
		});
};

export const getSearch = (id) => (dispatch) => {
	dispatch({
		type: Search.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/search/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Search.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: Search.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Search.GET.FAILURE
			});
		});
};

export const addSearch = (data) => (dispatch) => {
	dispatch({
		type: Search.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/search/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Search.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: Search.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Search.ADD.FAILURE
			});
		});
};

export const editSearch = (id, data) => (dispatch) => {
	dispatch({
		type: Search.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/search/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Search.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: Search.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Search.EDIT.FAILURE
			});
		});
};

export const deleteSearch = (id) => (dispatch) => {
	dispatch({
		type: Search.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/search/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Search.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: Search.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Search.DELETE.FAILURE
			});
		});
};
