import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';
import shortid from 'shortid';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	FieldGroup,
	Form,
	Input,
	Select
} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';
import {ItemTypeHookType} from '../../../../util/types';

class AddItem extends Component {
	state = {
		success: false,
		invalid: false,
		name: '',
		internalName: '',
		itemTypeId: '',
		hooks: []
	}

	componentDidMount() {
		setTitle(['Obiekty', 'Dodaj nowy obiekt']);

		this.loadItemTypes();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.itemTypes !== this.props.itemTypes && this.props.itemTypes) {
			this.setItemTypes();
		}

		if (!prevProps.added && this.props.added) {
			this.props.listItems('id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push('/item');
				}, SuccessMessageTimeout);
			});
		}
	}

	loadItemTypes = () => {
		const {itemTypes, listItemTypes} = this.props;

		if (itemTypes && itemTypes.order === 'name' && itemTypes.asc) {
			this.setItemTypes();
		} else {
			listItemTypes('name', true);
		}
	}

	setItemTypes = () => {
		const {itemTypes} = this.props;

		this.setState({
			itemTypeOptions: [
				{
					label: 'Wybierz rodzaj obiektu',
					value: ''
				},
				...itemTypes.list.map((r) => ({
					label: r.display_name,
					value: r.id
				}))
			]
		});
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			addItem,
			loading
		} = this.props;

		const {
			name,
			internalName,
			itemTypeId,
			hooks
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		addItem({
			name,
			internal_name: internalName,
			item_type_id: itemTypeId,
			hooks
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	handleItemTypeChange = (name, value) => {
		const {itemTypes} = this.props;

		const pickedItemType = itemTypes.list.find((itemType) => itemType.id === parseInt(value, 10));

		if (pickedItemType) {
			this.setState({
				hooks: pickedItemType.hooks.map((hook) => ({
					uuid: shortid.generate(),
					item_type_hook_id: hook.id,
					item_type_hook_name: hook.name,
					item_type_hook_display_name: hook.display_name,
					item_type_hook_type_name: hook.item_type_hook_type_name,
					value: ''
				}))
			});
		} else {
			this.setState({
				hooks: []
			});
		}

		this.handleChange(name, value);
	}

	render() {
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			name,
			internalName,
			itemTypeId,
			itemTypeOptions,
			hooks
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Nowy obiekt</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							itemTypeOptions && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<Input
										name="name"
										value={name}
										label="Nazwa obiektu"
										placeholder="Wpisz nazwę obiektu"
										handleChange={this.handleChange}
										required
									/>

									<Input
										name="internalName"
										value={internalName}
										label="Wewnętrzna nazwa obiektu (w odnośniku)"
										placeholder="Wpisz wewnętrzną nazwę obiektu"
										handleChange={this.handleChange}
										required
									/>

									<Select
										name="itemTypeId"
										value={itemTypeId}
										label="Rodzaj obiektu"
										options={itemTypeOptions}
										handleChange={this.handleItemTypeChange}
										required
									/>

									{
										itemTypeId && (
											<FieldGroup label="Własności obiektu">
												{
													hooks.map((hook, index) => (
														<Row
															key={hook.uuid}
															style={{
																border: '1px solid #d0d0d0',
																borderRadius: '5px',
																margin: '15px',
																padding: '1rem 0'
															}}
														>
															<Col lg={{span: 12}}>
																<p>Właściwość: <strong>{hook.item_type_hook_display_name}</strong> (<em>{hook.item_type_hook_name}</em>)</p>
																{
																	hook.item_type_hook_type_name === ItemTypeHookType.TEXT && (
																		<Input
																			name={`hooks[${index}].value`}
																			value={hook.value}
																			placeholder={`Wartość dla ${hook.item_type_hook_display_name}`}
																			handleChange={this.handleChange}
																			required
																		/>
																	)
																}
																{
																	hook.item_type_hook_type_name === ItemTypeHookType.INTEGRATION && (
																		<>
																			<Input
																				name={`hooks[${index}].value`}
																				value={hook.value}
																				placeholder={`Wartość dla ${hook.item_type_hook_display_name}`}
																				handleChange={this.handleChange}
																				required
																				disabled
																			/>
																			<p><small>To jest własność, która jest połączona z integracją i nie może być edytowana.</small></p>
																		</>
																	)
																}
															</Col>
														</Row>
													))
												}
											</FieldGroup>
										)
									}

									<Button variant="outline-primary" type="submit">
										Utwórz nowy obiekt
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

AddItem.propTypes = {
	history: PropTypes.object.isRequired,
	listItems: PropTypes.func.isRequired,
	itemTypes: PropTypes.object,
	listItemTypes: PropTypes.func.isRequired,
	added: PropTypes.bool.isRequired,
	addItem: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

AddItem.defaultProps = {
	itemTypes: null
};

export default AddItem;
