import {connect} from 'react-redux';

import {editRecipientGroup, getRecipientGroup, listRecipientGroups} from '../../../../actions/recipientGroup';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.recipientGroup.item,
	edited: state.recipientGroup.edited,
	loading: state.recipientGroup.status.loading,
	error: state.recipientGroup.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRecipientGroups: (order, asc) => dispatch(listRecipientGroups(order, asc)),
	getRecipientGroup: (id) => dispatch(getRecipientGroup(id)),
	editRecipientGroup: (id, data) => dispatch(editRecipientGroup(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
