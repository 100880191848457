import {connect} from 'react-redux';

import {addLetter, listLetters} from '../../../../actions/letter';
import {listRecipientGroups} from '../../../../actions/recipientGroup';
import Add from './Add';

const mapStateToProps = (state) => ({
	recipientGroups: state.recipientGroup.list,
	added: state.letter.added,
	loading: state.letter.status.loading || state.recipientGroup.status.loading,
	error: state.letter.status.error || state.recipientGroup.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listLetters: (order, asc) => dispatch(listLetters(order, asc)),
	listRecipientGroups: (order, asc) => dispatch(listRecipientGroups(order, asc)),
	addLetter: (data) => dispatch(addLetter(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
