import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Alert} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {Modal, Status, Table} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';
import {formatTimestamp, setTitle} from '../../../../util/common';

const operations = [
	{
		style: 'outline-primary',
		link: '/user/add',
		label: 'Dodaj nowe konto'
	}
];

class ListAccount extends Component {
	state = {
		success: false,
		deleteId: null,
		order: 'updated',
		asc: false
	}

	componentDidMount() {
		setTitle(['Konta użytkowników']);

		this.loadData(true);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.deleted && this.props.deleted) {
			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});
				}, SuccessMessageTimeout);
			});

			this.props.listUsers(this.state.order, this.state.asc);
		}
	}

	sortable = (type) => {
		this.setState((prevState) => ({
			order: type,
			asc: prevState.order === type ? !prevState.asc : prevState.asc
		}), () => {
			this.loadData();
		});
	}

	loadData = (init = false) => {
		const {list, listUsers} = this.props;
		const {order, asc} = this.state;

		if (list) {
			if (list.order !== order || list.asc !== asc) {
				if (init) {
					this.setState({
						order: list.order,
						asc: list.asc
					});
				} else {
					listUsers(order, asc);
				}
			}
		} else {
			listUsers(order, asc);
		}
	}

	handleDelete = (deleteId) => {
		this.setState({
			deleteId
		});
	}

	handleDeleteConfirmed = () => {
		const {deleteUser, loading} = this.props;
		const {deleteId} = this.state;

		if (loading) {
			return false;
		}

		this.setState({
			deleteId: null,
			success: false
		});

		deleteUser(deleteId);

		return true;
	}

	handleDeleteClose = () => {
		this.setState({
			deleteId: null
		});
	}

	render() {
		const {
			me,
			list,
			loading,
			error
		} = this.props;

		const {
			deleteId,
			success,
			order,
			asc
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col>
						<h2 style={{marginBottom: '2rem'}}>Konta użytkowników</h2>
						<Status
							success={success}
							error={error}
							loading={loading}
						/>
						{
							(me && me.super === 1) ? (
								<Table
									sortable={this.sortable}
									cols={[
										{
											sortable: true,
											name: 'id',
											label: '#'
										},
										{
											sortable: true,
											name: 'firstname',
											label: 'Właściciel',
											render: (item) => `${item.firstname} ${item.lastname}`
										},
										{
											sortable: true,
											name: 'email',
											label: 'E-mail'
										},
										{
											sortable: true,
											name: 'super',
											label: 'Typ konta',
											render: (item) => (item.super === 1 ? 'Administrator' : 'Użytkownik')
										},
										{
											sortable: true,
											name: 'updated',
											label: 'Zmieniono',
											render: (item) => (item.updated === item.created ? formatTimestamp(item.updated) : <em>{formatTimestamp(item.updated)}</em>)
										}
									]}
									order={order}
									asc={asc}
									operations={operations}
									list={list ? list.list : null}
									actions={[
										{
											style: 'outline-danger',
											action: (item) => this.handleDelete(item.id),
											label: 'Usuń'
										}
									]}
								/>
							) : (
								<Table
									sortable={this.sortable}
									cols={[
										{
											sortable: true,
											name: 'id',
											label: '#'
										},
										{
											sortable: true,
											name: 'firstname',
											label: 'Właściciel',
											render: (item) => `${item.firstname} ${item.lastname}`
										},
										{
											sortable: true,
											name: 'email',
											label: 'E-mail'
										},
										{
											sortable: true,
											name: 'updated',
											label: 'Zmieniono',
											render: (item) => (item.updated === item.created ? formatTimestamp(item.updated) : <em>{formatTimestamp(item.updated)}</em>)
										}
									]}
									order={order}
									asc={asc}
									operations={operations}
									list={list ? list.list : null}
								/>
							)
						}
						{
							deleteId && (
								deleteId === me.id ? (
									<Modal
										title="Ta akcja jest niemożliwa"
										cancelAction={this.handleDeleteClose}
										cancelText="Ok, rozumiem!"
									>
										<p>
											Nie możesz usunąć siebie samego :) To nie ma zupełnie sensu!
										</p>
									</Modal>
								) : (
									<Modal
										title="Potwierdź akcję"
										confirmAction={this.handleDeleteConfirmed}
										cancelAction={this.handleDeleteClose}
									>
										<p>
											Naprawdę chcesz usunąć konto #{deleteId}? Żadne akcje wykonane przez tego użytkownika nie zostaną skasowane, ale konto nie będzie mogło być ponownie utworzone.
										</p>
										<Alert variant="warning">
											<strong>Zatrzymaj się na chwilę!</strong> Ta operacja jest nieodwracalna z poziomu panelu administratora. Może ją przywrócić tylko administrator systemu.
										</Alert>
									</Modal>
								)
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

ListAccount.propTypes = {
	me: PropTypes.object,
	list: PropTypes.object,
	listUsers: PropTypes.func.isRequired,
	deleted: PropTypes.bool.isRequired,
	deleteUser: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

ListAccount.defaultProps = {
	me: null,
	list: null
};

export default ListAccount;
