import {connect} from 'react-redux';

import {deleteVoivodeship, listVoivodeships} from '../../../../actions/voivodeship';
import List from './List';

const mapStateToProps = (state) => ({
	list: state.voivodeship.list,
	deleted: state.voivodeship.deleted,
	loading: state.voivodeship.status.loading,
	error: state.voivodeship.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listVoivodeships: (order, asc) => dispatch(listVoivodeships(order, asc)),
	deleteVoivodeship: (id) => dispatch(deleteVoivodeship(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
