import React, {Component} from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {FieldGroup, Form, Textarea} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class EditSearchEntry extends Component {
	state = {
		initialized: false,
		success: false,
		invalid: false,
		values: null
	}

	componentDidMount() {
		const {searchId, searchEntryId} = this.props.match.params;
		const {getSearchEntry} = this.props;

		setTitle(['Wyszukiwarki', `Wyszukiwarka #${searchId}`, 'Pozycje', `Pozycja #${searchEntryId}`, 'Edytuj']);

		getSearchEntry(searchEntryId);

		return true;
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.item && this.props.item && this.props.item.id === parseInt(this.props.match.params.searchEntryId, 10)) {
			this.setState({
				initialized: true,
				values: this.props.item.values.map((value) => ({
					uuid: shortid.generate(),
					key: {
						id: value.key.id,
						name: value.key.name,
						value_type_name: value.key.value_type_name
					},
					value: value.value ? {
						id: value.value.id,
						value: value.value.value
					} : {
						id: null,
						value: ''
					}
				}))
			});
		}

		if (!prevProps.edited && this.props.edited) {
			this.props.listSearchEntries(this.props.match.params.searchId, 'id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/search/${this.props.match.params.searchId}/entry`);
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {searchEntryId} = this.props.match.params;
		const {editSearchEntry, loading} = this.props;
		const {values} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		editSearchEntry(searchEntryId, {values});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {searchEntryId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			initialized,
			success,
			invalid,
			values
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Edycja pozycji #{searchEntryId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							initialized && values && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<FieldGroup label="Własności wyszukiwarki dla pozycji">
										{
											values.map((value, index) => (
												<Row
													key={value.uuid}
													style={{
														border: '1px solid #d0d0d0',
														borderRadius: '5px',
														margin: '15px',
														padding: '1rem 0'
													}}
												>
													<Col lg={{span: 12}}>
														Własność: <strong>{value.key.name}</strong>
														<hr />
													</Col>
													<Col lg={{span: 12}}>
														<Textarea
															name={`values[${index}].value.value`}
															value={value.value.value}
															placeholder={value.key.name}
															handleChange={this.handleChange}
														/>
													</Col>
												</Row>
											))
										}
									</FieldGroup>

									<Button variant="outline-primary" type="submit">
										Zapisz zmiany
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

EditSearchEntry.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	listSearchEntries: PropTypes.func.isRequired,
	item: PropTypes.object,
	getSearchEntry: PropTypes.func.isRequired,
	edited: PropTypes.bool.isRequired,
	editSearchEntry: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

EditSearchEntry.defaultProps = {
	item: null
};

export default EditSearchEntry;
