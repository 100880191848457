import {connect} from 'react-redux';

import {editBorough, getBorough, listBoroughs} from '../../../../actions/borough';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.borough.item,
	edited: state.borough.edited,
	loading: state.borough.status.loading,
	error: state.borough.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listBoroughs: (districtId, order, asc) => dispatch(listBoroughs(districtId, order, asc)),
	getBorough: (id) => dispatch(getBorough(id)),
	editBorough: (id, data) => dispatch(editBorough(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
