import {connect} from 'react-redux';

import {editDistrict, getDistrict, listDistricts} from '../../../../actions/district';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.district.item,
	edited: state.district.edited,
	loading: state.district.status.loading,
	error: state.district.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listDistricts: (voivodeshipId, order, asc) => dispatch(listDistricts(voivodeshipId, order, asc)),
	getDistrict: (id) => dispatch(getDistrict(id)),
	editDistrict: (id, data) => dispatch(editDistrict(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
