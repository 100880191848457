import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Row,
	Col,
	Alert
} from 'react-bootstrap';

import {handleChange, setTitle} from '../../../../util/common';
import {Form, Input} from '../../../../components/Form';
import LayoutPrivate from '../../../../components/Layout/Private';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class AddAccount extends Component {
	state = {
		success: false,
		invalid: false,
		email: '',
		firstname: '',
		lastname: ''
	}

	componentDidMount() {
		setTitle(['Konta użytkowników', 'Dodaj nowe konto']);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.added && this.props.added) {
			this.props.listUsers('created', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push('/account');
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {addUser, loading} = this.props;

		const {
			email,
			firstname,
			lastname
		} = this.state;

		if (loading) return false;

		if (email === '') {
			this.setState({
				invalid: true,
				success: false
			});

			return false;
		}

		this.setState({
			invalid: false,
			success: false
		});

		addUser({
			email,
			firstname,
			lastname
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {
			loading,
			error
		} = this.props;

		const {
			email,
			firstname,
			lastname,
			success,
			invalid
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 6, offset: 3}}>
						<h2 style={{marginBottom: '2rem'}}>Nowe konto użytkownika</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
							<Row>
								<Col lg={{span: 6}}>
									<Input
										name="firstname"
										value={firstname}
										label="Imię"
										placeholder="Wpisz imię"
										handleChange={this.handleChange}
									/>
								</Col>
								<Col lg={{span: 6}}>
									<Input
										name="lastname"
										value={lastname}
										label="Nazwisko"
										placeholder="Wpisz nazwisko"
										handleChange={this.handleChange}
									/>
								</Col>
							</Row>

							<Input
								name="email"
								value={email}
								type="email"
								label="E-mail"
								placeholder="Wpisz e-mail"
								handleChange={this.handleChange}
								required
							/>

							<Alert variant="warning">
								<strong>Zatrzymaj się</strong> na chwilę :)
								<br />
								Jeżeli utworzysz konto użytkownikowi to ten użytkownik będzie miał dostęp do całego panelu. Prawo do odbierania dostępu posiada tylko super administrator i jest one nadawane przez administratora systemu.
							</Alert>

							<Button variant="outline-primary" type="submit">
								Utwórz nowe konto
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

AddAccount.propTypes = {
	history: PropTypes.object.isRequired,
	listUsers: PropTypes.func.isRequired,
	added: PropTypes.bool.isRequired,
	addUser: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

export default AddAccount;
