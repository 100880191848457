import {Auth} from '../util/actionTypes';
import {Status} from '../util/default';

const initialState = {
	status: Status.SUCCESS,
	logged: false,
	reset: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case Auth.LOGIN.REQUEST:
			return {
				...state,
				logged: false,
				status: Status.REQUEST
			};
		case Auth.LOGIN.SUCCESS:
			return {
				...state,
				logged: true,
				status: Status.SUCCESS
			};
		case Auth.LOGIN.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case Auth.RESET_PASSWORD.REQUEST:
			return {
				...state,
				reset: false,
				status: Status.REQUEST
			};
		case Auth.RESET_PASSWORD.SUCCESS:
			return {
				...state,
				reset: true,
				status: Status.SUCCESS
			};
		case Auth.RESET_PASSWORD.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		default:
			return state;
	}
};
