import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

const FieldGroup = (props) => {
	const {label, children} = props;

	return (
		<Form.Group className="mb-3" style={{border: '1px solid #c0c0c0', borderRadius: '5px', padding: '20px'}}>
			<Form.Label><strong>{label}</strong></Form.Label>
			<div style={{padding: '10px'}}>
				{children}
			</div>
		</Form.Group>
	);
};

FieldGroup.propTypes = {
	label: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

export default FieldGroup;
