import React, {Component} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	Form,
	Input,
	File,
	Select, Checkbox
} from '../../../../components/Form';
import {TimerStyle} from '../../../../util/types';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class EditTimer extends Component {
	state = {
		success: false,
		invalid: false,
		initialized: false,
		name: '',
		style: '',
		textAbove: '',
		deadline: '',
		textBelow: '',
		logoPath: null,
		hiddenBrand: false
	}

	componentDidMount() {
		const {timerId} = this.props.match.params;
		const {getTimer} = this.props;

		setTitle(['Liczniki', `Licznik #${timerId}`, 'Edytuj']);

		getTimer(timerId);

		return true;
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.item && this.props.item && this.props.item.id === parseInt(this.props.match.params.timerId, 10)) {
			this.setState({
				initialized: true,
				name: this.props.item.name,
				style: this.props.item.timer_style_name,
				textAbove: this.props.item.text_above,
				deadline: moment(this.props.item.deadline).format('YYYY-MM-DD'),
				textBelow: this.props.item.text_below,
				logoPath: this.props.item.logo_path && {
					name: this.props.item.logo_path,
					data: 'none'
				},
				hiddenBrand: this.props.item.hidden_brand === 1
			});
		}

		if (!prevProps.edited && this.props.edited) {
			this.props.listTimers('id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push('/timer');
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {timerId} = this.props.match.params;
		const {editTimer, loading} = this.props;

		const {
			name,
			style,
			textAbove,
			deadline,
			textBelow,
			logoPath,
			hiddenBrand
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		editTimer(timerId, {
			name,
			timer_style_name: style,
			text_above: textAbove,
			deadline,
			text_below: textBelow,
			logo_path: logoPath && (logoPath.data === 'none' ? logoPath.name : logoPath),
			hidden_brand: hiddenBrand
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {timerId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			initialized,
			success,
			invalid,
			name,
			style,
			textAbove,
			deadline,
			textBelow,
			logoPath,
			hiddenBrand
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>
							Edycja licznika #{timerId}
						</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							initialized && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<Input
										name="name"
										value={name}
										label="Nazwa licznika"
										placeholder="Wpisz nazwę licznika"
										handleChange={this.handleChange}
										required
									/>

									<Select
										name="style"
										value={style}
										label="Styl licznika"
										options={[
											{
												label: 'Wybierz styl',
												value: ''
											},
											{
												label: 'Jasny',
												value: TimerStyle.LIGHT
											},
											{
												label: 'Ciemny',
												value: TimerStyle.DARK
											}
										]}
										handleChange={this.handleChange}
										required
									/>

									<Input
										name="textAbove"
										value={textAbove}
										label="Tekst nad licznikiem"
										placeholder="Wpisz tekst nad licznikiem"
										handleChange={this.handleChange}
										required
									/>

									<Input
										name="deadline"
										value={deadline}
										type="date"
										label="Data docelowa licznika"
										placeholder="Wpisz datę docelową licznika"
										handleChange={this.handleChange}
										required
									/>

									<Input
										name="textBelow"
										value={textBelow}
										label="Tekst pod licznikiem"
										placeholder="Wpisz tekst pod licznikiem"
										handleChange={this.handleChange}
										required
									/>

									<File
										name="logoPath"
										value={logoPath}
										label="Logo partnera licznika (opcjonalne)"
										handleChange={this.handleChange}
									/>

									<Checkbox
										name="hiddenBrand"
										value={hiddenBrand}
										handleChange={this.handleChange}
										label="Logotyp Polskiego Alarmu Smogowego"
										fieldLabel="Ukryj logotyp Polskiego Alarmu Smogowego na tym liczniku"
									/>

									<Button variant="outline-primary" type="submit">
										Zapisz zmiany
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

EditTimer.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	item: PropTypes.object,
	getTimer: PropTypes.func.isRequired,
	edited: PropTypes.bool.isRequired,
	editTimer: PropTypes.func.isRequired,
	listTimers: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

EditTimer.defaultProps = {
	item: null
};

export default EditTimer;

