import {CalculatorDefinition} from '../util/actionTypes';
import {Status} from '../util/default';

const initialState = {
	status: Status.SUCCESS,
	list: null,
	set: false,
	imported: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CalculatorDefinition.GET.REQUEST:
			return {
				...state,
				list: null,
				status: Status.REQUEST
			};
		case CalculatorDefinition.GET.SUCCESS:
			return {
				...state,
				list: action.data,
				status: Status.SUCCESS
			};
		case CalculatorDefinition.GET.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case CalculatorDefinition.SET.REQUEST:
			return {
				...state,
				set: false,
				status: Status.REQUEST
			};
		case CalculatorDefinition.SET.SUCCESS:
			return {
				...state,
				set: true,
				status: Status.SUCCESS
			};
		case CalculatorDefinition.SET.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case CalculatorDefinition.IMPORT.REQUEST:
			return {
				...state,
				imported: false,
				status: Status.REQUEST
			};
		case CalculatorDefinition.IMPORT.SUCCESS:
			return {
				...state,
				imported: true,
				status: Status.SUCCESS
			};
		case CalculatorDefinition.IMPORT.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		default:
			return state;
	}
};
