import axios from 'axios';

import {CalculatorDefinition} from '../util/actionTypes';

export const getCalculatorDefinition = () => (dispatch) => {
	dispatch({
		type: CalculatorDefinition.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/calculator/definition/`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: CalculatorDefinition.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: CalculatorDefinition.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: CalculatorDefinition.GET.FAILURE
			});
		});
};

export const setCalculatorDefinition = (data) => (dispatch) => {
	dispatch({
		type: CalculatorDefinition.SET.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/calculator/definition/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: CalculatorDefinition.SET.SUCCESS
				});
			} else {
				dispatch({
					type: CalculatorDefinition.SET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: CalculatorDefinition.SET.FAILURE
			});
		});
};

export const importCalculatorDefinition = (data) => (dispatch) => {
	dispatch({
		type: CalculatorDefinition.IMPORT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/calculator/definition/import`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: CalculatorDefinition.IMPORT.SUCCESS
				});
			} else {
				dispatch({
					type: CalculatorDefinition.IMPORT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: CalculatorDefinition.IMPORT.FAILURE
			});
		});
};
