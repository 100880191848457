import React, {Component} from 'react';
import {Form} from 'react-bootstrap';
import PropTypes from 'prop-types';

class Textarea extends Component {
	handleChange = (e) => {
		const {handleChange, name} = this.props;

		handleChange(name, e.target.value);
	}

	render() {
		const {
			name,
			value,
			label,
			placeholder,
			required
		} = this.props;

		return (
			<Form.Group className="mb-3" controlId={name}>
				{
					label && (
						<Form.Label>{label}</Form.Label>
					)
				}
				<Form.Control
					name={name}
					as="textarea"
					defaultValue={value}
					placeholder={placeholder}
					onChange={this.handleChange}
					required={required}
					style={{height: '100px'}}
				/>
			</Form.Group>
		);
	}
}

Textarea.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	required: PropTypes.bool
};

Textarea.defaultProps = {
	label: null,
	required: false
};

export default Textarea;
