import axios from 'axios';

import {RankGroup} from '../util/actionTypes';

export const listRankGroups = (rankId, order, asc) => (dispatch) => {
	dispatch({
		type: RankGroup.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/rank_group/rank/${rankId}?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					id: rankId,
					type: RankGroup.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: RankGroup.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankGroup.LIST.FAILURE
			});
		});
};

export const getRankGroup = (id) => (dispatch) => {
	dispatch({
		type: RankGroup.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/rank_group/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RankGroup.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: RankGroup.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankGroup.GET.FAILURE
			});
		});
};

export const addRankGroup = (data) => (dispatch) => {
	dispatch({
		type: RankGroup.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/rank_group/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RankGroup.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: RankGroup.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankGroup.ADD.FAILURE
			});
		});
};

export const editRankGroup = (id, data) => (dispatch) => {
	dispatch({
		type: RankGroup.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/rank_group/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RankGroup.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: RankGroup.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankGroup.EDIT.FAILURE
			});
		});
};

export const deleteRankGroup = (id) => (dispatch) => {
	dispatch({
		type: RankGroup.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/rank_group/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RankGroup.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: RankGroup.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankGroup.DELETE.FAILURE
			});
		});
};
