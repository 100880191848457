import React from 'react';
import PropTypes from 'prop-types';

const Code = (props) => {
	const {
		code
	} = props;

	return (
		<code>
			{code}
		</code>
	);
};

Code.propTypes = {
	code: PropTypes.string.isRequired
};

export default Code;
