import axios from 'axios';

import {Recipient} from '../util/actionTypes';

export const listRecipients = (recipientGroupId, order, asc) => (dispatch) => {
	dispatch({
		type: Recipient.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/recipient/recipient_group/${recipientGroupId}?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					id: recipientGroupId,
					type: Recipient.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: Recipient.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Recipient.LIST.FAILURE
			});
		});
};

export const getRecipient = (id) => (dispatch) => {
	dispatch({
		type: Recipient.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/recipient/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Recipient.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: Recipient.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Recipient.GET.FAILURE
			});
		});
};

export const addRecipient = (data) => (dispatch) => {
	dispatch({
		type: Recipient.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/recipient/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Recipient.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: Recipient.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Recipient.ADD.FAILURE
			});
		});
};

export const importRecipient = (data) => (dispatch) => {
	dispatch({
		type: Recipient.IMPORT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/recipient/import`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Recipient.IMPORT.SUCCESS
				});
			} else {
				dispatch({
					type: Recipient.IMPORT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Recipient.IMPORT.FAILURE
			});
		});
};

export const editRecipient = (id, data) => (dispatch) => {
	dispatch({
		type: Recipient.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/recipient/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Recipient.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: Recipient.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Recipient.EDIT.FAILURE
			});
		});
};

export const deleteRecipient = (id) => (dispatch) => {
	dispatch({
		type: Recipient.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/recipient/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Recipient.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: Recipient.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Recipient.DELETE.FAILURE
			});
		});
};
