import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	Checkbox,
	Form,
	Input,
	FieldGroup,
	File,
	Textarea,
	Select
} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';
import {RankType} from '../../../../util/types';

class AddRank extends Component {
	state = {
		success: false,
		invalid: false,
		name: '',
		internalName: '',
		type: '',
		subname: '',
		chooseGroupTitle: '',
		showEmpty: false,
		searchValueTitle: '',
		tableValueTitle: '',
		logoPath: '',
		legend: '',
		showSummary: false,
		summaryTitle: ''
	}

	componentDidMount() {
		setTitle(['Rankingi', 'Dodaj nowy ranking']);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.added && this.props.added) {
			this.props.listRanks('id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push('/rank');
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			addRank,
			loading
		} = this.props;

		const {
			name,
			internalName,
			type,
			subname,
			chooseGroupTitle,
			showEmpty,
			searchValueTitle,
			tableValueTitle,
			logoPath,
			legend,
			showSummary,
			summaryTitle
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		addRank({
			name,
			internal_name: internalName,
			rank_type_name: type,
			subname,
			choose_group_title: chooseGroupTitle,
			show_empty: showEmpty,
			search_value_title: searchValueTitle,
			table_value_title: tableValueTitle,
			logo_path: logoPath,
			legend,
			show_summary: showSummary,
			summary_title: summaryTitle
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			name,
			internalName,
			type,
			subname,
			chooseGroupTitle,
			showEmpty,
			searchValueTitle,
			tableValueTitle,
			logoPath,
			legend,
			showSummary,
			summaryTitle
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Nowy ranking</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
							<Input
								name="name"
								value={name}
								label="Nazwa rankingu"
								placeholder="Wpisz nazwę rankingu"
								handleChange={this.handleChange}
								required
							/>

							<Input
								name="internalName"
								value={internalName}
								label="Wewnętrzna nazwa rankingu (w odnośniku)"
								placeholder="Wpisz wewnętrzną nazwę rankingu"
								handleChange={this.handleChange}
								required
							/>

							<Select
								name="type"
								value={type}
								label="Typ rankingu"
								options={[
									{
										label: 'Wybierz typ rankingu',
										value: ''
									},
									{
										label: 'Ranking gmin',
										value: RankType.BOROUGH
									},
									{
										label: 'Ranking województw',
										value: RankType.VOIVODESHIP
									}
								]}
								handleChange={this.handleChange}
								required
							/>

							{
								type === RankType.BOROUGH && (
									<>
										<Input
											name="subname"
											value={subname}
											label="Podtytuł rankingu (opcjonalnie)"
											placeholder="Wpisz podtytuł rankingu (opcjonalnie)"
											handleChange={this.handleChange}
										/>

										<Input
											name="chooseGroupTitle"
											value={chooseGroupTitle}
											label="Tytuł wyboru grupy"
											placeholder="Wpisz tytuł wyboru grupy"
											handleChange={this.handleChange}
										/>

										<Checkbox
											name="showEmpty"
											value={showEmpty}
											handleChange={this.handleChange}
											label="Wyświetlanie gmin, powiatów i województw"
											fieldLabel="Wyświetl gminy, powiaty i województwa na liście, które nie uzyskały punktów w rankingu"
										/>

										<FieldGroup label="Jednostka pomiarowa rankingu">
											<Row
												style={{
													border: '1px solid #d0d0d0',
													borderRadius: '5px',
													margin: '15px',
													padding: '1rem 0'
												}}
											>
												<Col lg={{span: 6}}>
													<Input
														name="searchValueTitle"
														value={searchValueTitle}
														label="Dla wyszukiwarki gmin"
														placeholder="Wpisz jednostkę pomiarową dla wyszukiwarki gmin"
														handleChange={this.handleChange}
														required
													/>
												</Col>
												<Col lg={{span: 6}}>
													<Input
														name="tableValueTitle"
														value={tableValueTitle}
														label="Dla tabeli rankingu"
														placeholder="Wpisz jednostkę pomiarową dla tabeli rankingu"
														handleChange={this.handleChange}
														required
													/>
												</Col>
											</Row>
										</FieldGroup>

										<File
											name="logoPath"
											value={logoPath}
											label="Logo partnera rankingu (opcjonalne)"
											handleChange={this.handleChange}
										/>
									</>
								)
							}

							{
								type === RankType.VOIVODESHIP && (
									<>
										<Input
											name="tableValueTitle"
											value={tableValueTitle}
											label="Jednostka pomiarowa dla rankingu"
											placeholder="Wpisz jednostkę pomiarową dla rankingu"
											handleChange={this.handleChange}
											required
										/>

										<Checkbox
											name="showSummary"
											value={showSummary}
											handleChange={this.handleChange}
											label="Podsumowanie wyników"
											fieldLabel="Wyświetl podsumowanie wyników zsumowych ze wszystkich pozycji"
										/>

										{
											showSummary && (
												<>
													<Input
														name="summaryTitle"
														value={summaryTitle}
														label="Nazwa specjalnej pozycji wyświetlającej zsumowane wyniki ze wszystkich pozycji"
														placeholder="Wpisz nazwę specjalnej pozycji wyświetlającej zsumowane wyniki ze wszystkich pozycji"
														handleChange={this.handleChange}
													/>
													<p>
														<small>Domyślna nazwa tej specjalnej pozycji to &quot;Wszystkie pozycje&quot;, ale może być nadpisana zgodnie z Twoimi preferencjami. Dopuszczalne są wyrażenia <em>%FIRST_ENTRY%</em> oraz <em>%LAST_ENTRY%</em>, które zostaną zamienione z nazwą pierwszej i ostatniej pozycji.</small>
													</p>
												</>
											)
										}
									</>
								)
							}

							{
								type && (
									<Textarea
										name="legend"
										value={legend}
										label="Legenda dla miejsca w rankingu (opcjonalnie)"
										placeholder="Wpisz legendę dla miejsca w rankingu (opcjonalnie)"
										handleChange={this.handleChange}
									/>
								)
							}

							<Button variant="outline-primary" type="submit">
								Utwórz nowy ranking
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

AddRank.propTypes = {
	history: PropTypes.object.isRequired,
	listRanks: PropTypes.func.isRequired,
	added: PropTypes.bool.isRequired,
	addRank: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

export default AddRank;
