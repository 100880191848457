import {connect} from 'react-redux';

import {login} from '../../../actions/auth';
import Login from './Login';

const mapStateToProps = (state) => ({
	logged: state.auth.logged,
	loading: state.auth.status.loading,
	error: state.auth.status.error
});

const mapDispatchToProps = (dispatch) => ({
	login: (email, password) => dispatch(login(email, password))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Login);
