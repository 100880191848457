import {connect} from 'react-redux';

import {deleteRecipient, listRecipients} from '../../../../actions/recipient';
import List from './List';

const mapStateToProps = (state, ownProps) => ({
	list: state.recipient.list[ownProps.match.params.recipientGroupId],
	deleted: state.recipient.deleted,
	loading: state.recipient.status.loading,
	error: state.recipient.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRecipients: (recipientGroupId, order, asc) => dispatch(listRecipients(recipientGroupId, order, asc)),
	deleteRecipient: (id) => dispatch(deleteRecipient(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
