import {connect} from 'react-redux';

import {deleteSearchEntry, listSearchEntries} from '../../../../actions/searchEntry';
import List from './List';

const mapStateToProps = (state, ownProps) => ({
	list: state.searchEntry.list[ownProps.match.params.searchId],
	deleted: state.searchEntry.deleted,
	loading: state.searchEntry.status.loading,
	error: state.searchEntry.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listSearchEntries: (searchId, order, asc) => dispatch(listSearchEntries(searchId, order, asc)),
	deleteSearchEntry: (id) => dispatch(deleteSearchEntry(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
