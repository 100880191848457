import {connect} from 'react-redux';

import {editMap, getMap, listMaps} from '../../../../actions/map';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.map.item,
	edited: state.map.edited,
	loading: state.map.status.loading,
	error: state.map.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listMaps: (order, asc) => dispatch(listMaps(order, asc)),
	getMap: (id) => dispatch(getMap(id)),
	editMap: (id, data) => dispatch(editMap(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
