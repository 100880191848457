import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {Form, Input} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class AddBorough extends Component {
	state = {
		success: false,
		invalid: false,
		name: ''
	}

	componentDidMount() {
		const {voivodeshipId, districtId} = this.props.match.params;

		setTitle(['Województwa', `Województwo #${voivodeshipId}`, 'Powiaty', `Powiat #${districtId}`, 'Gminy', 'Dodaj nową gminę']);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.added && this.props.added) {
			this.props.listBoroughs(this.props.match.params.districtId, 'id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/voivodeship/${this.props.match.params.voivodeshipId}/district/${this.props.match.params.districtId}/borough`);
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			addBorough,
			loading
		} = this.props;

		const {name} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		const {districtId} = this.props.match.params;

		addBorough({
			name,
			district_id: districtId
		});

		return true;
	};

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {districtId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			name
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Nowa gmina w powiecie #{districtId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
							<Input
								name="name"
								value={name}
								label="Nazwa gminy"
								placeholder="Wpisz nazwę gminy"
								handleChange={this.handleChange}
								required
							/>

							<Button variant="outline-primary" type="submit">
								Utwórz nową gminę
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

AddBorough.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	listBoroughs: PropTypes.func.isRequired,
	added: PropTypes.bool.isRequired,
	addBorough: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

export default AddBorough;
