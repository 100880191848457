import {connect} from 'react-redux';

import {deleteLetter, listLetters} from '../../../../actions/letter';
import List from './List';

const mapStateToProps = (state) => ({
	list: state.letter.list,
	deleted: state.letter.deleted,
	loading: state.letter.status.loading,
	error: state.letter.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listLetters: (order, asc) => dispatch(listLetters(order, asc)),
	deleteLetter: (id) => dispatch(deleteLetter(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
