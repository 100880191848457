import {connect} from 'react-redux';

import {importCalculatorDefinition, getCalculatorDefinition} from '../../../../actions/calculatorDefinition';
import Import from './Import';

const mapStateToProps = (state) => ({
	imported: state.calculatorDefinition.imported,
	loading: state.calculatorDefinition.status.loading,
	error: state.calculatorDefinition.status.error
});

const mapDispatchToProps = (dispatch) => ({
	getCalculatorDefinition: () => dispatch(getCalculatorDefinition()),
	importCalculatorDefinition: (data) => dispatch(importCalculatorDefinition(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Import);
