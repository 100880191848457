import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	File,
	Form,
	Html,
	Input,
	Select, Textarea
} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class EditLetter extends Component {
	state = {
		success: false,
		invalid: false,
		initialized: false,
		name: '',
		recipientGroupId: '',
		coverPath: null,
		title: '',
		content: '',
		gdprAgreement: '',
		gdprInfo: ''
	}

	componentDidMount() {
		const {letterId} = this.props.match.params;
		const {getLetter} = this.props;

		setTitle(['Listy', `List #${letterId}`, 'Edytuj']);

		this.loadRecipientGroups();
		getLetter(letterId);

		return true;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.recipientGroups !== this.props.recipientGroups && this.props.recipientGroups) {
			this.setRecipientGroups();
		}

		if (!prevProps.item && this.props.item && this.props.item.id === parseInt(this.props.match.params.letterId, 10)) {
			this.setState({
				initialized: true,
				name: this.props.item.name,
				recipientGroupId: this.props.item.recipient_group_id,
				coverPath: this.props.item.cover_path && {
					name: this.props.item.cover_path,
					data: 'none'
				},
				title: this.props.item.title,
				content: this.props.item.content,
				gdprAgreement: this.props.item.gdpr_agreement,
				gdprInfo: this.props.item.gdpr_info
			});
		}

		if (!prevProps.edited && this.props.edited) {
			this.props.listLetters('id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push('/letter');
				}, SuccessMessageTimeout);
			});
		}
	}

	loadRecipientGroups = () => {
		const {recipientGroups, listRecipientGroups} = this.props;

		if (recipientGroups && recipientGroups.order === 'name' && recipientGroups.asc) {
			this.setRecipientGroups();
		} else {
			listRecipientGroups('name', true);
		}
	}

	setRecipientGroups = () => {
		const {recipientGroups} = this.props;

		this.setState({
			recipientGroupOptions: [
				{
					label: 'Wybierz grupę odbiorców',
					value: ''
				},
				...recipientGroups.list.map((r) => ({
					label: r.name,
					value: r.id
				}))
			]
		});
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {letterId} = this.props.match.params;
		const {editLetter, loading} = this.props;
		const {
			name,
			title,
			recipientGroupId,
			coverPath,
			content,
			gdprAgreement,
			gdprInfo
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		editLetter(letterId, {
			name,
			title,
			recipient_group_id: recipientGroupId,
			cover_path: coverPath,
			content,
			gdpr_agreement: gdprAgreement,
			gdpr_info: gdprInfo
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {letterId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			initialized,
			success,
			invalid,
			name,
			recipientGroupId,
			recipientGroupOptions,
			coverPath,
			title,
			content,
			gdprAgreement,
			gdprInfo
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Edycja listu #{letterId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							initialized && recipientGroupOptions && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<Input
										name="name"
										value={name}
										label="Nazwa listu"
										placeholder="Wpisz nazwę listu"
										handleChange={this.handleChange}
										required
									/>

									<Select
										name="recipientGroupId"
										value={recipientGroupId}
										label="Grupa odbiorców"
										options={recipientGroupOptions}
										handleChange={this.handleChange}
										required
									/>

									<File
										name="coverPath"
										value={coverPath}
										label="Zdjęcie w tle (opcjonalne)"
										handleChange={this.handleChange}
									/>

									<Input
										name="title"
										value={title}
										label="Tytuł listu"
										placeholder="Wpisz tytuł listu"
										handleChange={this.handleChange}
										required
									/>

									<Html
										name="content"
										value={content}
										label="Treść listu"
										handleChange={this.handleChange}
									/>

									<Input
										name="gdprAgreement"
										value={gdprAgreement}
										label="Treść zgody RODO (opcjonalne)"
										placeholder="Wpisz treść zgody RODO"
										handleChange={this.handleChange}
									/>

									<p>
										<small>
											Domyślna treść zgody to &quot;Wyrażam zgodę na przetwarzanie danych osobowych podanych w formularzu&quot;, ale może być nadpisana zgodnie z Twoimi preferencjami.
										</small>
									</p>

									<Textarea
										name="gdprInfo"
										value={gdprInfo}
										label="Informacje związane z RODO (opcjonalne)"
										placeholder="Wpisz informacje związane z RODO"
										handleChange={this.handleChange}
									/>

									<Button variant="outline-primary" type="submit">
										Zapisz zmiany
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

EditLetter.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	item: PropTypes.object,
	getLetter: PropTypes.func.isRequired,
	edited: PropTypes.bool.isRequired,
	editLetter: PropTypes.func.isRequired,
	listLetters: PropTypes.func.isRequired,
	recipientGroups: PropTypes.object,
	listRecipientGroups: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

EditLetter.defaultProps = {
	recipientGroups: null,
	item: null
};

export default EditLetter;

