import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	Row,
	Col,
	Alert
} from 'react-bootstrap';
import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	Select,
	Form,
	File
} from '../../../../components/Form';
import {ImportFileType} from '../../../../util/types';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class ImportRankGroupValue extends Component {
	state = {
		success: false,
		invalid: false,
		type: '',
		file: null
	}

	componentDidMount() {
		const {rankId, rankGroupId} = this.props.match.params;

		setTitle(['Rankingi', `Ranking #${rankId}`, 'Grupy', `Grupa #${rankGroupId}`, 'Wartości', 'Zaimportuj nowe wartości']);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.imported && this.props.imported) {
			this.props.listRankValues(this.props.match.params.rankGroupId, 'place', true);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/rank/${this.props.match.params.rankId}/group/${this.props.match.params.rankGroupId}/value`);
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			importRankValue,
			loading
		} = this.props;

		const {
			type,
			file
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		const {rankGroupId} = this.props.match.params;

		importRankValue({
			rank_group_id: rankGroupId,
			type,
			file
		});

		return true;
	};

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {rankId, rankGroupId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			type,
			file
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Wczytaj nowe wartości dla rankingu #{rankId} w grupie #{rankGroupId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
							<Select
								name="type"
								value={type}
								label="Rodzaj pliku wartości"
								options={[
									{
										label: 'Wybierz rodzaj pliku',
										value: ''
									},
									{
										label: 'Ranking gmin XLS',
										value: ImportFileType.RB_XLS
									}
								]}
								handleChange={this.handleChange}
								required
							/>

							{
								type === ImportFileType.RB_XLS && (
									<Alert variant="warning">
										<strong>Zatrzymaj się</strong> na chwilę :)<br />
										Jeżeli wykonujesz import to wszystkie nowe województwa, powiaty i gminy zostaną dodane do bazy systemu. Może się to przełożyć na duplikaty w przypadku, gdy plik zawiera literówki.<br />
										<br />
										W przypadku, gdy wartość nakłada się już z istniejącą to zostanie nadpisana.
									</Alert>
								)
							}

							{
								type && (
									<File
										name="file"
										value={file}
										label="Plik wartości"
										handleChange={this.handleChange}
										required
									/>
								)
							}

							<Button variant="outline-primary" type="submit">
								Zaimportuj nowe wartości
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

ImportRankGroupValue.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	listRankValues: PropTypes.func.isRequired,
	imported: PropTypes.bool.isRequired,
	importRankValue: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

export default ImportRankGroupValue;
