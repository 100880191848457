import {connect} from 'react-redux';

import {deleteSearch, listSearches} from '../../../../actions/search';
import List from './List';

const mapStateToProps = (state) => ({
	list: state.search.list,
	deleted: state.search.deleted,
	loading: state.search.status.loading,
	error: state.search.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listSearches: (order, asc) => dispatch(listSearches(order, asc)),
	deleteSearch: (id) => dispatch(deleteSearch(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
