import {connect} from 'react-redux';

import {editGraph, getGraph, listGraphs} from '../../../../actions/graph';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.graph.item,
	edited: state.graph.edited,
	loading: state.graph.status.loading,
	error: state.graph.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listGraphs: (order, asc) => dispatch(listGraphs(order, asc)),
	getGraph: (id) => dispatch(getGraph(id)),
	editGraph: (id, data) => dispatch(editGraph(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
