import {connect} from 'react-redux';

import {addRecipientGroup, listRecipientGroups} from '../../../../actions/recipientGroup';
import Add from './Add';

const mapStateToProps = (state) => ({
	added: state.recipientGroup.added,
	loading: state.recipientGroup.status.loading,
	error: state.recipientGroup.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRecipientGroups: (order, asc) => dispatch(listRecipientGroups(order, asc)),
	addRecipientGroup: (data) => dispatch(addRecipientGroup(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
