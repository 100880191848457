import axios from 'axios';

import {ItemType} from '../util/actionTypes';

export const listItemTypes = (order, asc) => (dispatch) => {
	dispatch({
		type: ItemType.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/item_type/?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: ItemType.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: ItemType.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: ItemType.LIST.FAILURE
			});
		});
};

export const getItemType = (id) => (dispatch) => {
	dispatch({
		type: ItemType.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/item_type/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: ItemType.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: ItemType.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: ItemType.GET.FAILURE
			});
		});
};
