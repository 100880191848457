import {connect} from 'react-redux';

import {deleteGraphEntry, listGraphEntries} from '../../../../actions/graphEntry';
import List from './List';

const mapStateToProps = (state, ownProps) => ({
	list: state.graphEntry.list[ownProps.match.params.graphGroupId],
	deleted: state.graphEntry.deleted,
	loading: state.graphEntry.status.loading,
	error: state.graphEntry.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listGraphEntries: (graphGroupId, order, asc) => dispatch(listGraphEntries(graphGroupId, order, asc)),
	deleteGraphEntry: (id) => dispatch(deleteGraphEntry(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
