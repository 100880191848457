import {connect} from 'react-redux';

import {deleteDistrict, listDistricts} from '../../../../actions/district';
import List from './List';

const mapStateToProps = (state, ownProps) => ({
	list: state.district.list[ownProps.match.params.voivodeshipId],
	deleted: state.district.deleted,
	loading: state.district.status.loading,
	error: state.district.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listDistricts: (voivodeshipId, order, asc) => dispatch(listDistricts(voivodeshipId, order, asc)),
	deleteDistrict: (id) => dispatch(deleteDistrict(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
