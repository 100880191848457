import {connect} from 'react-redux';

import {deleteCalculator, listCalculators} from '../../../../actions/calculator';
import List from './List';

const mapStateToProps = (state) => ({
	list: state.calculator.list,
	deleted: state.calculator.deleted,
	loading: state.calculator.status.loading,
	error: state.calculator.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listCalculators: (order, asc) => dispatch(listCalculators(order, asc)),
	deleteCalculator: (id) => dispatch(deleteCalculator(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
