import {connect} from 'react-redux';

import {addRecipient, listRecipients} from '../../../../actions/recipient';
import Add from './Add';

const mapStateToProps = (state) => ({
	added: state.recipient.added,
	loading: state.recipient.status.loading,
	error: state.recipient.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRecipients: (recipientGroupId, order, asc) => dispatch(listRecipients(recipientGroupId, order, asc)),
	addRecipient: (data) => dispatch(addRecipient(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
