import {connect} from 'react-redux';

import {importSearchEntry, listSearchEntries} from '../../../../actions/searchEntry';
import Import from './Import';

const mapStateToProps = (state) => ({
	imported: state.searchEntry.imported,
	loading: state.searchEntry.status.loading,
	error: state.searchEntry.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listSearchEntries: (searchGroupId, order, asc) => dispatch(listSearchEntries(searchGroupId, order, asc)),
	importSearchEntry: (data) => dispatch(importSearchEntry(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Import);
