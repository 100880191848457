import {connect} from 'react-redux';

import {deleteItem, listItems} from '../../../../actions/item';
import List from './List';

const mapStateToProps = (state) => ({
	list: state.item.list,
	deleted: state.item.deleted,
	loading: state.item.status.loading,
	error: state.item.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listItems: (order, asc) => dispatch(listItems(order, asc)),
	deleteItem: (id) => dispatch(deleteItem(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
