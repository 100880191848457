import {connect} from 'react-redux';

import {listGraphGroups} from '../../../../actions/graphGroup';
import {getGraph, importGraph} from '../../../../actions/graph';
import Import from './Import';

const mapStateToProps = (state) => ({
	graph: state.graph.item,
	imported: state.graph.imported,
	loading: state.graphGroup.status.loading || state.graph.status.loading,
	error: state.graphGroup.status.error || state.graph.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listGraphGroups: (graphId, order, asc) => dispatch(listGraphGroups(graphId, order, asc)),
	getGraph: (id) => dispatch(getGraph(id)),
	importGraph: (data) => dispatch(importGraph(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Import);
