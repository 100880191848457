import {connect} from 'react-redux';

import {addRankValue, listRankGroupValues} from '../../../../actions/rankValue';
import {listAllBoroughs} from '../../../../actions/borough';
import {getRank} from '../../../../actions/rank';
import Add from './Add';

const mapStateToProps = (state) => ({
	rank: state.rank.item,
	boroughs: state.borough.allList,
	added: state.rankValue.added,
	loading: state.rankValue.status.loading || state.borough.status.loading || state.rank.status.loading,
	error: state.rankValue.status.error || state.borough.status.error || state.rank.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRankValues: (rankGroupId, order, asc) => dispatch(listRankGroupValues(rankGroupId, order, asc)),
	getRank: (id) => dispatch(getRank(id)),
	listAllBoroughs: (order, asc) => dispatch(listAllBoroughs(order, asc)),
	addRankValue: (data) => dispatch(addRankValue(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
