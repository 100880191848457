import {connect} from 'react-redux';

import {resetPassword} from '../../../actions/auth';
import ForgotPassword from './ForgotPassword';

const mapStateToProps = (state) => ({
	reset: state.auth.reset,
	loading: state.auth.status.loading,
	error: state.auth.status.error
});

const mapDispatchToProps = (dispatch) => ({
	resetPassword: (email) => dispatch(resetPassword(email))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ForgotPassword);
