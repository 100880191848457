import axios from 'axios';

import {RankEntry} from '../util/actionTypes';

export const listRankEntries = (rankGroupId, order, asc) => (dispatch) => {
	dispatch({
		type: RankEntry.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/rank_entry/rank_group/${rankGroupId}?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					id: rankGroupId,
					type: RankEntry.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: RankEntry.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankEntry.LIST.FAILURE
			});
		});
};

export const getRankEntry = (id) => (dispatch) => {
	dispatch({
		type: RankEntry.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/rank_entry/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RankEntry.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: RankEntry.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankEntry.GET.FAILURE
			});
		});
};

export const addRankEntry = (data) => (dispatch) => {
	dispatch({
		type: RankEntry.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/rank_entry/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RankEntry.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: RankEntry.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankEntry.ADD.FAILURE
			});
		});
};

export const editRankEntry = (id, data) => (dispatch) => {
	dispatch({
		type: RankEntry.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/rank_entry/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RankEntry.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: RankEntry.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankEntry.EDIT.FAILURE
			});
		});
};

export const deleteRankEntry = (id) => (dispatch) => {
	dispatch({
		type: RankEntry.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/rank_entry/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: RankEntry.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: RankEntry.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: RankEntry.DELETE.FAILURE
			});
		});
};
