import React, {Component} from 'react';
import {Form as BootstrapForm} from 'react-bootstrap';
import PropTypes from 'prop-types';

class Form extends Component {
	handleSubmit = (e) => {
		e.preventDefault();

		const {handleSubmit, handleError} = this.props;

		if (!e.currentTarget.checkValidity()) {
			handleError();
			return false;
		}

		handleSubmit();

		return true;
	};

	render() {
		const {children} = this.props;

		return (
			<BootstrapForm noValidate onSubmit={this.handleSubmit}>
				{children}
			</BootstrapForm>
		);
	}
}

Form.propTypes = {
	children: PropTypes.node.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	handleError: PropTypes.func.isRequired
};

export default Form;
