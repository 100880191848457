import React, {Component} from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import update from 'immutability-helper';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	FieldGroup,
	Form,
	Input,
	Select, Textarea
} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';
import {GraphStyle, GraphType} from '../../../../util/types';

class EditGraph extends Component {
	state = {
		success: false,
		invalid: false,
		initialized: false,
		name: '',
		subname: '',
		unit: '',
		source: '',
		legend: '',
		type: '',
		style: '',
		graphKeys: [],
		graphKeyGroups: [],
		positionTitle: '',
		valueTitle: ''
	}

	componentDidMount() {
		const {graphId} = this.props.match.params;
		const {getGraph} = this.props;

		setTitle(['Wykresy', `Wykres #${graphId}`, 'Edytuj']);

		getGraph(graphId);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.item && this.props.item && this.props.item.id === parseInt(this.props.match.params.graphId, 10)) {
			const {item} = this.props;

			this.setState({
				initialized: true,
				name: item.name,
				subname: item.subname || '',
				unit: item.unit || '',
				source: item.source || '',
				legend: item.legend || '',
				type: item.graph_type_name || '',
				style: item.graph_style_name || '',
				graphKeys: [GraphType.CIRCLE, GraphType.MULTILINE].includes(item.graph_type_name) ? item.graph_keys.map((graphKey) => ({
					uuid: shortid.generate(),
					id: graphKey.id,
					name: graphKey.name,
					short_name: graphKey.short_name
				})) : [],
				graphKeyGroups: [GraphType.BAR, GraphType.LINE].includes(item.graph_type_name) ? item.graph_key_groups.map((graphKeyGroup) => ({
					uuid: shortid.generate(),
					id: graphKeyGroup.id,
					name: graphKeyGroup.name,
					color: graphKeyGroup.color,
					graph_keys: graphKeyGroup.graph_keys.map((graphKey) => ({
						uuid: shortid.generate(),
						id: graphKey.id,
						name: graphKey.name,
						short_name: graphKey.short_name
					}))
				})) : [],
				positionTitle: item.position_title,
				valueTitle: item.value_title
			});
		}

		if (!prevProps.edited && this.props.edited) {
			this.props.listGraphs('id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push('/graph');
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {graphId} = this.props.match.params;
		const {editGraph, loading} = this.props;

		const {
			name,
			subname,
			unit,
			source,
			legend,
			type,
			style,
			graphKeys,
			graphKeyGroups,
			positionTitle,
			valueTitle
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		editGraph(graphId, {
			name,
			subname,
			unit,
			source,
			legend,
			graph_type_name: type,
			graph_style_name: style,
			graph_keys: graphKeys,
			graph_key_groups: graphKeyGroups,
			position_title: positionTitle,
			value_title: valueTitle
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	addGraphKeyToGroup = (i) => {
		const graphKey = {
			uuid: shortid.generate(),
			id: null,
			name: '',
			short_name: ''
		};

		this.setState((prevProps) => update(prevProps, {
			graphKeyGroups: {
				[i]: {
					graph_keys: {$push: [graphKey]}
				}
			}
		}));
	}

	addGraphKey = () => {
		const graphKey = {
			uuid: shortid.generate(),
			id: null,
			name: '',
			short_name: ''
		};

		this.setState((prevProps) => update(prevProps, {
			graphKeys: {$push: [graphKey]}
		}));
	}

	removeGraphKeyFromGroup = (i, j) => {
		this.setState((prevProps) => update(prevProps, {
			graphKeyGroups: {
				[i]: {
					graph_keys: {$splice: [[j, 1]]}
				}
			}
		}));
	}

	removeGraphKey = (index) => {
		this.setState((prevProps) => update(prevProps, {
			graphKeys: {$splice: [[index, 1]]}
		}));
	}

	addGraphKeyGroup = () => {
		const graphKeyGroup = {
			uuid: shortid.generate(),
			id: null,
			name: '',
			color: '',
			graph_keys: []
		};

		this.setState((prevProps) => update(prevProps, {
			graphKeyGroups: {$push: [graphKeyGroup]}
		}));
	}

	removeGraphKeyGroup = (index) => {
		this.setState((prevProps) => update(prevProps, {
			graphKeyGroups: {$splice: [[index, 1]]}
		}));
	}

	render() {
		const {graphId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			initialized,
			success,
			invalid,
			name,
			subname,
			unit,
			source,
			legend,
			type,
			style,
			graphKeys,
			graphKeyGroups,
			positionTitle,
			valueTitle
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Edycja wykresu #{graphId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							initialized && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<Input
										name="name"
										value={name}
										label="Nazwa wykresu"
										placeholder="Wpisz nazwę wykresu"
										handleChange={this.handleChange}
										required
									/>

									<Input
										name="subname"
										value={subname}
										label="Podtytuł wykresu (opcjonalnie)"
										placeholder="Wpisz podtytuł wykresu (opcjonalnie)"
										handleChange={this.handleChange}
									/>

									{
										type === GraphType.BAR && (
											<Input
												name="unit"
												value={unit}
												label="Jednostka dla wartości wykresu"
												type="text"
												placeholder="Wpisz jednostkę dla wartości wykresu"
												handleChange={this.handleChange}
												required
											/>
										)
									}

									<Select
										name="style"
										value={style}
										label="Motyw wykresu"
										options={[
											{
												label: 'Wybierz motyw',
												value: ''
											},
											{
												label: 'Klasyczny',
												value: GraphStyle.CLASSIC
											},
											{
												label: 'Nowoczesny',
												value: GraphStyle.MODERN
											}
										]}
										handleChange={this.handleChange}
										required
									/>

									<Row>
										<Col lg={{span: 6}}>
											<Input
												name="positionTitle"
												value={positionTitle}
												label="Tytuł dla pozycji w tabeli"
												placeholder="Wpisz tytuł dla pozycji w tabeli"
												handleChange={this.handleChange}
												required
											/>
										</Col>
										<Col lg={{span: 6}}>
											<Input
												name="valueTitle"
												value={valueTitle}
												label="Tytuł dla wartości w tabeli"
												placeholder="Wpisz tytuł dla wartości w tabeli"
												handleChange={this.handleChange}
												required
											/>
											<p>
												<small>Dopuszczalne jest wyrażenie <em>%GROUP%</em>, które zostanie zamienione z nazwą grupy wykresu.</small>
											</p>
										</Col>
									</Row>

									<Input
										name="source"
										value={source}
										label="Źródło danych (opcjonalnie)"
										placeholder="Wpisz źródło danych (opcjonalnie)"
										handleChange={this.handleChange}
									/>

									<Textarea
										name="legend"
										value={legend}
										label="Legenda dla wartości wykresu (opcjonalnie)"
										placeholder="Wpisz legendę dla wartości wykresu (opcjonalnie)"
										handleChange={this.handleChange}
									/>

									{
										[GraphType.BAR, GraphType.LINE].includes(type) && (
											<FieldGroup label="Grupy własności wykresu">
												{
													graphKeyGroups.map((graphKeyGroup, i) => (
														<Row
															key={graphKeyGroup.uuid}
															style={{
																border: '1px solid #d0d0d0',
																borderRadius: '5px',
																margin: '15px',
																padding: '1rem 0'
															}}
														>
															<Col lg={{span: 12}}>
																<Input
																	name={`graphKeyGroups[${i}].name`}
																	value={graphKeyGroup.name}
																	label="Nazwa grupy"
																	placeholder="Wpisz nazwę grupy"
																	handleChange={this.handleChange}
																	required
																/>
																<Input
																	name={`graphKeyGroups[${i}].color`}
																	value={graphKeyGroup.color}
																	label="Kolor grupy"
																	placeholder="Wpisz kolor grupy"
																	handleChange={this.handleChange}
																	required
																/>
																<p>
																	<small>Dopuszczalne są kolory w języku angielskim oraz <em>rgb</em>, <em>rgba</em>, <em>hex</em>.</small>
																</p>
																<FieldGroup label="Własności grupy">
																	{
																		graphKeyGroup.graph_keys.map((graphKey, j) => (
																			<Row
																				key={graphKey.uuid}
																				style={{
																					border: '1px solid #d0d0d0',
																					borderRadius: '5px',
																					margin: '15px',
																					padding: '1rem 0'
																				}}
																			>
																				<Col lg={{span: 12}}>
																					<Input
																						name={`graphKeyGroups[${i}].graph_keys[${j}].name`}
																						value={graphKey.name}
																						label="Nazwa właściwości"
																						placeholder="Wpisz nazwę właściwości"
																						handleChange={this.handleChange}
																						required
																					/>
																					<Input
																						name={`graphKeyGroups[${i}].graph_keys[${j}].short_name`}
																						value={graphKey.short_name}
																						label="Skrócona nazwa właściwości (na wykres)"
																						placeholder="Wpisz skróconą nazwę właściwości"
																						handleChange={this.handleChange}
																						required
																					/>
																				</Col>
																				<Col lg={{span: 12}}>
																					<Button
																						size="sm"
																						variant="outline-danger"
																						onClick={() => this.removeGraphKeyFromGroup(i, j)}
																					>
																						Usuń tą właściwość
																					</Button>
																				</Col>
																			</Row>
																		))
																	}

																	<Button
																		size="sm"
																		variant="outline-success"
																		onClick={() => this.addGraphKeyToGroup(i)}
																	>
																		Dodaj nową właściwość
																	</Button>
																</FieldGroup>
															</Col>
															<Col lg={{span: 12}}>
																<Button
																	size="sm"
																	variant="outline-danger"
																	onClick={() => this.removeGraphKeyGroup(i)}
																>
																	Usuń tę grupę
																</Button>
															</Col>
														</Row>
													))
												}

												<Button size="sm" variant="outline-success" onClick={this.addGraphKeyGroup}>
													Dodaj nową grupę
												</Button>
											</FieldGroup>
										)
									}
									{
										[GraphType.CIRCLE, GraphType.MULTILINE].includes(type) && (
											<FieldGroup label="Własności wykresu">
												{
													graphKeys.map((graphKey, index) => (
														<Row
															key={graphKey.uuid}
															style={{
																border: '1px solid #d0d0d0',
																borderRadius: '5px',
																margin: '15px',
																padding: '1rem 0'
															}}
														>
															<Col lg={{span: 12}}>
																<Input
																	name={`graphKeys[${index}].name`}
																	value={graphKey.name}
																	label="Nazwa właściwości"
																	placeholder="Wpisz nazwę właściwości"
																	handleChange={this.handleChange}
																	required
																/>
																<Input
																	name={`graphKeys[${index}].short_name`}
																	value={graphKey.short_name}
																	label="Skrócona nazwa właściwości (na wykres)"
																	placeholder="Wpisz skróconą nazwę właściwości"
																	handleChange={this.handleChange}
																	required
																/>
															</Col>
															<Col lg={{span: 12}}>
																<Button size="sm" variant="outline-danger" onClick={() => this.removeGraphKey(index)}>
																	Usuń tą właściwość
																</Button>
															</Col>
														</Row>
													))
												}

												<Button size="sm" variant="outline-success" onClick={this.addGraphKey}>
													Dodaj nową właściwość
												</Button>
											</FieldGroup>
										)
									}

									<Button variant="outline-primary" type="submit">
										Zapisz zmiany
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

EditGraph.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	item: PropTypes.object,
	getGraph: PropTypes.func.isRequired,
	edited: PropTypes.bool.isRequired,
	editGraph: PropTypes.func.isRequired,
	listGraphs: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

EditGraph.defaultProps = {
	item: null
};

export default EditGraph;

