import React from 'react';
import PropTypes from 'prop-types';
import {Alert} from 'react-bootstrap';

const Status = (props) => {
	const {
		error,
		loading,
		success,
		invalid
	} = props;

	return (
		<>
			{
				error && (
					<Alert variant="danger">
						Wystąpił błąd! Sprawdź przekazane dane i ponów próbę.
					</Alert>
				)
			}
			{
				loading && (
					<Alert variant="warning">
						Komunikacja z serwerem w toku. Chwileczkę...
					</Alert>
				)
			}
			{
				invalid && (
					<Alert variant="danger">
						Popraw dane w formularzu, gdyż wystąpił błąd.
					</Alert>
				)
			}
			{
				!loading && success && (
					<Alert variant="success">
						Operacja została zakończona :)
					</Alert>
				)
			}
		</>
	);
};

Status.propTypes = {
	error: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	success: PropTypes.bool,
	invalid: PropTypes.bool
};

Status.defaultProps = {
	success: false,
	invalid: false
};

export default Status;
