import {connect} from 'react-redux';

import {importCheckpoint, listCheckpoints} from '../../../../actions/checkpoint';
import Import from './Import';

const mapStateToProps = (state) => ({
	imported: state.checkpoint.imported,
	loading: state.checkpoint.status.loading,
	error: state.checkpoint.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listCheckpoints: (voivodeshipId, order, asc) => dispatch(listCheckpoints(voivodeshipId, order, asc)),
	importCheckpoint: (data) => dispatch(importCheckpoint(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Import);
