import {connect} from 'react-redux';

import {editRankValue, getRankValue, listRankEntryValues} from '../../../../actions/rankValue';
import {getRank} from '../../../../actions/rank';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	rank: state.rank.item,
	item: state.rankValue.item,
	edited: state.rankValue.edited,
	loading: state.rankValue.status.loading || state.rank.status.loading,
	error: state.rankValue.status.error || state.rank.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRankValues: (rankEntryId, order, asc) => dispatch(listRankEntryValues(rankEntryId, order, asc)),
	getRank: (id) => dispatch(getRank(id)),
	getRankValue: (id) => dispatch(getRankValue(id)),
	editRankValue: (id, data) => dispatch(editRankValue(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
