import {connect} from 'react-redux';

import {addRankGroup, listRankGroups} from '../../../../actions/rankGroup';
import Add from './Add';

const mapStateToProps = (state) => ({
	added: state.rankGroup.added,
	loading: state.rankGroup.status.loading,
	error: state.rankGroup.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRankGroups: (rankId, order, asc) => dispatch(listRankGroups(rankId, order, asc)),
	addRankGroup: (data) => dispatch(addRankGroup(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
