import {combineReducers} from 'redux';

import auth from './auth';
import user from './user';
import voivodeship from './voivodeship';
import district from './district';
import borough from './borough';
import calculator from './calculator';
import calculatorDefinition from './calculatorDefinition';
import rank from './rank';
import rankGroup from './rankGroup';
import rankEntry from './rankEntry';
import rankValue from './rankValue';
import map from './map';
import checkpoint from './checkpoint';
import search from './search';
import searchEntry from './searchEntry';
import timer from './timer';
import graph from './graph';
import graphGroup from './graphGroup';
import graphEntry from './graphEntry';
import letter from './letter';
import recipientGroup from './recipientGroup';
import recipient from './recipient';
import item from './item';
import itemType from './itemType';

export default combineReducers({
	auth,
	user,
	voivodeship,
	district,
	borough,
	calculator,
	calculatorDefinition,
	rank,
	rankGroup,
	rankEntry,
	rankValue,
	map,
	checkpoint,
	search,
	searchEntry,
	timer,
	graph,
	graphGroup,
	graphEntry,
	letter,
	recipientGroup,
	recipient,
	item,
	itemType
});
