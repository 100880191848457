import {connect} from 'react-redux';

import {deleteRankEntry, listRankEntries} from '../../../../actions/rankEntry';
import List from './List';

const mapStateToProps = (state, ownProps) => ({
	list: state.rankEntry.list[ownProps.match.params.rankGroupId],
	deleted: state.rankEntry.deleted,
	loading: state.rankEntry.status.loading,
	error: state.rankEntry.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRankEntries: (rankGroupId, order, asc) => dispatch(listRankEntries(rankGroupId, order, asc)),
	deleteRankEntry: (id) => dispatch(deleteRankEntry(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
