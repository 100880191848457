import axios from 'axios';

import {GraphEntry} from '../util/actionTypes';

export const listGraphEntries = (graphGroupId, order, asc) => (dispatch) => {
	dispatch({
		type: GraphEntry.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/graph_entry/graph_group/${graphGroupId}?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					id: graphGroupId,
					type: GraphEntry.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: GraphEntry.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: GraphEntry.LIST.FAILURE
			});
		});
};

export const getGraphEntry = (id) => (dispatch) => {
	dispatch({
		type: GraphEntry.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/graph_entry/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: GraphEntry.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: GraphEntry.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: GraphEntry.GET.FAILURE
			});
		});
};

export const addGraphEntry = (data) => (dispatch) => {
	dispatch({
		type: GraphEntry.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/graph_entry/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: GraphEntry.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: GraphEntry.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: GraphEntry.ADD.FAILURE
			});
		});
};

export const editGraphEntry = (id, data) => (dispatch) => {
	dispatch({
		type: GraphEntry.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/graph_entry/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: GraphEntry.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: GraphEntry.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: GraphEntry.EDIT.FAILURE
			});
		});
};

export const deleteGraphEntry = (id) => (dispatch) => {
	dispatch({
		type: GraphEntry.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/graph_entry/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: GraphEntry.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: GraphEntry.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: GraphEntry.DELETE.FAILURE
			});
		});
};
