import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	File,
	Form,
	Html,
	Input,
	Select,
	Textarea
} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class AddLetter extends Component {
	state = {
		success: false,
		invalid: false,
		name: '',
		internalName: '',
		recipientGroupId: '',
		recipientGroupOptions: null,
		coverPath: null,
		title: '',
		content: '',
		gdprAgreement: '',
		gdprInfo: ''
	}

	componentDidMount() {
		setTitle(['Listy', 'Dodaj nowy list']);

		this.loadRecipientGroups();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.recipientGroups !== this.props.recipientGroups && this.props.recipientGroups) {
			this.setRecipientGroups();
		}

		if (!prevProps.added && this.props.added) {
			this.props.listLetters('id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push('/letter');
				}, SuccessMessageTimeout);
			});
		}
	}

	loadRecipientGroups = () => {
		const {recipientGroups, listRecipientGroups} = this.props;

		if (recipientGroups && recipientGroups.order === 'name' && recipientGroups.asc) {
			this.setRecipientGroups();
		} else {
			listRecipientGroups('name', true);
		}
	}

	setRecipientGroups = () => {
		const {recipientGroups} = this.props;

		this.setState({
			recipientGroupOptions: [
				{
					label: 'Wybierz grupę odbiorców',
					value: ''
				},
				...recipientGroups.list.map((r) => ({
					label: r.name,
					value: r.id
				}))
			]
		});
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			addLetter,
			loading
		} = this.props;

		const {
			name,
			internalName,
			recipientGroupId,
			coverPath,
			title,
			content,
			gdprAgreement,
			gdprInfo
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		addLetter({
			name,
			internal_name: internalName,
			recipient_group_id: recipientGroupId,
			cover_path: coverPath,
			title,
			content,
			gdpr_agreement: gdprAgreement,
			gdpr_info: gdprInfo
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			name,
			internalName,
			recipientGroupId,
			recipientGroupOptions,
			coverPath,
			title,
			content,
			gdprAgreement,
			gdprInfo
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Nowy list</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							recipientGroupOptions && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<Input
										name="name"
										value={name}
										label="Nazwa listu"
										placeholder="Wpisz nazwę listu"
										handleChange={this.handleChange}
										required
									/>

									<Input
										name="internalName"
										value={internalName}
										label="Wewnętrzna nazwa listu (w odnośniku)"
										placeholder="Wpisz wewnętrzną nazwę listu"
										handleChange={this.handleChange}
										required
									/>

									<Select
										name="recipientGroupId"
										value={recipientGroupId}
										label="Grupa odbiorców"
										options={recipientGroupOptions}
										handleChange={this.handleChange}
										required
									/>

									<File
										name="coverPath"
										value={coverPath}
										label="Zdjęcie w tle (opcjonalne)"
										handleChange={this.handleChange}
									/>

									<Input
										name="title"
										value={title}
										label="Tytuł listu"
										placeholder="Wpisz tytuł listu"
										handleChange={this.handleChange}
										required
									/>

									<Html
										name="content"
										value={content}
										label="Treść listu"
										handleChange={this.handleChange}
									/>

									<Input
										name="gdprAgreement"
										value={gdprAgreement}
										label="Treść zgody RODO (opcjonalne)"
										placeholder="Wpisz treść zgody RODO"
										handleChange={this.handleChange}
									/>

									<p>
										<small>
											Domyślna treść zgody to &quot;Wyrażam zgodę na przetwarzanie danych osobowych podanych w formularzu&quot;, ale może być nadpisana zgodnie z Twoimi preferencjami.
										</small>
									</p>

									<Textarea
										name="gdprInfo"
										value={gdprInfo}
										label="Informacje związane z RODO (opcjonalne)"
										placeholder="Wpisz informacje związane z RODO"
										handleChange={this.handleChange}
									/>

									<Button variant="outline-primary" type="submit">
										Utwórz nowy list
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

AddLetter.propTypes = {
	history: PropTypes.object.isRequired,
	listLetters: PropTypes.func.isRequired,
	recipientGroups: PropTypes.object,
	listRecipientGroups: PropTypes.func.isRequired,
	added: PropTypes.bool.isRequired,
	addLetter: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

AddLetter.defaultProps = {
	recipientGroups: null
};

export default AddLetter;
