import React, {Component} from 'react';
import {Button, Form} from 'react-bootstrap';
import PropTypes from 'prop-types';

class File extends Component {
	handleChange = (e) => {
		const {handleChange, name} = this.props;
		const [file] = e.target.files;

		const fileReader = new window.FileReader();

		fileReader.onload = (ev) => {
			handleChange(name, {
				name: file.name,
				data: ev.target.result
			});
		};

		fileReader.readAsDataURL(file);
	}

	render() {
		const {
			name,
			value,
			label,
			handleChange,
			required
		} = this.props;

		return (
			<Form.Group className="mb-3" controlId={name}>
				{
					label && (
						<Form.Label>{label}</Form.Label>
					)
				}
				{
					value ? (
						<p>
							<Button size="sm" variant="outline-danger" onClick={() => handleChange(name, null)}>
								Usuń plik
							</Button>
							&nbsp;
							<span>Plik <em>{value.name}</em> został poprawnie wczytany.</span>
						</p>
					) : (
						<Form.Control
							name={name}
							type="file"
							onChange={this.handleChange}
							required={required}
						/>
					)
				}
			</Form.Group>
		);
	}
}

File.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.object,
	label: PropTypes.string,
	handleChange: PropTypes.func.isRequired,
	required: PropTypes.bool
};

File.defaultProps = {
	label: null,
	value: null,
	required: false
};

export default File;
