import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';
import shortid from 'shortid';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	FieldGroup,
	Form,
	Input
} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';
import {ItemTypeHookType} from '../../../../util/types';

class EditItem extends Component {
	state = {
		success: false,
		invalid: false,
		initialized: false,
		name: '',
		hooks: []
	}

	componentDidMount() {
		const {itemId} = this.props.match.params;
		const {getItem} = this.props;

		setTitle(['Obiekty', `Obiekt #${itemId}`, 'Edytuj']);

		getItem(itemId);

		return true;
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.item && this.props.item && this.props.item.id === parseInt(this.props.match.params.itemId, 10)) {
			this.setState({
				initialized: true,
				name: this.props.item.name,
				hooks: this.props.item.hooks.map((hook) => ({
					uuid: shortid.generate(),
					...hook
				}))
			});
		}

		if (!prevProps.edited && this.props.edited) {
			this.props.listItems('id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push('/item');
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {itemId} = this.props.match.params;
		const {editItem, loading} = this.props;
		const {
			name,
			hooks
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		editItem(itemId, {
			name,
			hooks
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {itemId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			initialized,
			success,
			invalid,
			name,
			hooks
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Edycja obiektu #{itemId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							initialized && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<Input
										name="name"
										value={name}
										label="Nazwa obiektu"
										placeholder="Wpisz nazwę obiektu"
										handleChange={this.handleChange}
										required
									/>

									<FieldGroup label="Własności obiektu">
										{
											hooks.map((hook, index) => (
												<Row
													key={hook.uuid}
													style={{
														border: '1px solid #d0d0d0',
														borderRadius: '5px',
														margin: '15px',
														padding: '1rem 0'
													}}
												>
													<Col lg={{span: 12}}>
														<p>Właściwość: <strong>{hook.item_type_hook_display_name}</strong> (<em>{hook.item_type_hook_name}</em>)</p>
														{
															hook.item_type_hook_type_name === ItemTypeHookType.TEXT && (
																<Input
																	name={`hooks[${index}].value`}
																	value={hook.value}
																	placeholder={`Wartość dla ${hook.item_type_hook_display_name}`}
																	handleChange={this.handleChange}
																	required
																/>
															)
														}
														{
															hook.item_type_hook_type_name === ItemTypeHookType.INTEGRATION && (
																<>
																	<Input
																		name={`hooks[${index}].value`}
																		value={hook.value}
																		placeholder={`Wartość dla ${hook.item_type_hook_display_name}`}
																		handleChange={this.handleChange}
																		required
																		disabled
																	/>
																	<p><small>To jest własność, która jest połączona z integracją i nie może być edytowana.</small></p>
																</>
															)
														}
													</Col>
												</Row>
											))
										}
									</FieldGroup>

									<Button variant="outline-primary" type="submit">
										Zapisz zmiany
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

EditItem.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	item: PropTypes.object,
	getItem: PropTypes.func.isRequired,
	edited: PropTypes.bool.isRequired,
	editItem: PropTypes.func.isRequired,
	listItems: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

EditItem.defaultProps = {
	item: null
};

export default EditItem;

