import React from 'react';
import {
	BrowserRouter,
	Switch,
	Route,
	Redirect
} from 'react-router-dom';

import Login from './scenes/public/Login';
import ForgotPassword from './scenes/public/ForgotPassword';

import ListMap from './scenes/private/Map/List';
import AddMap from './scenes/private/Map/Add';
import EditMap from './scenes/private/Map/Edit';

import ListCheckpoint from './scenes/private/Checkpoint/List';
import AddCheckpoint from './scenes/private/Checkpoint/Add';
import ImportCheckpoint from './scenes/private/Checkpoint/Import';
import EditCheckpoint from './scenes/private/Checkpoint/Edit';

import ListTimer from './scenes/private/Timer/List';
import AddTimer from './scenes/private/Timer/Add';
import EditTimer from './scenes/private/Timer/Edit';

import ListVoivodeship from './scenes/private/Voivodeship/List';
import AddVoivodeship from './scenes/private/Voivodeship/Add';
import EditVoivodeship from './scenes/private/Voivodeship/Edit';

import ListDistrict from './scenes/private/District/List';
import AddDistrict from './scenes/private/District/Add';
import EditDistrict from './scenes/private/District/Edit';

import ListBorough from './scenes/private/Borough/List';
import AddBorough from './scenes/private/Borough/Add';
import EditBorough from './scenes/private/Borough/Edit';

import ListRank from './scenes/private/Rank/List';
import AddRank from './scenes/private/Rank/Add';
import ImportRank from './scenes/private/Rank/Import';
import EditRank from './scenes/private/Rank/Edit';

import ListRankGroup from './scenes/private/RankGroup/List';
import AddRankGroup from './scenes/private/RankGroup/Add';
import EditRankGroup from './scenes/private/RankGroup/Edit';

import ListRankGroupValue from './scenes/private/RankGroupValue/List';
import AddRankGroupValue from './scenes/private/RankGroupValue/Add';
import ImportRankGroupValue from './scenes/private/RankGroupValue/Import';
import EditRankGroupValue from './scenes/private/RankGroupValue/Edit';

import ListRankEntry from './scenes/private/RankEntry/List';
import AddRankEntry from './scenes/private/RankEntry/Add';
import EditRankEntry from './scenes/private/RankEntry/Edit';

import ListRankEntryValue from './scenes/private/RankEntryValue/List';
import AddRankEntryValue from './scenes/private/RankEntryValue/Add';
import EditRankEntryValue from './scenes/private/RankEntryValue/Edit';

import ListSearch from './scenes/private/Search/List';
import AddSearch from './scenes/private/Search/Add';
import EditSearch from './scenes/private/Search/Edit';

import ListSearchEntry from './scenes/private/SearchEntry/List';
import AddSearchEntry from './scenes/private/SearchEntry/Add';
import ImportSearchEntry from './scenes/private/SearchEntry/Import';
import EditSearchEntry from './scenes/private/SearchEntry/Edit';

import ListAccount from './scenes/private/Account/List';
import AddAccount from './scenes/private/Account/Add';
import ProfileAccount from './scenes/private/Account/Profile';

import ListCalculator from './scenes/private/Calculator/List';
import AddCalculator from './scenes/private/Calculator/Add';
import EditCalculator from './scenes/private/Calculator/Edit';

import SetCalculatorDefinition from './scenes/private/CalculatorDefinition/Set';
import ImportCalculatorDefinition from './scenes/private/CalculatorDefinition/Import';

import ListGraph from './scenes/private/Graph/List';
import AddGraph from './scenes/private/Graph/Add';
import ImportGraph from './scenes/private/Graph/Import';
import EditGraph from './scenes/private/Graph/Edit';

import ListGraphGroup from './scenes/private/GraphGroup/List';
import AddGraphGroup from './scenes/private/GraphGroup/Add';
import EditGraphGroup from './scenes/private/GraphGroup/Edit';

import ListGraphEntry from './scenes/private/GraphEntry/List';
import AddGraphEntry from './scenes/private/GraphEntry/Add';
import EditGraphEntry from './scenes/private/GraphEntry/Edit';

import ListLetter from './scenes/private/Letter/List';
import AddLetter from './scenes/private/Letter/Add';
import EditLetter from './scenes/private/Letter/Edit';

import ListRecipientGroup from './scenes/private/RecipientGroup/List';
import AddRecipientGroup from './scenes/private/RecipientGroup/Add';
import EditRecipientGroup from './scenes/private/RecipientGroup/Edit';

import ListRecipient from './scenes/private/Recipient/List';
import AddRecipient from './scenes/private/Recipient/Add';
import ImportRecipient from './scenes/private/Recipient/Import';
import EditRecipient from './scenes/private/Recipient/Edit';

import ListItem from './scenes/private/Item/List';
import AddItem from './scenes/private/Item/Add';
import EditItem from './scenes/private/Item/Edit';

const App = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path="/" component={Login} />
			<Route exact path="/forgot-password" component={ForgotPassword} />

			<Route exact path="/voivodeship" component={ListVoivodeship} />
			<Route exact path="/voivodeship/add" component={AddVoivodeship} />
			<Route exact path="/voivodeship/:voivodeshipId(\d+)" component={EditVoivodeship} />

			<Route exact path="/voivodeship/:voivodeshipId(\d+)/district" component={ListDistrict} />
			<Route exact path="/voivodeship/:voivodeshipId(\d+)/district/add" component={AddDistrict} />
			<Route exact path="/voivodeship/:voivodeshipId(\d+)/district/:districtId(\d+)" component={EditDistrict} />

			<Route exact path="/voivodeship/:voivodeshipId(\d+)/district/:districtId(\d+)/borough" component={ListBorough} />
			<Route exact path="/voivodeship/:voivodeshipId(\d+)/district/:districtId(\d+)/borough/add" component={AddBorough} />
			<Route exact path="/voivodeship/:voivodeshipId(\d+)/district/:districtId(\d+)/borough/:boroughId(\d+)" component={EditBorough} />

			<Route exact path="/map" component={ListMap} />
			<Route exact path="/map/add" component={AddMap} />
			<Route exact path="/map/:mapId(\d+)" component={EditMap} />

			<Route exact path="/map/:mapId(\d+)/checkpoint" component={ListCheckpoint} />
			<Route exact path="/map/:mapId(\d+)/checkpoint/add" component={AddCheckpoint} />
			<Route exact path="/map/:mapId(\d+)/checkpoint/import" component={ImportCheckpoint} />
			<Route exact path="/map/:mapId(\d+)/checkpoint/:checkpointId(\d+)" component={EditCheckpoint} />

			<Route exact path="/timer" component={ListTimer} />
			<Route exact path="/timer/add" component={AddTimer} />
			<Route exact path="/timer/:timerId(\d+)" component={EditTimer} />

			<Route exact path="/rank" component={ListRank} />
			<Route exact path="/rank/add" component={AddRank} />
			<Route exact path="/rank/:rankId(\d+)" component={EditRank} />

			<Route exact path="/rank/:rankId(\d+)/group" component={ListRankGroup} />
			<Route exact path="/rank/:rankId(\d+)/group/add" component={AddRankGroup} />
			<Route exact path="/rank/:rankId(\d+)/import" component={ImportRank} />
			<Route exact path="/rank/:rankId(\d+)/group/:rankGroupId(\d+)" component={EditRankGroup} />

			<Route exact path="/rank/:rankId(\d+)/group/:rankGroupId(\d+)/value" component={ListRankGroupValue} />
			<Route exact path="/rank/:rankId(\d+)/group/:rankGroupId(\d+)/value/add" component={AddRankGroupValue} />
			<Route exact path="/rank/:rankId(\d+)/group/:rankGroupId(\d+)/value/import" component={ImportRankGroupValue} />
			<Route exact path="/rank/:rankId(\d+)/group/:rankGroupId(\d+)/value/:rankValueId(\d+)" component={EditRankGroupValue} />

			<Route exact path="/rank/:rankId(\d+)/group/:rankGroupId(\d+)/entry" component={ListRankEntry} />
			<Route exact path="/rank/:rankId(\d+)/group/:rankGroupId(\d+)/entry/add" component={AddRankEntry} />
			<Route exact path="/rank/:rankId(\d+)/group/:rankGroupId(\d+)/entry/:rankEntryId(\d+)" component={EditRankEntry} />

			<Route exact path="/rank/:rankId(\d+)/group/:rankGroupId(\d+)/entry/:rankEntryId(\d+)/value" component={ListRankEntryValue} />
			<Route exact path="/rank/:rankId(\d+)/group/:rankGroupId(\d+)/entry/:rankEntryId(\d+)/value/add" component={AddRankEntryValue} />
			<Route exact path="/rank/:rankId(\d+)/group/:rankGroupId(\d+)/entry/:rankEntryId(\d+)/value/:rankValueId(\d+)" component={EditRankEntryValue} />

			<Route exact path="/search" component={ListSearch} />
			<Route exact path="/search/add" component={AddSearch} />
			<Route exact path="/search/:searchId(\d+)" component={EditSearch} />

			<Route exact path="/search/:searchId(\d+)/entry" component={ListSearchEntry} />
			<Route exact path="/search/:searchId(\d+)/entry/add" component={AddSearchEntry} />
			<Route exact path="/search/:searchId(\d+)/entry/import" component={ImportSearchEntry} />
			<Route exact path="/search/:searchId(\d+)/entry/:searchEntryId(\d+)" component={EditSearchEntry} />

			<Route exact path="/calculator" component={ListCalculator} />
			<Route exact path="/calculator/add" component={AddCalculator} />
			<Route exact path="/calculator/:calculatorId(\d+)" component={EditCalculator} />

			<Route exact path="/calculator/definition/set" component={SetCalculatorDefinition} />
			<Route exact path="/calculator/definition/import" component={ImportCalculatorDefinition} />

			<Route exact path="/graph" component={ListGraph} />
			<Route exact path="/graph/add" component={AddGraph} />
			<Route exact path="/graph/:graphId(\d+)" component={EditGraph} />
			<Route exact path="/graph/:graphId(\d+)/import" component={ImportGraph} />

			<Route exact path="/graph/:graphId(\d+)/group" component={ListGraphGroup} />
			<Route exact path="/graph/:graphId(\d+)/group/add" component={AddGraphGroup} />
			<Route exact path="/graph/:graphId(\d+)/group/:graphGroupId(\d+)" component={EditGraphGroup} />

			<Route exact path="/graph/:graphId(\d+)/group/:graphGroupId(\d+)/entry" component={ListGraphEntry} />
			<Route exact path="/graph/:graphId(\d+)/group/:graphGroupId(\d+)/entry/add" component={AddGraphEntry} />
			<Route exact path="/graph/:graphId(\d+)/group/:graphGroupId(\d+)/entry/:graphEntryId(\d+)" component={EditGraphEntry} />

			<Route exact path="/letter" component={ListLetter} />
			<Route exact path="/letter/add" component={AddLetter} />
			<Route exact path="/letter/:letterId(\d+)" component={EditLetter} />

			<Route exact path="/letter/recipient_group" component={ListRecipientGroup} />
			<Route exact path="/letter/recipient_group/add" component={AddRecipientGroup} />
			<Route exact path="/letter/recipient_group/:recipientGroupId(\d+)" component={EditRecipientGroup} />

			<Route exact path="/letter/recipient_group/:recipientGroupId(\d+)/recipient" component={ListRecipient} />
			<Route exact path="/letter/recipient_group/:recipientGroupId(\d+)/recipient/add" component={AddRecipient} />
			<Route exact path="/letter/recipient_group/:recipientGroupId(\d+)/recipient/import" component={ImportRecipient} />
			<Route exact path="/letter/recipient_group/:recipientGroupId(\d+)/recipient/:recipientId(\d+)" component={EditRecipient} />

			<Route exact path="/item" component={ListItem} />
			<Route exact path="/item/add" component={AddItem} />
			<Route exact path="/item/:itemId(\d+)" component={EditItem} />

			<Route exact path="/account" component={ListAccount} />
			<Route exact path="/user/add" component={AddAccount} />
			<Route exact path="/user/profile" component={ProfileAccount} />
			<Redirect to="/" />
		</Switch>
	</BrowserRouter>
);

export default App;
