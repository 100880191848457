import {connect} from 'react-redux';

import {editRankGroup, getRankGroup, listRankGroups} from '../../../../actions/rankGroup';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.rankGroup.item,
	edited: state.rankGroup.edited,
	loading: state.rankGroup.status.loading,
	error: state.rankGroup.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRankGroups: (rankId, order, asc) => dispatch(listRankGroups(rankId, order, asc)),
	getRankGroup: (id) => dispatch(getRankGroup(id)),
	editRankGroup: (id, data) => dispatch(editRankGroup(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
