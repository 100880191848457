import React, {Component} from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	FieldGroup,
	Form,
	Input
} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class SetCalculatorDefinition extends Component {
	state = {
		initialized: false,
		success: false,
		invalid: false,
		types: null
	}

	componentDidMount() {
		const {list, getCalculatorDefinition} = this.props;

		setTitle(['Kalkulatory', 'Ustaw definicję']);

		if (list) {
			this.setCalculatorDefinition();
		} else {
			getCalculatorDefinition();
		}

		return true;
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.list && this.props.list) {
			this.setCalculatorDefinition();
		}

		if (!prevProps.set && this.props.set) {
			this.props.getCalculatorDefinition();

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push('/calculator');
				}, SuccessMessageTimeout);
			});
		}
	}

	setCalculatorDefinition = () => {
		const {list} = this.props;

		this.setState({
			initialized: true,
			types: list.map((t) => ({
				uuid: shortid.generate(),
				id: t.id,
				name: t.name,
				definitions: t.definitions.map((d) => ({
					uuid: shortid.generate(),
					id: d.id,
					name: d.name,
					value: d.value
				}))
			}))
		});
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			setCalculatorDefinition,
			loading
		} = this.props;

		const {types} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		setCalculatorDefinition({types});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {loading, error} = this.props;
		const {
			initialized,
			success,
			invalid,
			types
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Ustaw definicję kalkulatora</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							initialized && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<FieldGroup label="Definicja dostępna dla kalkulatora">
										{
											types.map((type, index) => (
												<Row
													key={type.uuid}
													style={{
														border: '1px solid #d0d0d0',
														borderRadius: '5px',
														margin: '15px',
														padding: '1rem 0'
													}}
												>
													<Col lg={{span: 12}}>
														Typ kalkulatora: <strong>{type.name}</strong>
														<hr />
													</Col>
													{
														type.definitions.map((definition, j) => (
															<Col key={definition.uuid} lg={{span: 12}}>
																<Input
																	name={`types[${index}].definitions[${j}].value`}
																	type="number"
																	value={definition.value}
																	placeholder={types[index].definitions[j].name.replace(/_/g, ' ')}
																	label={types[index].definitions[j].name.replace(/_/g, ' ')}
																	handleChange={this.handleChange}
																	required
																	step="any"
																/>
															</Col>
														))
													}
												</Row>
											))
										}
									</FieldGroup>

									<Button variant="outline-primary" type="submit">
										Zaktualizuj definicję kalkulatora
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

SetCalculatorDefinition.propTypes = {
	history: PropTypes.object.isRequired,
	list: PropTypes.object,
	getCalculatorDefinition: PropTypes.func.isRequired,
	set: PropTypes.bool.isRequired,
	setCalculatorDefinition: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

SetCalculatorDefinition.defaultProps = {
	list: null
};

export default SetCalculatorDefinition;
