import {connect} from 'react-redux';

import {deleteGraph, listGraphs} from '../../../../actions/graph';
import List from './List';

const mapStateToProps = (state) => ({
	list: state.graph.list,
	deleted: state.graph.deleted,
	loading: state.graph.status.loading,
	error: state.graph.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listGraphs: (order, asc) => dispatch(listGraphs(order, asc)),
	deleteGraph: (id) => dispatch(deleteGraph(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
