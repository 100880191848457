import React from 'react';
import {Form} from 'react-bootstrap';
import PropTypes from 'prop-types';

const Checkbox = (props) => {
	const {
		name,
		value,
		type,
		label,
		fieldLabel,
		handleChange
	} = props;

	return (
		<Form.Group className="mb-3" controlId={name}>
			{
				label && (
					<Form.Label>{label}</Form.Label>
				)
			}
			<Form.Check defaultChecked={value} name={name} type={type} label={fieldLabel} onChange={(e) => handleChange(name, e.target.checked)} />
		</Form.Group>
	);
};

Checkbox.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.bool.isRequired,
	type: PropTypes.oneOf(['radio', 'checkbox']),
	label: PropTypes.string,
	fieldLabel: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired
};

Checkbox.defaultProps = {
	type: 'checkbox',
	label: null
};

export default Checkbox;
