import axios from 'axios';

import {Map} from '../util/actionTypes';

export const listMaps = (order, asc) => (dispatch) => {
	dispatch({
		type: Map.LIST.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/map/?order=${order}&asc=${asc}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Map.LIST.SUCCESS,
					data: {
						order,
						asc,
						list: res.message
					}
				});
			} else {
				dispatch({
					type: Map.LIST.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Map.LIST.FAILURE
			});
		});
};

export const getMap = (id) => (dispatch) => {
	dispatch({
		type: Map.GET.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/map/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Map.GET.SUCCESS,
					data: res.message
				});
			} else {
				dispatch({
					type: Map.GET.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Map.GET.FAILURE
			});
		});
};

export const addMap = (data) => (dispatch) => {
	dispatch({
		type: Map.ADD.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/map/`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Map.ADD.SUCCESS
				});
			} else {
				dispatch({
					type: Map.ADD.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Map.ADD.FAILURE
			});
		});
};

export const editMap = (id, data) => (dispatch) => {
	dispatch({
		type: Map.EDIT.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/map/${id}`, data)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Map.EDIT.SUCCESS
				});
			} else {
				dispatch({
					type: Map.EDIT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Map.EDIT.FAILURE
			});
		});
};

export const exportMap = (id) => (dispatch) => {
	dispatch({
		type: Map.EXPORT.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/map/${id}/export`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Map.EXPORT.SUCCESS
				});

				window.location.href = res.message;
			} else {
				dispatch({
					type: Map.EXPORT.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Map.EXPORT.FAILURE
			});
		});
};

export const deleteMap = (id) => (dispatch) => {
	dispatch({
		type: Map.DELETE.REQUEST
	});

	axios.delete(`${process.env.REACT_APP_API_URL}/map/${id}`)
		.then((res) => {
			if (res.success) {
				dispatch({
					type: Map.DELETE.SUCCESS
				});
			} else {
				dispatch({
					type: Map.DELETE.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: Map.DELETE.FAILURE
			});
		});
};
