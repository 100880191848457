import {connect} from 'react-redux';

import {editSearchEntry, getSearchEntry, listSearchEntries} from '../../../../actions/searchEntry';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.searchEntry.item,
	edited: state.searchEntry.edited,
	loading: state.searchEntry.status.loading,
	error: state.searchEntry.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listSearchEntries: (searchId, order, asc) => dispatch(listSearchEntries(searchId, order, asc)),
	getSearchEntry: (id) => dispatch(getSearchEntry(id)),
	editSearchEntry: (id, data) => dispatch(editSearchEntry(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
