import {connect} from 'react-redux';

import {editSearch, getSearch, listSearches} from '../../../../actions/search';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.search.item,
	edited: state.search.edited,
	loading: state.search.status.loading,
	error: state.search.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listSearches: (order, asc) => dispatch(listSearches(order, asc)),
	getSearch: (id) => dispatch(getSearch(id)),
	editSearch: (id, data) => dispatch(editSearch(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
