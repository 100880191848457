import {connect} from 'react-redux';

import {addGraphGroup, listGraphGroups} from '../../../../actions/graphGroup';
import Add from './Add';

const mapStateToProps = (state) => ({
	added: state.graphGroup.added,
	loading: state.graphGroup.status.loading,
	error: state.graphGroup.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listGraphGroups: (graphId, order, asc) => dispatch(listGraphGroups(graphId, order, asc)),
	addGraphGroup: (data) => dispatch(addGraphGroup(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
