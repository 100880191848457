import {connect} from 'react-redux';

import {addRank, listRanks} from '../../../../actions/rank';
import Add from './Add';

const mapStateToProps = (state) => ({
	added: state.rank.added,
	loading: state.rank.status.loading,
	error: state.rank.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRanks: (order, asc) => dispatch(listRanks(order, asc)),
	addRank: (data) => dispatch(addRank(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
