import React, {Component} from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	Checkbox,
	FieldGroup,
	Form,
	Input
} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';
import {GraphType} from '../../../../util/types';

class AddGraphEntry extends Component {
	state = {
		success: false,
		invalid: false,
		name: '',
		color: '',
		withGoal: false,
		goal: '',
		goalProgressInfo: '',
		goalValueInfo: '',
		goalValueSubinfo: '',
		values: null,
		graphType: null
	}

	componentDidMount() {
		const {graphId, graphGroupId} = this.props.match.params;
		const {getGraph} = this.props;

		setTitle(['Wykresy', `Wykres #${graphId}`, 'Grupy', `Grupa #${graphGroupId}`, 'Pozycje', 'Dodaj nową pozycję']);

		getGraph(graphId);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.graph && this.props.graph && this.props.graph.id === parseInt(this.props.match.params.graphId, 10)) {
			this.setState({
				graphType: this.props.graph.graph_type_name,
				values: [GraphType.BAR, GraphType.LINE].includes(this.props.graph.graph_type_name) ? (
					this.props.graph.graph_key_groups.flatMap(((graphKeyGroup) => graphKeyGroup.graph_keys.map((graphKey) => ({
						uuid: shortid.generate(),
						key: {
							id: graphKey.id,
							name: graphKey.name,
							short_name: graphKey.short_name
						},
						group: {
							id: graphKeyGroup.id,
							name: graphKeyGroup.name,
							color: graphKeyGroup.color
						},
						value: {
							id: null,
							value: ''
						}
					}))))
				) : (
					this.props.graph.graph_keys.map((graphKey) => ({
						uuid: shortid.generate(),
						key: {
							id: graphKey.id,
							name: graphKey.name,
							short_name: graphKey.short_name
						},
						value: {
							id: null,
							value: ''
						}
					}))
				)
			});
		}

		if (!prevProps.added && this.props.added) {
			this.props.listGraphEntries(this.props.match.params.graphGroupId, 'id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/graph/${this.props.match.params.graphId}/group/${this.props.match.params.graphGroupId}/entry`);
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {graphGroupId} = this.props.match.params;
		const {addGraphEntry, loading} = this.props;
		const {
			name,
			color,
			withGoal,
			goal,
			goalProgressInfo,
			goalValueInfo,
			goalValueSubinfo,
			values
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		addGraphEntry({
			name,
			color,
			with_goal: withGoal,
			goal,
			goal_progress_info: goalProgressInfo,
			goal_value_info: goalValueInfo,
			goal_value_subinfo: goalValueSubinfo,
			values,
			graph_group_id: graphGroupId
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {graphId, graphGroupId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			name,
			color,
			withGoal,
			goal,
			goalProgressInfo,
			goalValueInfo,
			goalValueSubinfo,
			values,
			graphType
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Nowa pozycja wykresie #{graphId} w grupie #{graphGroupId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							values && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<Input
										name="name"
										value={name}
										label="Nazwa"
										placeholder="Wpisz nazwę pozycji"
										handleChange={this.handleChange}
										required
									/>

									{
										graphType === GraphType.MULTILINE && (
											<>
												<Input
													name="color"
													value={color}
													label="Kolor pozycji"
													placeholder="Wpisz kolor pozycji"
													handleChange={this.handleChange}
													required
												/>
												<p>
													<small>Dopuszczalne są kolory w języku angielskim oraz <em>rgb</em>, <em>rgba</em>, <em>hex</em>.</small>
												</p>
											</>
										)
									}

									{
										graphType === GraphType.BAR && (
											<>
												<Checkbox
													name="withGoal"
													value={withGoal}
													handleChange={this.handleChange}
													label="Cel pozycji"
													fieldLabel="Ustaw cel, do którego dążą wartości w tej pozycji"
												/>

												{
													withGoal && (
														<>
															<Input
																name="goal"
																value={goal}
																label="Wartość celu"
																type="number"
																placeholder="Wpisz wartość celu"
																handleChange={this.handleChange}
																required
																min="1"
															/>
															<Input
																name="goalProgressInfo"
																value={goalProgressInfo}
																label="Podpis dla postępu celu"
																type="text"
																placeholder="Wpisz podpis dla postępu celu"
																handleChange={this.handleChange}
																required
															/>
															<Input
																name="goalValueInfo"
																value={goalValueInfo}
																label="Podpis dla wartości celu"
																type="text"
																placeholder="Wpisz podpis dla wartości celu"
																handleChange={this.handleChange}
																required
															/>
															<Input
																name="goalValueSubinfo"
																value={goalValueSubinfo}
																label="Dodatkowy opis dla wartości celu"
																type="text"
																placeholder="Wpisz dodatkowy opis dla wartości celu"
																handleChange={this.handleChange}
																required
															/>
														</>
													)
												}
											</>
										)
									}

									<FieldGroup label="Własności wykresu dla pozycji">
										{
											values.map((value, index) => (
												<Row
													key={value.uuid}
													style={{
														border: '1px solid #d0d0d0',
														borderRadius: '5px',
														margin: '15px',
														padding: '1rem 0'
													}}
												>
													<Col lg={{span: 12}}>
														Własność{value.group && <> z grupy <em>{value.group.name}</em></>}: <strong>{value.key.name}</strong> (<em>{value.key.short_name}</em>)
														<hr />
													</Col>
													<Col lg={{span: 12}}>
														<Input
															name={`values[${index}].value.value`}
															type="number"
															min={0}
															value={value.value.value}
															placeholder={value.key.name}
															handleChange={this.handleChange}
															step="any"
														/>
													</Col>
												</Row>
											))
										}
									</FieldGroup>

									<Button variant="outline-primary" type="submit">
										Utwórz nową pozycję wykresu
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

AddGraphEntry.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	listGraphEntries: PropTypes.func.isRequired,
	graph: PropTypes.object,
	getGraph: PropTypes.func.isRequired,
	added: PropTypes.bool.isRequired,
	addGraphEntry: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

AddGraphEntry.defaultProps = {
	graph: null
};

export default AddGraphEntry;
