import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	Form,
	Input,
	File,
	Select, Checkbox
} from '../../../../components/Form';
import {TimerStyle} from '../../../../util/types';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class AddTimer extends Component {
	state = {
		success: false,
		invalid: false,
		name: '',
		internalName: '',
		style: '',
		textAbove: '',
		deadline: '',
		textBelow: '',
		logoPath: null,
		hiddenBrand: false
	}

	componentDidMount() {
		setTitle(['Liczniki', 'Dodaj nowy licznik']);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.added && this.props.added) {
			this.props.listTimers('id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push('/timer');
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {
			addTimer,
			loading
		} = this.props;

		const {
			name,
			internalName,
			style,
			textAbove,
			deadline,
			textBelow,
			logoPath,
			hiddenBrand
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		addTimer({
			name,
			internal_name: internalName,
			timer_style_name: style,
			text_above: textAbove,
			deadline,
			text_below: textBelow,
			logo_path: logoPath,
			hidden_brand: hiddenBrand
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			name,
			internalName,
			style,
			textAbove,
			deadline,
			textBelow,
			logoPath,
			hiddenBrand
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Nowy licznik</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
							<Input
								name="name"
								value={name}
								label="Nazwa licznika"
								placeholder="Wpisz nazwę licznika"
								handleChange={this.handleChange}
								required
							/>

							<Input
								name="internalName"
								value={internalName}
								label="Wewnętrzna nazwa licznika (w odnośniku)"
								placeholder="Wpisz wewnętrzną nazwę licznika"
								handleChange={this.handleChange}
								required
							/>

							<Select
								name="style"
								value={style}
								label="Styl licznika"
								options={[
									{
										label: 'Wybierz styl',
										value: ''
									},
									{
										label: 'Jasny',
										value: TimerStyle.LIGHT
									},
									{
										label: 'Ciemny',
										value: TimerStyle.DARK
									}
								]}
								handleChange={this.handleChange}
								required
							/>

							<Input
								name="textAbove"
								value={textAbove}
								label="Tekst nad licznikiem"
								placeholder="Wpisz tekst nad licznikiem"
								handleChange={this.handleChange}
								required
							/>

							<Input
								name="deadline"
								value={deadline}
								type="date"
								label="Data docelowa licznika"
								placeholder="Wpisz datę docelową licznika"
								handleChange={this.handleChange}
								required
							/>

							<Input
								name="textBelow"
								value={textBelow}
								label="Tekst pod licznikiem"
								placeholder="Wpisz tekst pod licznikiem"
								handleChange={this.handleChange}
								required
							/>

							<File
								name="logoPath"
								value={logoPath}
								label="Logo partnera licznika (opcjonalne)"
								handleChange={this.handleChange}
							/>

							<Checkbox
								name="hiddenBrand"
								value={hiddenBrand}
								handleChange={this.handleChange}
								label="Logotyp Polskiego Alarmu Smogowego"
								fieldLabel="Ukryj logotyp Polskiego Alarmu Smogowego na tym liczniku"
							/>

							<Button variant="outline-primary" type="submit">
								Utwórz nowy licznik
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

AddTimer.propTypes = {
	history: PropTypes.object.isRequired,
	listTimers: PropTypes.func.isRequired,
	added: PropTypes.bool.isRequired,
	addTimer: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

export default AddTimer;
