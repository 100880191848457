import {User} from '../util/actionTypes';
import {Status} from '../util/default';

const initialState = {
	status: Status.SUCCESS,
	me: null,
	savedMe: false,
	added: false,
	deleted: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case User.GET_ME.REQUEST:
			return {
				...state,
				status: Status.REQUEST
			};
		case User.GET_ME.SUCCESS:
			return {
				...state,
				me: action.data,
				status: Status.SUCCESS
			};
		case User.GET_ME.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case User.SAVE_ME.REQUEST:
			return {
				...state,
				savedMe: false,
				status: Status.REQUEST
			};
		case User.SAVE_ME.SUCCESS:
			return {
				...state,
				savedMe: true,
				status: Status.SUCCESS
			};
		case User.SAVE_ME.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case User.LIST.REQUEST:
			return {
				...state,
				status: Status.REQUEST
			};
		case User.LIST.SUCCESS:
			return {
				...state,
				list: action.data,
				status: Status.SUCCESS
			};
		case User.LIST.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case User.ADD.REQUEST:
			return {
				...state,
				added: false,
				status: Status.REQUEST
			};
		case User.ADD.SUCCESS:
			return {
				...state,
				added: true,
				status: Status.SUCCESS
			};
		case User.ADD.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		case User.DELETE.REQUEST:
			return {
				...state,
				deleted: false,
				status: Status.REQUEST
			};
		case User.DELETE.SUCCESS:
			return {
				...state,
				deleted: true,
				status: Status.SUCCESS
			};
		case User.DELETE.FAILURE:
			return {
				...state,
				status: Status.FAILURE
			};
		default:
			return state;
	}
};
