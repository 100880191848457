import {connect} from 'react-redux';

import {deleteMap, exportMap, listMaps} from '../../../../actions/map';
import List from './List';

const mapStateToProps = (state) => ({
	list: state.map.list,
	exported: state.map.exported,
	deleted: state.map.deleted,
	loading: state.map.status.loading,
	error: state.map.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listMaps: (order, asc) => dispatch(listMaps(order, asc)),
	exportMap: (id) => dispatch(exportMap(id)),
	deleteMap: (id) => dispatch(deleteMap(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
