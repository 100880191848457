export const Auth = {
	LOGIN: {
		REQUEST: 'AUTH_LOGIN_REQUEST',
		SUCCESS: 'AUTH_LOGIN_SUCCESS',
		FAILURE: 'AUTH_LOGIN_FAILURE'
	},
	RESET_PASSWORD: {
		REQUEST: 'AUTH_RESET_PASSWORD_REQUEST',
		SUCCESS: 'AUTH_RESET_PASSWORD_SUCCESS',
		FAILURE: 'AUTH_RESET_PASSWORD_FAILURE'
	}
};

export const User = {
	GET_ME: {
		REQUEST: 'USER_GET_ME_REQUEST',
		SUCCESS: 'USER_GET_ME_SUCCESS',
		FAILURE: 'USER_GET_ME_FAILURE'
	},
	SAVE_ME: {
		REQUEST: 'USER_SAVE_ME_REQUEST',
		SUCCESS: 'USER_SAVE_ME_SUCCESS',
		FAILURE: 'USER_SAVE_ME_FAILURE'
	},
	LIST: {
		REQUEST: 'USER_LIST_REQUEST',
		SUCCESS: 'USER_LIST_SUCCESS',
		FAILURE: 'USER_LIST_FAILURE'
	},
	ADD: {
		REQUEST: 'USER_ADD_REQUEST',
		SUCCESS: 'USER_ADD_SUCCESS',
		FAILURE: 'USER_ADD_FAILURE'
	},
	DELETE: {
		REQUEST: 'USER_DELETE_REQUEST',
		SUCCESS: 'USER_DELETE_SUCCESS',
		FAILURE: 'USER_DELETE_FAILURE'
	}
};

export const Voivodeship = {
	LIST: {
		REQUEST: 'VOIVODESHIP_LIST_REQUEST',
		SUCCESS: 'VOIVODESHIP_LIST_SUCCESS',
		FAILURE: 'VOIVODESHIP_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'VOIVODESHIP_GET_REQUEST',
		SUCCESS: 'VOIVODESHIP_GET_SUCCESS',
		FAILURE: 'VOIVODESHIP_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'VOIVODESHIP_ADD_REQUEST',
		SUCCESS: 'VOIVODESHIP_ADD_SUCCESS',
		FAILURE: 'VOIVODESHIP_ADD_FAILURE'
	},
	EDIT: {
		REQUEST: 'VOIVODESHIP_EDIT_REQUEST',
		SUCCESS: 'VOIVODESHIP_EDIT_SUCCESS',
		FAILURE: 'VOIVODESHIP_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'VOIVODESHIP_DELETE_REQUEST',
		SUCCESS: 'VOIVODESHIP_DELETE_SUCCESS',
		FAILURE: 'VOIVODESHIP_DELETE_FAILURE'
	}
};

export const District = {
	LIST: {
		REQUEST: 'DISTRICT_LIST_REQUEST',
		SUCCESS: 'DISTRICT_LIST_SUCCESS',
		FAILURE: 'DISTRICT_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'DISTRICT_GET_REQUEST',
		SUCCESS: 'DISTRICT_GET_SUCCESS',
		FAILURE: 'DISTRICT_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'DISTRICT_ADD_REQUEST',
		SUCCESS: 'DISTRICT_ADD_SUCCESS',
		FAILURE: 'DISTRICT_ADD_FAILURE'
	},
	EDIT: {
		REQUEST: 'DISTRICT_EDIT_REQUEST',
		SUCCESS: 'DISTRICT_EDIT_SUCCESS',
		FAILURE: 'DISTRICT_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'DISTRICT_DELETE_REQUEST',
		SUCCESS: 'DISTRICT_DELETE_SUCCESS',
		FAILURE: 'DISTRICT_DELETE_FAILURE'
	}
};

export const Borough = {
	LIST: {
		REQUEST: 'BOROUGH_LIST_REQUEST',
		SUCCESS: 'BOROUGH_LIST_SUCCESS',
		FAILURE: 'BOROUGH_LIST_FAILURE'
	},
	LIST_ALL: {
		REQUEST: 'BOROUGH_LIST_ALL_REQUEST',
		SUCCESS: 'BOROUGH_LIST_ALL_SUCCESS',
		FAILURE: 'BOROUGH_LIST_ALL_FAILURE'
	},
	GET: {
		REQUEST: 'BOROUGH_GET_REQUEST',
		SUCCESS: 'BOROUGH_GET_SUCCESS',
		FAILURE: 'BOROUGH_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'BOROUGH_ADD_REQUEST',
		SUCCESS: 'BOROUGH_ADD_SUCCESS',
		FAILURE: 'BOROUGH_ADD_FAILURE'
	},
	EDIT: {
		REQUEST: 'BOROUGH_EDIT_REQUEST',
		SUCCESS: 'BOROUGH_EDIT_SUCCESS',
		FAILURE: 'BOROUGH_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'BOROUGH_DELETE_REQUEST',
		SUCCESS: 'BOROUGH_DELETE_SUCCESS',
		FAILURE: 'BOROUGH_DELETE_FAILURE'
	}
};

export const Timer = {
	LIST: {
		REQUEST: 'TIMER_LIST_REQUEST',
		SUCCESS: 'TIMER_LIST_SUCCESS',
		FAILURE: 'TIMER_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'TIMER_GET_REQUEST',
		SUCCESS: 'TIMER_GET_SUCCESS',
		FAILURE: 'TIMER_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'TIMER_ADD_REQUEST',
		SUCCESS: 'TIMER_ADD_SUCCESS',
		FAILURE: 'TIMER_ADD_FAILURE'
	},
	EDIT: {
		REQUEST: 'TIMER_EDIT_REQUEST',
		SUCCESS: 'TIMER_EDIT_SUCCESS',
		FAILURE: 'TIMER_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'TIMER_DELETE_REQUEST',
		SUCCESS: 'TIMER_DELETE_SUCCESS',
		FAILURE: 'TIMER_DELETE_FAILURE'
	}
};

export const Search = {
	LIST: {
		REQUEST: 'SEARCH_LIST_REQUEST',
		SUCCESS: 'SEARCH_LIST_SUCCESS',
		FAILURE: 'SEARCH_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'SEARCH_GET_REQUEST',
		SUCCESS: 'SEARCH_GET_SUCCESS',
		FAILURE: 'SEARCH_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'SEARCH_ADD_REQUEST',
		SUCCESS: 'SEARCH_ADD_SUCCESS',
		FAILURE: 'SEARCH_ADD_FAILURE'
	},
	EDIT: {
		REQUEST: 'SEARCH_EDIT_REQUEST',
		SUCCESS: 'SEARCH_EDIT_SUCCESS',
		FAILURE: 'SEARCH_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'SEARCH_DELETE_REQUEST',
		SUCCESS: 'SEARCH_DELETE_SUCCESS',
		FAILURE: 'SEARCH_DELETE_FAILURE'
	}
};

export const SearchEntry = {
	LIST: {
		REQUEST: 'SEARCH_ENTRY_LIST_REQUEST',
		SUCCESS: 'SEARCH_ENTRY_LIST_SUCCESS',
		FAILURE: 'SEARCH_ENTRY_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'SEARCH_ENTRY_GET_REQUEST',
		SUCCESS: 'SEARCH_ENTRY_GET_SUCCESS',
		FAILURE: 'SEARCH_ENTRY_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'SEARCH_ENTRY_ADD_REQUEST',
		SUCCESS: 'SEARCH_ENTRY_ADD_SUCCESS',
		FAILURE: 'SEARCH_ENTRY_ADD_FAILURE'
	},
	IMPORT: {
		REQUEST: 'SEARCH_ENTRY_IMPORT_REQUEST',
		SUCCESS: 'SEARCH_ENTRY_IMPORT_SUCCESS',
		FAILURE: 'SEARCH_ENTRY_IMPORT_FAILURE'
	},
	EDIT: {
		REQUEST: 'SEARCH_ENTRY_EDIT_REQUEST',
		SUCCESS: 'SEARCH_ENTRY_EDIT_SUCCESS',
		FAILURE: 'SEARCH_ENTRY_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'SEARCH_ENTRY_DELETE_REQUEST',
		SUCCESS: 'SEARCH_ENTRY_DELETE_SUCCESS',
		FAILURE: 'SEARCH_ENTRY_DELETE_FAILURE'
	}
};

export const Rank = {
	LIST: {
		REQUEST: 'RANK_LIST_REQUEST',
		SUCCESS: 'RANK_LIST_SUCCESS',
		FAILURE: 'RANK_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'RANK_GET_REQUEST',
		SUCCESS: 'RANK_GET_SUCCESS',
		FAILURE: 'RANK_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'RANK_ADD_REQUEST',
		SUCCESS: 'RANK_ADD_SUCCESS',
		FAILURE: 'RANK_ADD_FAILURE'
	},
	IMPORT: {
		REQUEST: 'RANK_IMPORT_REQUEST',
		SUCCESS: 'RANK_IMPORT_SUCCESS',
		FAILURE: 'RANK_IMPORT_FAILURE'
	},
	EDIT: {
		REQUEST: 'RANK_EDIT_REQUEST',
		SUCCESS: 'RANK_EDIT_SUCCESS',
		FAILURE: 'RANK_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'RANK_DELETE_REQUEST',
		SUCCESS: 'RANK_DELETE_SUCCESS',
		FAILURE: 'RANK_DELETE_FAILURE'
	}
};

export const RankGroup = {
	LIST: {
		REQUEST: 'RANK_GROUP_LIST_REQUEST',
		SUCCESS: 'RANK_GROUP_LIST_SUCCESS',
		FAILURE: 'RANK_GROUP_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'RANK_GROUP_GET_REQUEST',
		SUCCESS: 'RANK_GROUP_GET_SUCCESS',
		FAILURE: 'RANK_GROUP_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'RANK_GROUP_ADD_REQUEST',
		SUCCESS: 'RANK_GROUP_ADD_SUCCESS',
		FAILURE: 'RANK_GROUP_ADD_FAILURE'
	},
	EDIT: {
		REQUEST: 'RANK_GROUP_EDIT_REQUEST',
		SUCCESS: 'RANK_GROUP_EDIT_SUCCESS',
		FAILURE: 'RANK_GROUP_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'RANK_GROUP_DELETE_REQUEST',
		SUCCESS: 'RANK_GROUP_DELETE_SUCCESS',
		FAILURE: 'RANK_GROUP_DELETE_FAILURE'
	}
};

export const RankEntry = {
	LIST: {
		REQUEST: 'RANK_GROUP_LIST_REQUEST',
		SUCCESS: 'RANK_GROUP_LIST_SUCCESS',
		FAILURE: 'RANK_GROUP_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'RANK_GROUP_GET_REQUEST',
		SUCCESS: 'RANK_GROUP_GET_SUCCESS',
		FAILURE: 'RANK_GROUP_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'RANK_GROUP_ADD_REQUEST',
		SUCCESS: 'RANK_GROUP_ADD_SUCCESS',
		FAILURE: 'RANK_GROUP_ADD_FAILURE'
	},
	EDIT: {
		REQUEST: 'RANK_GROUP_EDIT_REQUEST',
		SUCCESS: 'RANK_GROUP_EDIT_SUCCESS',
		FAILURE: 'RANK_GROUP_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'RANK_GROUP_DELETE_REQUEST',
		SUCCESS: 'RANK_GROUP_DELETE_SUCCESS',
		FAILURE: 'RANK_GROUP_DELETE_FAILURE'
	}
};

export const RankValue = {
	LIST_GROUP: {
		REQUEST: 'RANK_ENTRY_LIST_GROUP_REQUEST',
		SUCCESS: 'RANK_ENTRY_LIST_GROUP_SUCCESS',
		FAILURE: 'RANK_ENTRY_LIST_GROUP_FAILURE'
	},
	LIST_ENTRY: {
		REQUEST: 'RANK_ENTRY_LIST_ENTRY_REQUEST',
		SUCCESS: 'RANK_ENTRY_LIST_ENTRY_SUCCESS',
		FAILURE: 'RANK_ENTRY_LIST_ENTRY_FAILURE'
	},
	GET: {
		REQUEST: 'RANK_ENTRY_GET_REQUEST',
		SUCCESS: 'RANK_ENTRY_GET_SUCCESS',
		FAILURE: 'RANK_ENTRY_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'RANK_ENTRY_ADD_REQUEST',
		SUCCESS: 'RANK_ENTRY_ADD_SUCCESS',
		FAILURE: 'RANK_ENTRY_ADD_FAILURE'
	},
	IMPORT: {
		REQUEST: 'RANK_ENTRY_IMPORT_REQUEST',
		SUCCESS: 'RANK_ENTRY_IMPORT_SUCCESS',
		FAILURE: 'RANK_ENTRY_IMPORT_FAILURE'
	},
	EDIT: {
		REQUEST: 'RANK_ENTRY_EDIT_REQUEST',
		SUCCESS: 'RANK_ENTRY_EDIT_SUCCESS',
		FAILURE: 'RANK_ENTRY_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'RANK_ENTRY_DELETE_REQUEST',
		SUCCESS: 'RANK_ENTRY_DELETE_SUCCESS',
		FAILURE: 'RANK_ENTRY_DELETE_FAILURE'
	}
};

export const Map = {
	LIST: {
		REQUEST: 'MAP_LIST_REQUEST',
		SUCCESS: 'MAP_LIST_SUCCESS',
		FAILURE: 'MAP_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'MAP_GET_REQUEST',
		SUCCESS: 'MAP_GET_SUCCESS',
		FAILURE: 'MAP_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'MAP_ADD_REQUEST',
		SUCCESS: 'MAP_ADD_SUCCESS',
		FAILURE: 'MAP_ADD_FAILURE'
	},
	EDIT: {
		REQUEST: 'MAP_EDIT_REQUEST',
		SUCCESS: 'MAP_EDIT_SUCCESS',
		FAILURE: 'MAP_EDIT_FAILURE'
	},
	EXPORT: {
		REQUEST: 'MAP_EXPORT_REQUEST',
		SUCCESS: 'MAP_EXPORT_SUCCESS',
		FAILURE: 'MAP_EXPORT_FAILURE'
	},
	DELETE: {
		REQUEST: 'MAP_DELETE_REQUEST',
		SUCCESS: 'MAP_DELETE_SUCCESS',
		FAILURE: 'MAP_DELETE_FAILURE'
	}
};

export const Checkpoint = {
	LIST: {
		REQUEST: 'CHECKPOINT_LIST_REQUEST',
		SUCCESS: 'CHECKPOINT_LIST_SUCCESS',
		FAILURE: 'CHECKPOINT_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'CHECKPOINT_GET_REQUEST',
		SUCCESS: 'CHECKPOINT_GET_SUCCESS',
		FAILURE: 'CHECKPOINT_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'CHECKPOINT_ADD_REQUEST',
		SUCCESS: 'CHECKPOINT_ADD_SUCCESS',
		FAILURE: 'CHECKPOINT_ADD_FAILURE'
	},
	IMPORT: {
		REQUEST: 'CHECKPOINT_IMPORT_REQUEST',
		SUCCESS: 'CHECKPOINT_IMPORT_SUCCESS',
		FAILURE: 'CHECKPOINT_IMPORT_FAILURE'
	},
	EDIT: {
		REQUEST: 'CHECKPOINT_EDIT_REQUEST',
		SUCCESS: 'CHECKPOINT_EDIT_SUCCESS',
		FAILURE: 'CHECKPOINT_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'SEARCH_ENTRY_DELETE_REQUEST',
		SUCCESS: 'SEARCH_ENTRY_DELETE_SUCCESS',
		FAILURE: 'SEARCH_ENTRY_DELETE_FAILURE'
	}
};

export const Calculator = {
	LIST: {
		REQUEST: 'CALCULATOR_LIST_REQUEST',
		SUCCESS: 'CALCULATOR_LIST_SUCCESS',
		FAILURE: 'CALCULATOR_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'CALCULATOR_GET_REQUEST',
		SUCCESS: 'CALCULATOR_GET_SUCCESS',
		FAILURE: 'CALCULATOR_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'CALCULATOR_ADD_REQUEST',
		SUCCESS: 'CALCULATOR_ADD_SUCCESS',
		FAILURE: 'CALCULATOR_ADD_FAILURE'
	},
	EDIT: {
		REQUEST: 'CALCULATOR_EDIT_REQUEST',
		SUCCESS: 'CALCULATOR_EDIT_SUCCESS',
		FAILURE: 'CALCULATOR_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'CALCULATOR_DELETE_REQUEST',
		SUCCESS: 'CALCULATOR_DELETE_SUCCESS',
		FAILURE: 'CALCULATOR_DELETE_FAILURE'
	}
};

export const CalculatorDefinition = {
	GET: {
		REQUEST: 'CALCULATOR_DEFINITION_GET_REQUEST',
		SUCCESS: 'CALCULATOR_DEFINITION_GET_SUCCESS',
		FAILURE: 'CALCULATOR_DEFINITION_GET_FAILURE'
	},
	SET: {
		REQUEST: 'CALCULATOR_DEFINITION_SET_REQUEST',
		SUCCESS: 'CALCULATOR_DEFINITION_SET_SUCCESS',
		FAILURE: 'CALCULATOR_DEFINITION_SET_FAILURE'
	},
	IMPORT: {
		REQUEST: 'CALCULATOR_DEFINITION_IMPORT_REQUEST',
		SUCCESS: 'CALCULATOR_DEFINITION_IMPORT_SUCCESS',
		FAILURE: 'CALCULATOR_DEFINITION_IMPORT_FAILURE'
	}
};

export const Graph = {
	LIST: {
		REQUEST: 'GRAPH_LIST_REQUEST',
		SUCCESS: 'GRAPH_LIST_SUCCESS',
		FAILURE: 'GRAPH_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'GRAPH_GET_REQUEST',
		SUCCESS: 'GRAPH_GET_SUCCESS',
		FAILURE: 'GRAPH_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'GRAPH_ADD_REQUEST',
		SUCCESS: 'GRAPH_ADD_SUCCESS',
		FAILURE: 'GRAPH_ADD_FAILURE'
	},
	IMPORT: {
		REQUEST: 'GRAPH_IMPORT_REQUEST',
		SUCCESS: 'GRAPH_IMPORT_SUCCESS',
		FAILURE: 'GRAPH_IMPORT_FAILURE'
	},
	EDIT: {
		REQUEST: 'GRAPH_EDIT_REQUEST',
		SUCCESS: 'GRAPH_EDIT_SUCCESS',
		FAILURE: 'GRAPH_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'SEARCH_DELETE_REQUEST',
		SUCCESS: 'SEARCH_DELETE_SUCCESS',
		FAILURE: 'SEARCH_DELETE_FAILURE'
	}
};

export const GraphGroup = {
	LIST: {
		REQUEST: 'GRAPH_GROUP_LIST_REQUEST',
		SUCCESS: 'GRAPH_GROUP_LIST_SUCCESS',
		FAILURE: 'GRAPH_GROUP_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'GRAPH_GROUP_GET_REQUEST',
		SUCCESS: 'GRAPH_GROUP_GET_SUCCESS',
		FAILURE: 'GRAPH_GROUP_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'GRAPH_GROUP_ADD_REQUEST',
		SUCCESS: 'GRAPH_GROUP_ADD_SUCCESS',
		FAILURE: 'GRAPH_GROUP_ADD_FAILURE'
	},
	EDIT: {
		REQUEST: 'GRAPH_GROUP_EDIT_REQUEST',
		SUCCESS: 'GRAPH_GROUP_EDIT_SUCCESS',
		FAILURE: 'GRAPH_GROUP_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'GRAPH_GROUP_DELETE_REQUEST',
		SUCCESS: 'GRAPH_GROUP_DELETE_SUCCESS',
		FAILURE: 'GRAPH_GROUP_DELETE_FAILURE'
	}
};

export const GraphEntry = {
	LIST: {
		REQUEST: 'GRAPH_ENTRY_LIST_REQUEST',
		SUCCESS: 'GRAPH_ENTRY_LIST_SUCCESS',
		FAILURE: 'GRAPH_ENTRY_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'GRAPH_ENTRY_GET_REQUEST',
		SUCCESS: 'GRAPH_ENTRY_GET_SUCCESS',
		FAILURE: 'GRAPH_ENTRY_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'GRAPH_ENTRY_ADD_REQUEST',
		SUCCESS: 'GRAPH_ENTRY_ADD_SUCCESS',
		FAILURE: 'GRAPH_ENTRY_ADD_FAILURE'
	},
	EDIT: {
		REQUEST: 'GRAPH_ENTRY_EDIT_REQUEST',
		SUCCESS: 'GRAPH_ENTRY_EDIT_SUCCESS',
		FAILURE: 'GRAPH_ENTRY_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'GRAPH_ENTRY_DELETE_REQUEST',
		SUCCESS: 'GRAPH_ENTRY_DELETE_SUCCESS',
		FAILURE: 'GRAPH_ENTRY_DELETE_FAILURE'
	}
};

export const Letter = {
	LIST: {
		REQUEST: 'LETTER_LIST_REQUEST',
		SUCCESS: 'LETTER_LIST_SUCCESS',
		FAILURE: 'LETTER_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'LETTER_GET_REQUEST',
		SUCCESS: 'LETTER_GET_SUCCESS',
		FAILURE: 'LETTER_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'LETTER_ADD_REQUEST',
		SUCCESS: 'LETTER_ADD_SUCCESS',
		FAILURE: 'LETTER_ADD_FAILURE'
	},
	EDIT: {
		REQUEST: 'LETTER_EDIT_REQUEST',
		SUCCESS: 'LETTER_EDIT_SUCCESS',
		FAILURE: 'LETTER_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'LETTER_DELETE_REQUEST',
		SUCCESS: 'LETTER_DELETE_SUCCESS',
		FAILURE: 'LETTER_DELETE_FAILURE'
	}
};

export const RecipientGroup = {
	LIST: {
		REQUEST: 'RECIPIENT_GROUP_LIST_REQUEST',
		SUCCESS: 'RECIPIENT_GROUP_LIST_SUCCESS',
		FAILURE: 'RECIPIENT_GROUP_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'RECIPIENT_GROUP_GET_REQUEST',
		SUCCESS: 'RECIPIENT_GROUP_GET_SUCCESS',
		FAILURE: 'RECIPIENT_GROUP_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'RECIPIENT_GROUP_ADD_REQUEST',
		SUCCESS: 'RECIPIENT_GROUP_ADD_SUCCESS',
		FAILURE: 'RECIPIENT_GROUP_ADD_FAILURE'
	},
	EDIT: {
		REQUEST: 'RECIPIENT_GROUP_EDIT_REQUEST',
		SUCCESS: 'RECIPIENT_GROUP_EDIT_SUCCESS',
		FAILURE: 'RECIPIENT_GROUP_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'RECIPIENT_GROUP_DELETE_REQUEST',
		SUCCESS: 'RECIPIENT_GROUP_DELETE_SUCCESS',
		FAILURE: 'RECIPIENT_GROUP_DELETE_FAILURE'
	}
};

export const Recipient = {
	LIST: {
		REQUEST: 'RECIPIENT_LIST_REQUEST',
		SUCCESS: 'RECIPIENT_LIST_SUCCESS',
		FAILURE: 'RECIPIENT_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'RECIPIENT_GET_REQUEST',
		SUCCESS: 'RECIPIENT_GET_SUCCESS',
		FAILURE: 'RECIPIENT_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'RECIPIENT_ADD_REQUEST',
		SUCCESS: 'RECIPIENT_ADD_SUCCESS',
		FAILURE: 'RECIPIENT_ADD_FAILURE'
	},
	IMPORT: {
		REQUEST: 'RECIPIENT_IMPORT_REQUEST',
		SUCCESS: 'RECIPIENT_IMPORT_SUCCESS',
		FAILURE: 'RECIPIENT_IMPORT_FAILURE'
	},
	EDIT: {
		REQUEST: 'RECIPIENT_EDIT_REQUEST',
		SUCCESS: 'RECIPIENT_EDIT_SUCCESS',
		FAILURE: 'RECIPIENT_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'RECIPIENT_DELETE_REQUEST',
		SUCCESS: 'RECIPIENT_DELETE_SUCCESS',
		FAILURE: 'RECIPIENT_DELETE_FAILURE'
	}
};

export const Item = {
	LIST: {
		REQUEST: 'ITEM_LIST_REQUEST',
		SUCCESS: 'ITEM_LIST_SUCCESS',
		FAILURE: 'ITEM_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'ITEM_GET_REQUEST',
		SUCCESS: 'ITEM_GET_SUCCESS',
		FAILURE: 'ITEM_GET_FAILURE'
	},
	ADD: {
		REQUEST: 'ITEM_ADD_REQUEST',
		SUCCESS: 'ITEM_ADD_SUCCESS',
		FAILURE: 'ITEM_ADD_FAILURE'
	},
	EDIT: {
		REQUEST: 'ITEM_EDIT_REQUEST',
		SUCCESS: 'ITEM_EDIT_SUCCESS',
		FAILURE: 'ITEM_EDIT_FAILURE'
	},
	DELETE: {
		REQUEST: 'ITEM_DELETE_REQUEST',
		SUCCESS: 'ITEM_DELETE_SUCCESS',
		FAILURE: 'ITEM_DELETE_FAILURE'
	}
};

export const ItemType = {
	LIST: {
		REQUEST: 'ITEM_TYPE_LIST_REQUEST',
		SUCCESS: 'ITEM_TYPE_LIST_SUCCESS',
		FAILURE: 'ITEM_TYPE_LIST_FAILURE'
	},
	GET: {
		REQUEST: 'ITEM_TYPE_GET_REQUEST',
		SUCCESS: 'ITEM_TYPE_GET_SUCCESS',
		FAILURE: 'ITEM_TYPE_GET_FAILURE'
	}
};
