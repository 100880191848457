import {connect} from 'react-redux';

import {addRankValue, listRankEntryValues} from '../../../../actions/rankValue';
import {listVoivodeships} from '../../../../actions/voivodeship';
import {getRank} from '../../../../actions/rank';
import Add from './Add';

const mapStateToProps = (state) => ({
	rank: state.rank.item,
	voivodeships: state.voivodeship.list,
	added: state.rankValue.added,
	loading: state.rankValue.status.loading || state.voivodeship.status.loading || state.rank.status.loading,
	error: state.rankValue.status.error || state.voivodeship.status.error || state.rank.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRankValues: (rankEntryId, order, asc) => dispatch(listRankEntryValues(rankEntryId, order, asc)),
	getRank: (id) => dispatch(getRank(id)),
	listVoivodeships: (order, asc) => dispatch(listVoivodeships(order, asc)),
	addRankValue: (data) => dispatch(addRankValue(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
