import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {Form, Input, Select} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class AddRankGroupValue extends Component {
	state = {
		success: false,
		invalid: false,
		value: '',
		points: '',
		place: '',
		voivodeshipId: '',
		voivodeshipOptions: null,
		rank: null
	}

	componentDidMount() {
		const {rankId, rankGroupId} = this.props.match.params;
		const {getRank} = this.props;

		setTitle(['Rankingi', `Ranking #${rankId}`, 'Grupy', `Grupa #${rankGroupId}`, 'Wartości', 'Dodaj nową wartość']);

		this.loadVoivodeships();
		getRank(rankId);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.voivodeships !== this.props.voivodeships && this.props.voivodeships) {
			this.setVoivodeships();
		}

		if (!prevProps.rank && this.props.rank && this.props.rank.id === parseInt(this.props.match.params.rankId, 10)) {
			this.setState({
				rank: this.props.rank
			});
		}

		if (!prevProps.added && this.props.added) {
			this.props.listRankValues(this.props.match.params.rankEntryId, 'place', true);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/rank/${this.props.match.params.rankId}/group/${this.props.match.params.rankGroupId}/entry/${this.props.match.params.rankEntryId}/value`);
				}, SuccessMessageTimeout);
			});
		}
	}

	loadVoivodeships = () => {
		const {voivodeships, listVoivodeships} = this.props;

		if (voivodeships && voivodeships.order === 'name' && voivodeships.asc) {
			this.setVoivodeships();
		} else {
			listVoivodeships('name', true);
		}
	}

	setVoivodeships = () => {
		const {voivodeships} = this.props;

		this.setState({
			voivodeshipOptions: [
				{
					label: 'Wybierz województwo',
					value: ''
				},
				...voivodeships.list.map((v) => ({
					label: v.name,
					value: v.id
				}))
			]
		});
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {rankEntryId} = this.props.match.params;
		const {addRankValue, loading} = this.props;
		const {
			value,
			points,
			place,
			voivodeshipId
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		addRankValue({
			value,
			points,
			place: place - 1,
			rank_entry_id: rankEntryId,
			voivodeship_id: voivodeshipId
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {rankId, rankGroupId, rankEntryId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			value,
			points,
			place,
			voivodeshipId,
			voivodeshipOptions,
			rank
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Nowa wartość rankingu #{rankId} dla pozycji #{rankEntryId} w grupie #{rankGroupId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							rank && voivodeshipOptions && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<Input
										name="value"
										value={value}
										label="Wartość"
										type="number"
										placeholder="Wpisz wartość"
										handleChange={this.handleChange}
										required
										step="any"
									/>

									<Input
										name="points"
										value={points}
										label="Punkty"
										type="number"
										placeholder="Wpisz punkty"
										handleChange={this.handleChange}
										required
										step="any"
									/>

									{
										rank.lock_place_refresh === 1 && (
											<Input
												name="place"
												value={place}
												label="Miejsce"
												type="number"
												placeholder="Wpisz miejsce w rankingu"
												handleChange={this.handleChange}
												required
											/>
										)
									}

									<Select
										name="voivodeshipId"
										value={voivodeshipId}
										label="Województwo"
										options={voivodeshipOptions}
										handleChange={this.handleChange}
										required
									/>

									<Button variant="outline-primary" type="submit">
										Utwórz nową pozycję rankingu
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

AddRankGroupValue.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	listRankValues: PropTypes.func.isRequired,
	rank: PropTypes.object,
	getRank: PropTypes.func.isRequired,
	voivodeships: PropTypes.object,
	listVoivodeships: PropTypes.func.isRequired,
	added: PropTypes.bool.isRequired,
	addRankValue: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

AddRankGroupValue.defaultProps = {
	rank: null,
	voivodeships: null
};

export default AddRankGroupValue;
