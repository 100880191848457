import {connect} from 'react-redux';

import {editRankEntry, getRankEntry, listRankEntries} from '../../../../actions/rankEntry';
import Edit from './Edit';

const mapStateToProps = (state) => ({
	item: state.rankEntry.item,
	edited: state.rankEntry.edited,
	loading: state.rankEntry.status.loading,
	error: state.rankEntry.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRankEntries: (rankId, order, asc) => dispatch(listRankEntries(rankId, order, asc)),
	getRankEntry: (id) => dispatch(getRankEntry(id)),
	editRankEntry: (id, data) => dispatch(editRankEntry(id, data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Edit);
