import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';

export const setToken = (token) => {
	if (!token) return;

	window.localStorage.setItem('token', token);
	axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const extendToken = () => {
	axios.get(`${process.env.REACT_APP_API_URL}/auth/extend`)
		.then((res) => {
			if (res.success) {
				setToken(res.message.token);
			}
		});

	return false;
};

export const handleChange = (that, name, value) => {
	that.setState((prevState) => {
		const newState = _.assign({}, prevState);
		return _.set(newState, name, value);
	});
};

export const setTitle = (els) => {
	document.title = `${els.join(' - ')} | ${process.env.REACT_APP_NAME} v${process.env.REACT_APP_VERSION}`;
};

export const formatTimestamp = (timestamp) => moment(timestamp).format('YYYY-MM-DD HH:mm');

export const generateUserName = (user) => {
	if (user.firstname && user.lastname) {
		return `${user.firstname} ${user.lastname}`;
	}

	if (user.firstname || user.lastname) {
		return `${user.firstname}${user.lastname}`;
	}

	return `${user.email}`;
};

export const generateIframe = (type, embed) => `<iframe src="${process.env.REACT_APP_API_URL}/view/${type}/${embed.name}/embed/${embed.uuid}" scrolling="no" class="smogalert" id="kas-smogalert-${embed.name}--${embed.uuid}" style="width: 100%; border: 0 none;"></iframe><script>(function () {var kasDefinition = {name: '${embed.name}--${embed.uuid}',iframe: document.getElementById("kas-smogalert-${embed.name}--${embed.uuid}")};function kasEventHandler(e) {if (e.data.name !== kasDefinition.name) {return;}switch (e.data.type) {case 'kas-smogalert':kasDefinition.iframe.style.height = e.data.height + 'px';break;case 'kas-redirect':window.location.href = e.data.target;break;}}window.addEventListener('message', kasEventHandler, false);})();</script>`;
