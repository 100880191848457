import React, {Component} from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	FieldGroup,
	Form,
	Textarea,
	Select
} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class AddSearchEntry extends Component {
	state = {
		success: false,
		invalid: false,
		boroughId: '',
		boroughOptions: null,
		values: null
	}

	componentDidMount() {
		const {searchId} = this.props.match.params;
		const {getSearch} = this.props;

		setTitle(['Wyszukiwarki', `Wyszukiwarka #${searchId}`, 'Pozycje', 'Dodaj nową pozycję']);

		this.loadBoroughs();
		getSearch(searchId);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.boroughs !== this.props.boroughs && this.props.boroughs) {
			this.setBoroughs();
		}

		if (!prevProps.search && this.props.search && this.props.search.id === parseInt(this.props.match.params.searchId, 10)) {
			this.setState({
				values: this.props.search.search_keys.map((searchKey) => ({
					uuid: shortid.generate(),
					key: {
						id: searchKey.id,
						name: searchKey.name
					},
					value: {
						id: null,
						value: ''
					}
				}))
			});
		}

		if (!prevProps.added && this.props.added) {
			this.props.listSearchEntries(this.props.match.params.searchId, 'id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/search/${this.props.match.params.searchId}/entry`);
				}, SuccessMessageTimeout);
			});
		}
	}

	loadBoroughs = () => {
		const {boroughs, listAllBoroughs} = this.props;

		if (boroughs && boroughs.order === 'name' && boroughs.asc) {
			this.setBoroughs();
		} else {
			listAllBoroughs('name', true);
		}
	}

	setBoroughs = () => {
		const {boroughs} = this.props;

		this.setState({
			boroughOptions: [
				{
					label: 'Wybierz gminę',
					value: ''
				},
				...boroughs.list.map((b) => ({
					label: `${b.name}, powiat ${b.district_name}, województwo ${b.voivodeship_name}`,
					value: b.id
				}))
			]
		});
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {searchId} = this.props.match.params;
		const {addSearchEntry, loading} = this.props;
		const {
			boroughId,
			values
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		addSearchEntry({
			search_id: searchId,
			borough_id: boroughId,
			values
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {searchId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			success,
			invalid,
			boroughId,
			boroughOptions,
			values
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Nowa pozycja dla wyszukiwarki #{searchId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							values && boroughOptions && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<Select
										name="boroughId"
										value={boroughId}
										label="Gmina"
										options={boroughOptions}
										handleChange={this.handleChange}
										required
									/>

									<FieldGroup label="Własności wyszukiwarki dla pozycji">
										{
											values.map((value, index) => (
												<Row
													key={value.uuid}
													style={{
														border: '1px solid #d0d0d0',
														borderRadius: '5px',
														margin: '15px',
														padding: '1rem 0'
													}}
												>
													<Col lg={{span: 12}}>
														Własność: <strong>{value.key.name}</strong>
														<hr />
													</Col>
													<Col lg={{span: 12}}>
														<Textarea
															name={`values[${index}].value.value`}
															value={value.value.value}
															placeholder={value.key.name}
															handleChange={this.handleChange}
														/>
													</Col>
												</Row>
											))
										}
									</FieldGroup>

									<Button variant="outline-primary" type="submit">
										Utwórz nową pozycję dla wyszukiwarki
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

AddSearchEntry.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	listSearchEntries: PropTypes.func.isRequired,
	search: PropTypes.object,
	getSearch: PropTypes.func.isRequired,
	boroughs: PropTypes.object,
	listAllBoroughs: PropTypes.func.isRequired,
	added: PropTypes.bool.isRequired,
	addSearchEntry: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

AddSearchEntry.defaultProps = {
	search: null,
	boroughs: null
};

export default AddSearchEntry;
