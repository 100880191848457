import {connect} from 'react-redux';

import {addRankEntry, listRankEntries} from '../../../../actions/rankEntry';
import Add from './Add';

const mapStateToProps = (state) => ({
	added: state.rankEntry.added,
	loading: state.rankEntry.status.loading,
	error: state.rankEntry.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRankEntries: (rankId, order, asc) => dispatch(listRankEntries(rankId, order, asc)),
	addRankEntry: (data) => dispatch(addRankEntry(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
