import {connect} from 'react-redux';

import List from './List';
import {deleteUser, listUsers} from '../../../../actions/user';

const mapStateToProps = (state) => ({
	me: state.user.me,
	list: state.user.list,
	deleted: state.user.deleted,
	loading: state.user.status.loading,
	error: state.user.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listUsers: (order, asc) => dispatch(listUsers(order, asc)),
	deleteUser: (id) => dispatch(deleteUser(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
