import {connect} from 'react-redux';

import {addBorough, listBoroughs} from '../../../../actions/borough';
import Add from './Add';

const mapStateToProps = (state) => ({
	added: state.borough.added,
	loading: state.borough.status.loading,
	error: state.borough.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listBoroughs: (districtId, order, asc) => dispatch(listBoroughs(districtId, order, asc)),
	addBorough: (data) => dispatch(addBorough(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add);
