import {connect} from 'react-redux';

import {deleteRank, listRanks} from '../../../../actions/rank';
import List from './List';

const mapStateToProps = (state) => ({
	list: state.rank.list,
	deleted: state.rank.deleted,
	loading: state.rank.status.loading,
	error: state.rank.status.error
});

const mapDispatchToProps = (dispatch) => ({
	listRanks: (order, asc) => dispatch(listRanks(order, asc)),
	deleteRank: (id) => dispatch(deleteRank(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
