import {connect} from 'react-redux';

import LayoutPrivate from './Private';
import {getUserMe} from '../../../actions/user';

const mapStateToProps = (state) => ({
	me: state.user.me
});

const mapDispatchToProps = (dispatch) => ({
	getUserMe: () => dispatch(getUserMe())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LayoutPrivate);
