import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {Button, Table as BootstrapTable} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

const Table = (props) => {
	const {
		cols,
		sortable,
		order,
		asc,
		operations,
		list,
		actions
	} = props;

	return (
		<BootstrapTable striped bordered hover>
			<thead>
				<tr>
					<th colSpan={cols.length + 1} className="buttons">
						{
							operations.map((operation) => (
								<Fragment key={operation.label}>
									<Button variant={operation.style} as={Link} to={operation.link}>{operation.label}</Button>
									&nbsp;
								</Fragment>
							))
						}
					</th>
				</tr>
				<tr>
					{
						cols.map((col) => (
							<th key={col.name} className={classNames({sortable: col.sortable})} onClick={col.sortable ? () => sortable(col.name) : null}>
								{col.label}
								{order === col.name && (asc ? '[↑]' : '[↓]')}
							</th>
						))
					}
					<th />{/* eslint-disable-line jsx-a11y/control-has-associated-label */}
				</tr>
			</thead>
			<tbody>
				{
					list ? (
						<>
							{
								list.length === 0 ? (
									<tr>
										<td colSpan={cols.length + 1}>
											Dodaj nowy element do listy za pomocą operacji w nagłówku.
										</td>
									</tr>
								) : (
									list.map((item) => (
										<tr key={`table_${item.id}`}>
											{
												cols.map((col) => (
													<td key={`table_${item.id}_${col.name}`}>
														{col.render ? col.render(item) : item[col.name]}
													</td>
												))
											}
											<td>
												{
													actions.map((action) => (
														<Fragment key={action.label}>
															<Button
																size="sm"
																variant={action.style}
																onClick={() => action.action(item)}
															>
																{action.label}
															</Button>
															&nbsp;
														</Fragment>
													))
												}
											</td>
										</tr>
									))
								)
							}
						</>
					) : (
						<tr>
							<td colSpan={cols.length + 1}>
								Wczytywanie...
							</td>
						</tr>
					)
				}
			</tbody>
		</BootstrapTable>
	);
};

Table.propTypes = {
	cols: PropTypes.array.isRequired,
	sortable: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	asc: PropTypes.bool.isRequired,
	operations: PropTypes.array,
	list: PropTypes.array,
	actions: PropTypes.array
};

Table.defaultProps = {
	operations: [],
	list: null,
	actions: []
};

export default Table;
