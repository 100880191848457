import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {Form, Input} from '../../../../components/Form';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class EditRankGroupValue extends Component {
	state = {
		initialized: false,
		success: false,
		invalid: false,
		value: '',
		points: '',
		place: '',
		rank: null
	}

	componentDidMount() {
		const {rankId, rankGroupId, rankValueId} = this.props.match.params;
		const {getRankValue, getRank} = this.props;

		setTitle(['Rankingi', `Ranking #${rankId}`, 'Grupy', `Grupa #${rankGroupId}`, 'Wartości', `Wartość #${rankValueId}`, 'Edytuj']);

		getRankValue(rankValueId);
		getRank(rankId);

		return true;
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.item && this.props.item && this.props.item.id === parseInt(this.props.match.params.rankValueId, 10)) {
			this.setState({
				initialized: true,
				value: this.props.item.value,
				points: this.props.item.points,
				place: parseInt(this.props.item.place, 10) + 1
			});
		}

		if (!prevProps.rank && this.props.rank && this.props.rank.id === parseInt(this.props.match.params.rankId, 10)) {
			this.setState({
				rank: this.props.rank
			});
		}

		if (!prevProps.edited && this.props.edited) {
			this.props.listRankValues(this.props.match.params.rankGroupId, 'place', true);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push(`/rank/${this.props.match.params.rankId}/group/${this.props.match.params.rankGroupId}/value`);
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {rankValueId} = this.props.match.params;
		const {editRankValue, loading} = this.props;
		const {
			value,
			points,
			place
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		editRankValue(rankValueId, {
			value,
			points,
			place: place - 1
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {rankValueId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			initialized,
			success,
			invalid,
			value,
			points,
			place,
			rank
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Edycja wartości rankingu #{rankValueId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							initialized && rank && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<Input
										name="value"
										value={value}
										label="Wartość"
										type="number"
										placeholder="Wpisz wartość"
										handleChange={this.handleChange}
										required
										step="any"
									/>

									<Input
										name="points"
										value={points}
										label="Punkty"
										type="number"
										placeholder="Wpisz punkty"
										handleChange={this.handleChange}
										required
										step="any"
									/>

									{
										rank.lock_place_refresh === 1 && (
											<Input
												name="place"
												value={place}
												label="Miejsce"
												type="number"
												placeholder="Wpisz miejsce w rankingu"
												handleChange={this.handleChange}
												required
											/>
										)
									}

									<Button variant="outline-primary" type="submit">
										Zapisz zmiany
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

EditRankGroupValue.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	listRankValues: PropTypes.func.isRequired,
	rank: PropTypes.object,
	getRank: PropTypes.func.isRequired,
	item: PropTypes.object,
	getRankValue: PropTypes.func.isRequired,
	edited: PropTypes.bool.isRequired,
	editRankValue: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

EditRankGroupValue.defaultProps = {
	rank: null,
	item: null
};

export default EditRankGroupValue;
